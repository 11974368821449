import { Directive, HostBinding, HostListener, Output, EventEmitter } from '@angular/core';
// No DND does not mean Dungoeons and Dragons it means Drag 'n Drop!
@Directive({ selector: '[dnd]' })
export class DndDirective {

  @HostBinding('class.fileover') fileOver: boolean;
  @Output() fileDropped = new EventEmitter<FileList>();

  constructor() { }

  // Listen for DragOver
  @HostListener('dragover', ['$event']) public onDragOver(event) {
    event.preventDefault();
    event.stopPropagation();
    this.fileOver = true;
  }

  // Listen for Dragleave
  @HostListener('dragleave', ['$event']) public onDragLeave(event) {
    event.preventDefault();
    event.stopPropagation();
    this.fileOver = false;
  }

  // Listen for Drop
  @HostListener('drop', ['$event']) public onDrop(event) {
    event.preventDefault();
    event.stopPropagation();
    this.fileOver = false;
    const files = event.dataTransfer.files;
    if (files.length > 0) {
      this.fileDropped.emit(files);
    }
  }

}

import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AuthenticationService } from 'src/app/services/auth/authentication.service';
import { LoaderService } from 'src/app/services/loader.service';
import { ApiService } from 'src/app/services/api.service';
import { LogallService } from 'src/app/services/logall.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class LoginComponent implements OnInit {
  loginForm = new FormGroup({
    username: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required, Validators.minLength(6)])
  });

  loading: boolean = false;
  baseLoadingIcon: string = '<i class="pi pi-spin pi-spinner"></i>';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private auth: AuthenticationService,
    private loaderService: LoaderService,
    private api: ApiService,
    private log: LogallService
  ) { 
    
  }

  async ngOnInit() {
    this.loaderService.activateFullSpinner();
    // Kick into OTP validation first
    this.route.params.subscribe(params => { 
      let OTP = params['OTP'];
      if (OTP) this.otpLogin(OTP);
    });
    // Check and Validate stored token
    await this.auth.validateSession();
    if (this.auth.isAuthValid()) {
      this.router.navigate([this.auth.getRouteLocation()]);
      this.loaderService.deactivateFullSpinner();
    }
    this.loaderService.deactivateFullSpinner();    
  }


  async login() {
    this.loading = true;
    try {
      let login = await this.auth.authenticate(this.loginForm.value.username, this.loginForm.value.password);

      console.log(login)

      var route = this.auth.getRouteLocation();
      console.log(route);

      this.loaderService.deactivateLoader();

      this.router.navigate([route]);
      
    } catch (error) {
      console.log(error)
      if (error.errorCode) {
        if (error.errorCode == -1) {
          this.messageService.add({
            severity: 'error',
            summary: error.errorMessage,
            detail: 'Either your Username or Password is incorrect, please check and try again!',
            life: 1500
          });
          this.log.error(error.errorMessage, 'login.component.login')
          this.api.logError(error.errorMessage, 'login.component.login')
        } else {
          this.messageService.add({
            severity: 'error', 
            summary: error.errorMessage,
            detail: 'Failed to login, please try again.',
            life: 1500
          });
          this.log.error(error.errorMessage, 'login.component.login')
          this.api.logError(error.errorMessage, 'login.component.login')
        }
      } else {
        this.messageService.add({
          severity: 'error', 
          summary: 'Failed to login, please try again.',
          life: 1500
        });
        this.log.error(error.errorMessage, 'login.component.login')
        this.api.logError(error.errorMessage, 'login.component.login')
      }
    }

    this.loading = false;
  }

  async otpLogin(otp: string) {
    try {
      await this.auth.authenticateOTP(otp);
      var route = this.auth.getRouteLocation();
      console.log(route);
      this.router.navigate([route]);

    } catch (error) {
      if (error.errorCode) {
        if (error.errorCode == -1) {
          this.messageService.add({
            severity: 'error', 
            summary: error.errorMessage,
            detail: 'Failed to login, please try again or contact administrator',
            life: 1500
          });
          this.log.error(error.errorMessage, 'login.component.login')
          this.api.logError(error.errorMessage, 'login.component.otpLogin');
        } else {
          this.messageService.add({
            severity: 'error', 
            summary: 'Unknown Error',
            detail: 'Failed to login, please try again or contact administrator',
            life: 1500
          });
          this.log.error(error.errorMessage, 'login.component.login')
          this.api.logError(error.errorMessage, 'login.component.otpLogin');
        }
      } else {
        this.messageService.add({
          severity: 'error', 
          summary: 'Unknown Error',
          detail: 'Failed to login, please try again or contact administrator',
          life: 1500
        });
        this.log.error(error.errorMessage, 'login.component.login')
        this.api.logError(error.errorMessage, 'login.component.otpLogin');
      }
    }

    this.loaderService.deactivateFullSpinner();
  }

  resetPassword() {
    this.router.navigate(['password-reset'])
  }

} 
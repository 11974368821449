import { Component, HostListener, OnInit } from '@angular/core';
import { LocationStrategy } from '@angular/common';
import { ApiService } from 'src/app/services/api.service';
import { DataService } from './data.service';
import { MessageService } from 'primeng/api';
import { LoaderService } from './loader.service';
import { ToastService } from './toast.service';
import * as moment from 'moment';

@Component({
  selector: 'app-complete-finance',
  templateUrl: './complete-finance.component.html',
  styleUrls: ['./complete-finance.component.scss']
})
export class CompleteFinanceComponent implements OnInit {

  // section booleans
  secStarting: boolean = true;
  secCalculator: boolean = false;
  personalInformation: boolean = false;
  clientSection: boolean = false;
  openAccount: boolean = false;
  applicationType: boolean = false;
  carList: boolean = false;
  employerDetails: boolean = false;
  loanTerms: boolean = false;
  uploaddocuments: boolean = false;
  terms: boolean = false;
  appreview: boolean = false;
  loginPage: boolean = false;
  loadLastPage: boolean = false;

  loanType;

  constructor(private api: ApiService,
  public data: DataService,
  private location: LocationStrategy,
  private messageService: MessageService,
  public ldService: LoaderService,
  private toast: ToastService) { 
    history.pushState(null, null, window.location.href);
    this.location.onPopState(() => {
      history.pushState(null, null, window.location.href);
      this.checkWhereStateAndGoBack()
      console.log('back button selected')
    })
  }

  // TODO: UnComment the below When deploying
  @HostListener('window:beforeunload', ["$event"]) unloadHandler(event: Event) {
    let result = confirm("Changes you made will not be saved");
    if (result) {

    }
    event.returnValue = false;
  }

  ngOnInit(): void {
    this.secStarting = true;
    this.carList = false;
    this.secCalculator = false;
    this.personalInformation = false;
    this.clientSection = false;
    this.openAccount = false;
    this.applicationType = false;
    this.employerDetails = false;
    this.loanTerms = false;
    this.uploaddocuments = false;
    this.terms = false;
    this.appreview = false;
    this.loginPage = false;

    this.data.getDropdowns();
  }

  checkWhereStateAndGoBack() {
    if (this.secCalculator) {
      this.secCalculator = false;
      this.carList = true;
    } else if (this.clientSection) {
      this.clientSection = false;
      this.secCalculator = true;
    } else if (this.openAccount) {
      this.openAccount = false;
      this.clientSection = true;
    } else if (this.applicationType) {
      this.applicationType = false;
      this.openAccount = true;
    } else if (this.loanTerms) {
      this.loanTerms = false;
      this.applicationType = true;
    } else if (this.employerDetails) {
      this.employerDetails = false;
      this.loanTerms = true;
    } else if (this.loginPage) {
      this.loginPage = false;
      this.employerDetails = true;
    } else if (this.personalInformation) {
      this.personalInformation = false;
      this.employerDetails = true;
    } else if (this.uploaddocuments) {
      this.uploaddocuments = false;
      this.personalInformation = true;
    } else if (this.terms) {
      this.terms = false;
      this.uploaddocuments = true;
    }
  }

  resetAll() {
    this.secStarting = false;
    this.carList = false;
    this.secCalculator = false;
    this.personalInformation = false;
    this.clientSection = false;
    this.openAccount = false;
    this.applicationType = false;
    this.employerDetails = false;
    this.loanTerms = false;
    this.uploaddocuments = false;
    this.terms = false;
    this.appreview = false;
    this.loginPage = false;
  }

  getLoaderStatus() {
    return this.ldService.getLoaderState();
  }

  chooseStanbic() {
    if (this.data.application.stanbicClient == 0) {//Not a client go to openAccount
      this.clientSection = false; this.openAccount = true;
    }
    else if (this.data.application.stanbicClient == 1) {//Is a client, go to next section
      this.clientSection = false; this.applicationType = true;
    }
  }

  acceptStanbicTerms() {
    this.openAccount = false; this.applicationType = true;
  }

  chooseLoanType() {
    if (this.data.application.loanType == 1) {//personal
      this.loanTerms = false; this.employerDetails = true;
    }
    else if (this.data.application.loanType == 2) {//business
      this.loanTerms = false; this.loginPage = true
    }
    else {
      //Log error something went wrong. only cater for 1 and 2
    }
  }

  trigPersonal() {
    this.personalInformation = false
    if (this.data.application.loanType == 1) {
       this.employerDetails = true;
    } else if (this.data.application.loanType == 2) {
      this.loanTerms = true;
    }
    
  }

  logTest() {
    console.log(this.data.application);
    console.log(this.data.loggedIn)
  }

  applicationContinue(appId) {
    console.log(appId)
    this.data.application.applicationId = appId.applicationId;
    this.api.getInProgressApplication(appId.applicationId).subscribe((result) => {
      console.log(result);
      if (result['errorCode'] == 0) {
        var data = result['result'];

        this.api.getBothDropdown(data.currencyId, data.countryId).subscribe((res) => {
          console.log(res)
          if (res['errorCode'] == 0) {
            this.data.application.formDetails.selectedCountry = res['result']['country'];
            this.data.application.formDetails.selectedCurrency = res['result']['currency'];
          }
        });
        this.data.getDropdowns();

        console.log(result['result'])

        switch (appId.stepNumber) {
          case 1: 
            // Vehicle Wishlist
            this.resetAll();
            this.data.application.vehicleDetails.vehicleMake = data.vehicleMake;
            this.data.application.vehicleDetails.vehicleModel = data.vehicleModel;
            let oneManufactureYear = moment(data.manufactureYear);
            if (!oneManufactureYear.isSame(moment('0001-01-01T00:00:00'))) {
              this.data.application.vehicleDetails.manufactureYear = oneManufactureYear.toDate();
            }
            this.data.application.vehicleDetails.other = data.other;
            this.data.application.vehicleDetails.fuelType = data.fuelType;
            this.data.application.vehicleDetails.engineSize = data.engineSize;
            this.data.application.vehicleDetails.vehicleValue = data.vehicleValue;
            this.data.application.personDetails.fullName = data.fullName;
            this.data.application.personDetails.emailAddress = data.emailAddress;
            this.data.application.personDetails.cellNumber = data.cellNumber;
            this.carList = true;
            break;
          case 2:
            // Calculator
            this.resetAll();
            this.data.application.vehicleDetails.vehicleMake = data.vehicleMake;
            this.data.application.vehicleDetails.vehicleModel = data.vehicleModel;
            let twoManufactureYear = moment(data.manufactureYear);
            if (!twoManufactureYear.isSame(moment('0001-01-01T00:00:00'))) {
              this.data.application.vehicleDetails.manufactureYear = twoManufactureYear.toDate();
            }
            this.data.application.vehicleDetails.other = data.other;
            this.data.application.vehicleDetails.fuelType = data.fuelType;
            this.data.application.vehicleDetails.engineSize = data.engineSize;
            this.data.application.vehicleDetails.vehicleValue = data.vehicleValue;
            this.data.application.personDetails.fullName = data.fullName;
            this.data.application.personDetails.emailAddress = data.emailAddress;
            this.data.application.personDetails.cellNumber = data.cellNumber;
            this.data.application.formDetails.deposit = data.deposit;
            this.data.application.formDetails.estimatedTotalExpenses = data.estimatedExpenses;
            this.data.application.formDetails.interestRate = data.interestRate;
            this.data.application.formDetails.loanTerm = data.loanTerm;
            this.data.application.formDetails.monthlySalaryAfterTax = data.monthlySalary;
            this.data.application.formDetails.vehicleValue = data.totalValue;
            this.secCalculator = true;
            break;
          case 3:
            // StanbicClient
            this.resetAll();
            this.data.application.vehicleDetails.vehicleMake = data.vehicleMake;
            this.data.application.vehicleDetails.vehicleModel = data.vehicleModel;
            let threeManufactureYear = moment(data.manufactureYear);
            if (!threeManufactureYear.isSame(moment('0001-01-01T00:00:00'))) {
              this.data.application.vehicleDetails.manufactureYear = threeManufactureYear.toDate();
            }
            this.data.application.vehicleDetails.other = data.other;
            this.data.application.vehicleDetails.fuelType = data.fuelType;
            this.data.application.vehicleDetails.engineSize = data.engineSize;
            this.data.application.vehicleDetails.vehicleValue = data.vehicleValue;
            this.data.application.personDetails.fullName = data.fullName;
            this.data.application.personDetails.emailAddress = data.emailAddress;
            this.data.application.personDetails.cellNumber = data.cellNumber;
            this.data.application.formDetails.deposit = data.deposit;
            this.data.application.formDetails.estimatedTotalExpenses = data.estimatedExpenses;
            this.data.application.formDetails.interestRate = data.interestRate;
            this.data.application.formDetails.loanTerm = data.loanTerm;
            this.data.application.formDetails.monthlySalaryAfterTax = data.monthlySalary;
            this.data.application.formDetails.vehicleValue = data.totalValue;
            this.data.application.stanbicClient = data.stanbicClient;
            this.clientSection = true;
            break;
          case 4:
            // Open Stanbic Account
            this.resetAll();
            this.data.application.vehicleDetails.vehicleMake = data.vehicleMake;
            this.data.application.vehicleDetails.vehicleModel = data.vehicleModel;
            let fourManufactureYear = moment(data.manufactureYear);
            if (!fourManufactureYear.isSame(moment('0001-01-01T00:00:00'))) {
              this.data.application.vehicleDetails.manufactureYear = fourManufactureYear.toDate();
            }
            this.data.application.vehicleDetails.other = data.other;
            this.data.application.vehicleDetails.fuelType = data.fuelType;
            this.data.application.vehicleDetails.engineSize = data.engineSize;
            this.data.application.vehicleDetails.vehicleValue = data.vehicleValue;
            this.data.application.personDetails.fullName = data.fullName;
            this.data.application.personDetails.emailAddress = data.emailAddress;
            this.data.application.personDetails.cellNumber = data.cellNumber;
            this.data.application.stanbicClient = data.stanbicClient;
            this.data.application.formDetails.deposit = data.deposit;
            this.data.application.formDetails.estimatedTotalExpenses = data.estimatedExpenses;
            this.data.application.formDetails.interestRate = data.interestRate;
            this.data.application.formDetails.loanTerm = data.loanTerm;
            this.data.application.formDetails.monthlySalaryAfterTax = data.monthlySalary;
            this.data.application.formDetails.vehicleValue = data.totalValue;
            this.data.application.stanbicTermsAccepted = data.stanbicTermsAccepted;
            this.openAccount = true;
            break;
          case 5:
            // Loan Type
            this.resetAll();
            this.data.application.vehicleDetails.vehicleMake = data.vehicleMake;
            this.data.application.vehicleDetails.vehicleModel = data.vehicleModel;
            let fiveManufactureYear = moment(data.manufactureYear);
            if (!fiveManufactureYear.isSame(moment('0001-01-01T00:00:00'))) {
              this.data.application.vehicleDetails.manufactureYear = fiveManufactureYear.toDate();
            }
            this.data.application.vehicleDetails.other = data.other;
            this.data.application.vehicleDetails.fuelType = data.fuelType;
            this.data.application.vehicleDetails.engineSize = data.engineSize;
            this.data.application.vehicleDetails.vehicleValue = data.vehicleValue;
            this.data.application.personDetails.fullName = data.fullName;
            this.data.application.personDetails.emailAddress = data.emailAddress;
            this.data.application.personDetails.cellNumber = data.cellNumber;
            this.data.application.loanType = data.loanTypeId;
            this.data.application.stanbicClient = data.stanbicClient;
            this.data.application.formDetails.deposit = data.deposit;
            this.data.application.formDetails.estimatedTotalExpenses = data.estimatedExpenses;
            this.data.application.formDetails.interestRate = data.interestRate;
            this.data.application.formDetails.loanTerm = data.loanTerm;
            this.data.application.formDetails.monthlySalaryAfterTax = data.monthlySalary;
            this.data.application.formDetails.vehicleValue = data.totalValue;
            this.data.application.stanbicTermsAccepted = data.stanbicTermsAccepted;
            this.applicationType = true;
            break;
          case 6:
            // Company Information
            this.resetAll();
            this.data.application.vehicleDetails.vehicleMake = data.vehicleMake;
            this.data.application.vehicleDetails.vehicleModel = data.vehicleModel;
            let sixManufactureYear = moment(data.manufactureYear);
            if (!sixManufactureYear.isSame(moment('0001-01-01T00:00:00'))) {
              this.data.application.vehicleDetails.manufactureYear = sixManufactureYear.toDate();
            }
            this.data.application.vehicleDetails.other = data.other;
            this.data.application.vehicleDetails.fuelType = data.fuelType;
            this.data.application.vehicleDetails.engineSize = data.engineSize;
            this.data.application.vehicleDetails.vehicleValue = data.vehicleValue;
            this.data.application.personDetails.fullName = data.fullName;
            this.data.application.personDetails.emailAddress = data.emailAddress;
            this.data.application.personDetails.cellNumber = data.cellNumber;
            this.data.application.loanType = data.loanTypeId;
            this.data.application.stanbicClient = data.stanbicClient;
            this.data.application.stanbicTermsAccepted = data.stanbicTermsAccepted;
            this.data.application.companyDetails.schemeId = data.schemeId;
            this.data.application.formDetails.deposit = data.deposit;
            this.data.application.formDetails.estimatedTotalExpenses = data.estimatedExpenses;
            this.data.application.formDetails.interestRate = data.interestRate;
            this.data.application.formDetails.loanTerm = data.loanTerm;
            this.data.application.formDetails.monthlySalaryAfterTax = data.monthlySalary;
            this.data.application.formDetails.vehicleValue = data.totalValue;
            this.data.application.companyDetails.companyName = data.schemeName;
            this.data.application.companyDetails.companyAddress = data.schemeAddress;
            this.employerDetails = true;
            break;
          case 7:
            // Personal information
            this.resetAll();
            this.fetchData(data);
            this.personalInformation = true;
            break;
          case 8:
            // Upload Documents
            this.resetAll();
            this.fetchData(data);
            this.uploaddocuments = true;
            break;
          case 9:
            // Completed Application
            this.resetAll();
            this.fetchData(data);
            this.appreview = true;
            break;
          default:
            // Unknown
            this.resetAll();
            this.secStarting = true;
            break;
        }

      } else {
        this.toast.toastError('Unable to fetch application data', 'We were unable to fetch the information associated to this application', appId);
      }
    })

    
  }

  fetchData(data) {
    this.data.application.vehicleDetails.vehicleMake = data.vehicleMake;
    this.data.application.vehicleDetails.vehicleModel = data.vehicleModel;
    let sevenManufactureYear = moment(data.manufactureYear);
    if (!sevenManufactureYear.isSame(moment('0001-01-01T00:00:00'))) {
      this.data.application.vehicleDetails.manufactureYear = sevenManufactureYear.toDate();
    }
    this.data.application.vehicleDetails.other = data.other;
    this.data.application.vehicleDetails.fuelType = data.fuelType;
    this.data.application.vehicleDetails.engineSize = data.engineSize;
    this.data.application.vehicleDetails.vehicleValue = data.vehicleValue;
    this.data.application.personDetails.fullName = data.fullName;
    this.data.application.personDetails.emailAddress = data.emailAddress;
    this.data.application.personDetails.cellNumber = data.cellNumber;
    this.data.application.stanbicTermsAccepted = data.stanbicTermsAccepted;
    this.data.application.formDetails.deposit = data.deposit;
    this.data.application.formDetails.estimatedTotalExpenses = data.estimatedExpenses;
    this.data.application.formDetails.interestRate = data.interestRate;
    this.data.application.formDetails.loanTerm = data.loanTerm;
    this.data.application.formDetails.monthlySalaryAfterTax = data.monthlySalary;
    this.data.application.formDetails.vehicleValue = data.totalValue;
    this.data.application.stanbicClient = data.stanbicClient;
    this.data.application.companyDetails.schemeId = data.schemeId;
    this.data.application.companyDetails.companyName = data.schemeName;
    this.data.application.companyDetails.companyAddress = data.schemeAddress;
    this.data.application.personDetails.fullName = data.fullName;
    this.data.application.personDetails.emailAddress = data.emailAddress;
    this.data.application.personDetails.cellNumber = data.cellNumber;
    this.data.application.personDetails.title = data.title;
    this.data.application.personDetails.firstName = data.firstname;
    this.data.application.personDetails.lastName = data.lastName;
    let dobDate = moment(data.dateOfBirth);
    if (!dobDate.isSame(moment('0001-01-01T00:00:00'))) {
      this.data.application.personDetails.dateOfBirth = dobDate.toDate();
    }
    this.data.application.personDetails.IDNumber = data.idNumber;
    this.data.application.personDetails.homeTellNumber = data.homeTellNumber;
    this.data.application.personDetails.workTellNumber = data.workTellNumber;
    this.data.application.personDetails.addressLine1 = data.addressLine1;
    this.data.application.personDetails.addressLine2 = data.addressLine2;
    this.data.application.personDetails.suburb = data.suburb;
    this.data.application.personDetails.city = data.city;
    this.data.application.personDetails.postalSuburb = data.postalSuburb;
    this.data.application.personDetails.postalCode = data.postalCode;
    let livedSinceDate = moment(data.livedSinceDate);
    if (!livedSinceDate.isSame(moment('0001-01-01T00:00:00'))) {
      this.data.application.personDetails.livedSinceDate = livedSinceDate.toDate();
    }
    this.data.application.personDetails.residentialStatus = data.residentialStatus;
    this.data.application.personDetails.postalAddressLine1 = data.postalAddressLine1;
    this.data.application.personDetails.postalAddressLine2 = data.postalAddressLine2;
    this.data.application.personDetails.postalCity = data.postalCity;
    this.data.application.personDetails.postalPostalCode = data.postalPostalCode;
    this.data.application.personDetails.maritalStatus = data.maritalStatus;
    this.data.application.personDetails.numberOfDependantsSpouse = data.numberOfDependantsSpouse;
    this.data.application.personDetails.numberOfDependantsChildren = data.numberOfDependantsChildren;
    this.data.application.personDetails.numberOfDependantsOther = data.numberOfDependantsOther;
    this.data.application.personDetails.nextOfKinTitle = data.nextOfKinTitle;
    this.data.application.personDetails.nextOfKinFirstName = data.nextOfKinFirstName;
    this.data.application.personDetails.nextOfKinLastName = data.nextOfKinLastName;
    this.data.application.personDetails.nextOfKinMobileNumber = data.nextOfKinMobileNumber;
    this.data.application.personDetails.employmentStatus = data.employmentStatus;
    this.data.application.personDetails.employmentAddress = data.employmentAddress;
    this.data.application.personDetails.employmentCity = data.employmentCity;
    this.data.application.personDetails.employmentTellNumber = data.employmentTellNumber;
    let employmentContractExpiryDate = moment(data.employmentContractExpiryDate);
    if (!employmentContractExpiryDate.isSame(moment('0001-01-01T00:00:00'))) {
      this.data.application.personDetails.employmentContractExpiryDate = employmentContractExpiryDate.toDate();
    }
    this.data.application.personDetails.employmentDesignation = data.employmentDesignation;
    this.data.application.personDetails.employmentOccupation = data.employmentOccupation;
    let employmentDate = moment(data.employmentDate);
    if (!employmentDate.isSame(moment('0001-01-01T00:00:00'))) {
      this.data.application.personDetails.employmentDate[0] = employmentDate.toDate();
    }
    let employmentDateStart = moment(data.employmentPeriodStart);
    if (!employmentDateStart.isSame(moment('0001-01-01T00:00:00'))) {
      this.data.application.personDetails.employmentPeriod[0] = employmentDateStart.toDate();
    }
    let employmentDateEnd = moment(data.employmentPeriodEnd);
    if (!employmentDateEnd.isSame(moment('0001-01-01T00:00:00'))) {
      this.data.application.personDetails.employmentPeriod[1] = employmentDateEnd.toDate();
    }
    this.data.application.personDetails.highestLevelEducation = data.highestLevelEducation; 
    this.data.application.personDetails.grossMonthlySalary = data.grossMonthlySalary;
    this.data.application.personDetails.netMonthlySalary = data.netMonthlySalary;
    this.data.application.personDetails.nameOfEmployer = data.nameOfEmployer;
    this.data.application.personDetails.accountType = data.accountType;
    this.data.application.personDetails.accountName = data.accountName;
    this.data.application.personDetails.bankName = data.bankName;
    this.data.application.personDetails.branchName = data.branchName;
    this.data.application.personDetails.branchCode = data.branchCode;
    this.data.application.personDetails.accountNumber = data.accountNumber;
    let dateAccountOpened = moment(data.dateAccountOpened);
    if (!dateAccountOpened.isSame(moment('0001-01-01T00:00:00'))) {
      this.data.application.personDetails.dateAccountOpened = dateAccountOpened.toDate();
    }
    this.data.application.personDetails.rentMortgagePayments = data.rentMortgagePayments;
    this.data.application.personDetails.vehiclePayments = data.vehiclePayments;
    this.data.application.personDetails.otherVeritableMonthlyIncome = data.otherVeritableMonthlyIncome;
    this.data.application.personDetails.otherDebtPayments = data.otherDebtPayments;
    this.data.application.personDetails.bonusThirteenthCheque = data.bonusThirteenthCheque;
    this.data.application.personDetails.otherMonthlyCommitments = data.otherMonthlyCommitments;
  }

  onNextForLoginCheck() {
    this.employerDetails = false; 
    if (!this.data.loggedIn) { 
      this.loginPage = true 
    } else { 
      this.personalInformation = true 
    }
  }

  startApplication() {
    this.ldService.showLoader();
    this.api.startNewApplication().subscribe((resp) => {
      if (resp['errorCode'] == 0) {
        this.data.application.applicationId = resp['result']
        this.secStarting = false;
        this.carList = true;
      }
      else {
        this.toast.toastError('Unable to communicate', 'Something is wrong with connecting to the services, please try again!', 0);
      }
    })
    this.ldService.hideLoader();
  }

}

import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { LogBase } from '../../../services/logger.service';
import { SupplierService } from '../../../services/supplier.service';
import { SupplierCargo, SupplierCargoForDelivery, SupplierCargoInStorage, SupplierVessels, SupplierVoyages } from '../../../services/global.interface';
import { MessageService } from 'primeng/api';
import { ExcelService, ExcelSheet } from '../../../services/excel.service';
import { LoaderService } from '../../../services/loader.service';
import { ApiService } from '../../../services/api.service';
import * as _ from 'lodash';

export interface FilterOptions {
  foreignReferences?: string
  vessel?: SupplierVessels
  voyageOptions?: SupplierVoyages[]
  voyage?: SupplierVoyages,
  data: SupplierCargoForDelivery[] | SupplierCargoInStorage[],
  filtering: boolean
}
@Component({
  selector: 'app-landing-page-suppliers',
  templateUrl: './landing-page-suppliers.component.html',
  styleUrls: ['./landing-page-suppliers.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class LandingPageSuppliersComponent implements OnInit {
  cifNonCifDropdown = [{
    value: 'CIF',
    label: 'CIF'
  },
  {
    value: 'NON-CIF',
    label: 'NON-CIF'
  }]
  
  fileStatusDropdown = [{
    value: 'Open',
    label: 'Open'
  },
  {
    value: 'Closed',
    label: 'Closed'
  }];

  dutiesPaidDropdown = [{
    value: 'Duties paid',
    label: 'Duties paid'
  }, {
    value: 'Duties unpaid',
    label: 'Duties unpaid'
  }];

  showSpinner: boolean = true;
  tableLoading: boolean = false;
  loading: boolean = false;
  downloadingExcel: boolean = false;
  cargoForDeliveryFilterOptions: FilterOptions = {
    foreignReferences: null,
    vessel: null,
    voyage: null,
    data: [] as SupplierCargoForDelivery[],
    filtering: true
  };
  cargoInStorageFilterOptions: FilterOptions = {
    foreignReferences: null,
    vessel: null,
    voyage: null,
    data: [] as SupplierCargoInStorage[],
    filtering: true
  };

  shippingInfo: SupplierCargo[] = []
  vesselsDropdown: SupplierVessels[] = [];
  voyageDropdown: SupplierVoyages[] = [];

  constructor(
    private supplierService: SupplierService,
    private log: LogBase,
    private router: Router,
    private messageService: MessageService,
    private loaderService: LoaderService,
    private excelService: ExcelService,
    private apiService: ApiService
  ) { }

  async ngOnInit() {
    try {
      // Get the vessels of cargo that the supplier has access to
      this.vesselsDropdown = await this.supplierService.getVessels();
      this.getCargoForDelivery();
      this.getCargoInStorage();
    } catch (error) {
      this.log.error(error);
    }
    this.showSpinner = false;
  }

  async getCargoForDelivery() {
    this.cargoForDeliveryFilterOptions.filtering = true;
    try {
      const foreignReference = this.getForeignReferences(this.cargoForDeliveryFilterOptions.foreignReferences)
      const resp = await this.apiService.filterDataForDelivery(foreignReference, this.cargoForDeliveryFilterOptions.vessel?.vesselName, this.cargoForDeliveryFilterOptions.voyage?.voyageNumber);
      if (!resp) this.toastMessage('error', 'Failed to get cargo for delivery.', '');
      else if (resp.errorCode != 0) this.toastMessage('error', resp.errorMessage, '');
      else this.cargoForDeliveryFilterOptions.data = resp.result;
    } catch (error) {
      this.toastMessage('error', 'Failed to get cargo for delivery.', '');
      this.log.error(error);
    }
    this.cargoForDeliveryFilterOptions.filtering = false;
  }

  clearCargoForDeliveryFilters() {
    this.cargoForDeliveryFilterOptions.foreignReferences = null;
    this.cargoForDeliveryFilterOptions.vessel = null;
    this.cargoForDeliveryFilterOptions.voyage = null;
  }

  async getCargoInStorage() {
    this.cargoInStorageFilterOptions.filtering = true;
    try {
      const foreignReference = this.getForeignReferences(this.cargoInStorageFilterOptions.foreignReferences)
      const resp = await this.apiService.filterDataForStorage(foreignReference, this.cargoInStorageFilterOptions.vessel?.vesselName, this.cargoInStorageFilterOptions.voyage?.voyageNumber);
      if (!resp) this.toastMessage('error', 'Failed to get cargo in storage.', '');
      else if (resp.errorCode != 0) this.toastMessage('error', resp.errorMessage, '');
      else this.cargoInStorageFilterOptions.data = resp.result;
    } catch (error) {
      this.toastMessage('error', 'Failed to get cargo in storage.', '');
      this.log.error(error);
    }
    this.cargoInStorageFilterOptions.filtering = false;
  }

  clearCargoInStorageFilters() {
    this.cargoInStorageFilterOptions.foreignReferences = null;
    this.cargoInStorageFilterOptions.vessel = null;
    this.cargoInStorageFilterOptions.voyage = null;
  }

  getForeignReferences(foreignReferences: string) {
    if (!foreignReferences) return null;
    else if (foreignReferences.includes(',')) {
      const splitArr = foreignReferences.replace(/\s/g, '').split(',');
      const uniqArr = _.uniq(splitArr);
      return uniqArr;
    } else if (foreignReferences.includes(' ') || foreignReferences.includes('\n')) {
      const replaceNewLinesWithSpace = foreignReferences.replace(/\n/g, ' ');
      const splitArr = replaceNewLinesWithSpace.split(' ');
      const uniqArr = _.uniq(splitArr);
      return uniqArr;
    } else return [foreignReferences];
  }

  async onVesselSelect(filterOption: string) {
    if (!this[filterOption].vessel) return;
    const result = await this.supplierService.getVoyagesByVessel(this[filterOption].vessel);
    this[filterOption].voyageOptions = result;
    this.voyageDropdown = result;
  }

  async getReports() {
    // Set the loaders to show
    this.downloadingExcel = true;
    this.loading = true;
    this.tableLoading = true;
    this.shippingInfo = [];
    try {
      // Get Tracking Sheet for Delivery
      const delivery = (await this.apiService.filterDataForDelivery(null, null, null)).result;
      // Get Tracking Sheet for Storage
      const storage = (await this.apiService.filterDataForStorage(null, null, null)).result;

      this.downloadBFJExcelFile(delivery, storage);
    } catch (error) {
      this.log.error(error);
    }
    this.tableLoading = false;
    this.loading = false;
    this.downloadingExcel = false;
  }

  loadFile(fileId: string) {
    if (fileId) {
      var file = fileId.split('/')
      const url = this.router.serializeUrl(
        this.router.createUrlTree([`file-viewer/supplier/${file[1]}`])
      );

      window.open(url, '_blank');
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Missing File Id'
      })
    }
  }

  downloadBFJExcelFile(trackingSheetDelivery: any[], trackingSheetStorage: any[]) {
    try {
      this.loaderService.activateFullSpinner();
      let xlsxData: ExcelSheet[] = [{
        sheet: "Tracking Sheet for Delivery",
        columns: this.getColumnsForExcel(trackingSheetDelivery),
        content: trackingSheetDelivery
      }, {
        sheet: "Tracking Sheet for Storage",
        columns: this.getColumnsForExcel(trackingSheetStorage),
        content: trackingSheetStorage
      }];
      this.excelService.generateMultiSheetExcel("Cargo Export Supplier", xlsxData)
    } catch (error) {
      this.log.error(error);
    }
    
    this.loaderService.deactivateFullSpinner();
  }

  getColumnsForExcel(payload: any[]) : { label: string, value: any }[] {
    if (_.isEmpty(payload)) return [];
    const props = Object.getOwnPropertyNames(payload[0]);
    let columns = []
    for (const prop of props) {
      columns.push({ label: this.splitCamelCase(prop), value: prop });
    }
    return columns;
  }

  splitCamelCase(text: string) : string {
    const split_text: string = text.replace(/([a-z])([A-Z])/g, '$1 $2')
    return split_text.charAt(0).toUpperCase() + split_text.slice(1);
  }

  toastMessage(severity: string, summary: string, detail: string) { this.messageService.add({ severity: severity, summary: summary, detail: detail }) }
}
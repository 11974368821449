import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../services/auth/authentication.service';

@Component({
    selector: 'app-topbar',
    templateUrl: './topbar.component.html',
    styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent implements OnInit {

    @Input() isPortal: boolean = false;
    
    constructor(private router: Router, public auth: AuthenticationService) { }

    ngOnInit() : void { }

    async logout() {
        let bool = await this.auth.logout();
        if (bool) {
            this.router.navigate([""])
        } else {
            // TODO: Error of unknown proportions
        }
        
    }
}

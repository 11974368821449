import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { LogBase } from '../../../services/logger.service';
import { MessageService } from 'primeng/api';
import { AuthenticationService } from 'src/app/services/auth/authentication.service';
import { fakeAsync } from '@angular/core/testing';

@Component({
  selector: 'app-email-reset-link',
  templateUrl: './email-reset-link.component.html',
  styleUrls: ['./email-reset-link.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class EmailResetLinkComponent implements OnInit {

  cellNumber: string;
  timeDelayInBetweenResends: number = 60;
  timeForResend: number = 0;
  form: FormGroup;
  displayDialog: boolean = false;
  msgs: any[];
  showSpinner: boolean = false;

  displayNotFoundMessage: boolean = false;
  resetTries: number = 0;

  resetPasswordForm: FormGroup;
  otpForm: FormGroup;

  sections = {
    emailSection: true,
    chosenResetSection: false,
    enterOtpSection: false
  }

  selectedValue: any;

  constructor(
    private formBuilder: FormBuilder,
    private auth: AuthenticationService,
    private messageService: MessageService,
    private router: Router,
    private log: LogBase
  ) { }

  ngOnInit() {
    this.resetPasswordForm = this.formBuilder.group({
      email: ['dev@avecs.co.za', Validators.compose([Validators.required, Validators.email])]
    });
    this.otpForm = this.formBuilder.group({
      otp1: ['', Validators.compose([Validators.required, Validators.maxLength(1)])],
      otp2: ['', Validators.compose([Validators.required, Validators.maxLength(1)])],
      otp3: ['', Validators.compose([Validators.required, Validators.maxLength(1)])],
      otp4: ['', Validators.compose([Validators.required, Validators.maxLength(1)])],
      otp5: ['', Validators.compose([Validators.required, Validators.maxLength(1)])],
      otp6: ['', Validators.compose([Validators.required, Validators.maxLength(1)])],
    })
  }

  async beginPasswordReset() {
    if (this.resetPasswordForm.invalid) {
      this.messageService.add({
        severity: 'error', 
        summary: 'Please enter a valid email address'
      });
      return
    }

    this.cellNumber = this.mask('0790366714');

    this.sections.emailSection = false;
    this.sections.chosenResetSection = true;
  }

  async sendOtpToUser(chosenMethod) {
    if (chosenMethod == 'email') {

    }

    this.resetTimer();

    this.sections.chosenResetSection = false;
    this.sections.enterOtpSection = true;
  }

  async resendOtp() {

    this.resetTimer();
  }

  resetTimer() {
    this.timeForResend = this.timeDelayInBetweenResends;
    var intervalSet = setInterval(() => {
      this.timeForResend = this.timeForResend - 1;
      if (this.timeForResend == 0) clearInterval(intervalSet);
    },1000)
  }

  mask(valueToMask: string) : string {
    let runAmount = valueToMask.length * 0.6;
    let randomNum;
    for (let i = 0; i < runAmount; i++) {
      randomNum = Math.floor(Math.random() * valueToMask.length); 
      let chars = valueToMask.split('');
      if (chars[randomNum] != '*' && randomNum != valueToMask.length && (randomNum != valueToMask.length-1)) chars[randomNum] = '*';
      else i--;
      valueToMask = chars.join('');
    }
    console.log(valueToMask);
    return valueToMask
  }

  async getOtp() {
    

    // this.sections.emailSection = false;
    // this.sections.chosenResetSection = true;

    // const res = await this.auth.resetPassword(this.resetPasswordForm.value.email);

  }

  backToLogin() {
    this.router.navigate(['login']);
  }

  async onClick() {
    try {
      this.showSpinner = true;
      const res = await this.auth.resetPassword(this.form.value.email);

      if (res && res.errorCode == 15) {

        this.resetTries++;
        if (this.resetTries >= 2) {
          this.displayNotFoundMessage = true;
        }

        this.messageService.add({
          severity: 'error',
          summary: 'Not Found',
          detail: 'The email address entered does not exist on our system'
        });

      } else {
        this.resetTries = 0;
        this.displayNotFoundMessage = false;       
        this.displayDialog = true;
      }

    } catch (error) {
      this.log.error(error);
    }

    this.showSpinner = false;
  }

  onDone() {
    try {
      this.displayDialog = false;
      this.router.navigate(['/home']);
    } catch (error) {
      this.log.error(error);
    }
  }
}
export const environment = {
  production: true,
  isDebugMode: false,
  loginfo: false,
  logwarning: true,
  logerror: false,
  nodeUrl: 'http://docutrack.avecs.co.za:3000/',
  url: 'https://docutrack.avecs.co.za:50063/',
  financeUrl: 'https://docutrack.avecs.co.za:50100/',
  inspectionAuthUrl: 'http://105.247.9.83:60001/'
};

  
import { Component, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { FileService } from 'src/app/services/file.service';
import { AcceptDeclineLoadId, AssignedLoads, BorderAgentDocuments, BorderAgentFiles, LoadColumns, ViewDocument } from 'src/app/services/global.interface';
import * as _ from 'lodash';

@Component({
  selector: 'app-landing-page-borderagent',
  templateUrl: './landing-page-borderagent.component.html',
  styleUrls: ['./landing-page-borderagent.component.css']
})
export class LandingPageBorderagentComponent implements OnInit {

  showSpinner: boolean = false;
  displayDocs: boolean = false;
  confirmBorderDuties: boolean = false;
  documentLoading: boolean = false;
  tableLoading: boolean = false;

  fileNameToDownload: string = '';
  selectedDBNNumber: number;
  selectedEventTypeMark: string = '';
  borderAgentReason: string;
  
  downloadLink: any;

  selectedDocumentId: number;
  selectedEventId: number;
  selectedDocumentFileId: number;

  files: BorderAgentFiles[] = [];
  documents: BorderAgentDocuments[] = [];
  selectedDocument: BorderAgentDocuments;
   selectedLoadRow: AcceptDeclineLoadId[];

  dropdownyears: string[] = [];

  borderDutiesOptions: any[] = [{
    value: 4, 
    name: 'Border Duties Unpaid'
  }, {
    value: 5, 
    name: 'Border Duties Part Paid'
  }, {
    value: 6, 
    name: 'Border Duties Other'
  }]
  borderDutiesSelected: any = {
    value: 0, 
    name: ''
  };
  
  borderLoads: AssignedLoads[];


  // borderStatus: number[] = [3, 4, 5, 6, 0]
 borderStatus: any[] = [{
    value: 3, 
    label: 'Border Duties Paid'
  }, {
    value: 4, 
    label: 'Border Duties Unpaid'
  }, {
    value: 5, 
    label: 'Border Duties Part Paid'
  }, {
    value: 6, 
    label: 'Border Duties Other'
  }, {
    value: 0,
    label: 'Unconfirmed'
    }]
  
  headers: LoadColumns[] = [
    { field: "loadName", header: "Load Name", width: null },
    { field: "estimatedDeparture", header: "Estimated Departure", width: null },
    { field: "destination", header: "Destination", width: null },
    { field: "cargoNumber", header: "Number of Cars", width: null },
    {field: "cargoValue", header: "Cargo Value", width: null},
    { field: "assignDate", header: "Date Assigned", width: null },
    { field: "driverName", header: "Drivers Name", width: null }
  ]

  constructor(
    private fileService: FileService, 
    private confirmationService: ConfirmationService, 
    private messageService: MessageService) { }

  ngOnInit() : void {
    this.showSpinner = true;
    this.fetchFiles();
    this.borderAgentLoads();
  }

  async fetchFiles() : Promise<void> {
    this.files = await this.fileService.getBorderAgentFiles()
    let years = [];
    for (const file of this.files) {
      years.push(file.year);
    }
    this.dropdownyears = _.orderBy(_.uniq(years));
    this.showSpinner = false;
  } 

  async loadDutyDocs(fileId: number) : Promise<void> {
    this.documents = [];
    this.downloadLink = undefined;
    this.selectedDocument = null;
    this.documentLoading = false;
    this.selectedDocumentFileId = fileId;
    this.documents = await this.fileService.getBorderAgentDocuments(fileId);
    if (this.documents) {
      this.displayDocs = true;
    }
  }

  viewFile(viewDocument: ViewDocument) {
    this.downloadLink = viewDocument.dataUrl;
    this.fileNameToDownload = viewDocument.fileName;
  }

  async loadDocument(documentId: number, fileName: string) : Promise<void> {
    this.documentLoading = true;
    this.selectedDocumentId = documentId;
    let loadedDocument = await this.fileService.getDocumentByDocId(documentId, fileName);

    this.downloadLink = loadedDocument.dataUrl
    this.fileNameToDownload = loadedDocument.fileName
    this.documentLoading = false;
  }

  loadInTab(documentToLoad: any) {
    let pdfWindow = window.open("")
    pdfWindow.document.write(
      "<style>body { margin: 0px !important; }</style><iframe width='100%' height='100%' src='" + documentToLoad.changingThisBreaksApplicationSecurity + "'></iframe>"
    )
  }

  borderDutiesOptionSelectChange(event, file: BorderAgentFiles) {
    this.borderAgentReason = null;
    let reason = _.find(this.borderDutiesOptions, { 'value': event.value})
    this.selectedDBNNumber = file.fileId // TODO: Change to DBN Number
    this.selectedEventTypeMark = reason.name;
    this.selectedEventId = reason.value;
    event.value = null;
    this.confirmBorderDuties = true;
  }

  async updateBorderDutiesFileStatus(fileStatusId: number, fileId: number) {
    if (this.borderAgentReason) {
      var response = await this.fileService.addBorderAgentFileEvent(fileId, fileStatusId, this.borderAgentReason);
      if (response == 1) {
        let index = _.findIndex(this.files, (e) => {
          return e.fileId == fileId;
        }, 0)
        let reason = _.find(this.borderDutiesOptions, { 'value': fileStatusId})
        this.files[index].borderDutiesStatus = reason.name;
        this.files[index].typeId = fileStatusId;
        this.confirmBorderDuties = false;
      }
    } else {
      this.messageService.add({
        severity: 'error', 
        summary: 'Please add a reason for your confirmation'
      });
    }
    
  }

  onDocRowSelect(event) {
    this.downloadLink = undefined;
    this.loadDocument(event.data.documentId, event.data.fileName)
  }

  onDocRowUnselect(event) {
    this.downloadLink = undefined;
  }

  confirmDuties(fileId: number) {
    this.confirmationService.confirm({
      message: `Are you sure you want to Confirm border duties paid for file ${fileId}?`,
      accept: async () => {
        var response = await this.fileService.addBorderAgentFileEvent(fileId, 3, 'Confirmation of Paid border duties');
        if (response == 1) {
          let index = _.findIndex(this.files, (e) => {
            return e.fileId == fileId;
          }, 0);
          this.files[index].borderDutiesStatus = 'Border Duties Paid';
          this.files[index].typeId = 3;
        }
      },
      reject: () => {
        this.borderDutiesSelected = undefined;
      }
    });
  }

  unConfirmDuties(fileId: number) {
    this.confirmationService.confirm({
      message: `Are you sure you want to unconfirm the duties on file ${fileId}`,
      accept: async () => {
        var response = await this.fileService.removeBorderAgentFileEvent(fileId);
        if (response == 1) {
          let index = _.findIndex(this.files, (e) => {
            return e.fileId == fileId;
          }, 0);
          this.files[index].borderDutiesStatus = null;
          this.files[index].typeId = null;
        }
      }, reject: () => {
        this.borderDutiesSelected = undefined;
      }
    })
  }

  public async borderAgentLoads() {
    try {
      let loads = await this.fileService.getBorderAgentLoads();
      if (loads) {
        this.borderLoads = loads;
      }
    }
    catch (err) {
      this.messageService.add({
        severity: 'error', 
        summary: 'Please contact avecs, failed to get loads'
      });
    }
  }

   public async closeIndividualFile(fileId: string) {
    const parts = fileId.split("/");
     const numberPart = parseInt(parts[1], 10);
     let retval = await this.fileService.CloseSelectedFilesBorderAgent(numberPart);
     if (retval) {
       this.messageService.add({
          severity: 'success',
          summary: 'File has successfully been offloaded and closed'
        });
       await this.borderAgentLoads();
     }
     else {
        this.messageService.add({
          severity: 'error',
          summary: 'File failed to close and offloaed. Please contact avecs.'
        });
     }
  }

  async OffloadLoadBorderAgent() {
    let LoadRowId = [];
    for (var item of this.selectedLoadRow) {
      console.log('Selected Load Row', this.selectedLoadRow);
      LoadRowId.push(item.cargoLoadAssignmentId);
      console.log(item.cargoLoadAssignmentId);
      let retval = await this.fileService.OffloadLoadsBorderAgents(item.cargoLoadAssignmentId, 8);
      if (retval == 1) {
        this.messageService.add({
          severity: 'success',
          summary: 'Load has successfully been offloaded, Thank You'
        });
        await this.borderAgentLoads();
      }
      else {
        this.messageService.add({
          severity: 'error',
          summary: 'Failed to offload load'
        });
      }
    }
  }

  test(event) {
    console.log(event);
  }


}

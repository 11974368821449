import { Component, OnInit, ViewEncapsulation, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { ConfirmationService, Message, MessageService } from 'primeng/api';
import * as _ from 'lodash';
import { FileService } from 'src/app/services/file.service';
import { AuthenticationService } from 'src/app/services/auth/authentication.service';
import { LoaderService } from 'src/app/services/loader.service';
import { TimelineService } from 'src/app/services/timeline.service';
import { CargoShippingItem, Cargo, Document, TimelineItem, LegalEntityInfoForFileId, FileCorrespondance } from '../../services/global.interface'

@Component({
  selector: 'app-dt-file-viewer',
  templateUrl: './dt-file-viewer.component.html',
  styleUrls: ['./dt-file-viewer.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ConfirmationService, DatePipe, MessageService]
})
export class DtFileViewerComponent implements OnInit {
  
  @HostListener('window:scroll', ['$event'])
  doScrollCheck(event) {
    if (window.pageYOffset > 400) {
      this.scrollUpButton = true;
    } else {
      this.scrollUpButton = false;
    }
  }

  // CORE VARS
  fileId: number;
  trackType: string;
  // END CORE VARS
  
  // DATA
  fullTerms: any;
  // Consignee Information
  consigneeInformation: LegalEntityInfoForFileId
  // Shipping & Cargo information
  shippingInfo: CargoShippingItem;
  cargoItem: Cargo[] = [];
  // Documents
  documents: Document[];
  correspondance: FileCorrespondance;
  // Timeline
  timeline: TimelineItem[];
  verticalTimeline: TimelineItem[];
  // END DATA

  // BOOLS
  acceptLoading: boolean = false;
  showTerms: boolean = false;
  scrollUpButton: boolean = false;
  timelineLoading: boolean = true;
  // END BOOLS

  // UI HELPER VARS
  showMessage: Message[] = [];
  baseLoadingIcon: string = '<i class="pi pi-spin pi-spinner"></i>';
  // END UI HELPER VARS

  constructor(
    public router: Router,
    private loaderService: LoaderService,
    private route: ActivatedRoute,
    private fileService: FileService,
    private auth: AuthenticationService,
    public timelineService: TimelineService
  ) { }

  ngOnInit(): void {
    var fileId = this.route.snapshot.paramMap.get('fileid');
    var type = this.route.snapshot.paramMap.get('type');

    this.trackType = type;
    this.fileId = +fileId;

    if (fileId && type) {
      this.loadData(this.fileId);
    }
  }

  async loadData(fileId) {

    this.fileId = fileId;
    this.loaderService.activateFullSpinner();
    this.timelineLoading = true;

    this.consigneeInformation = await this.fileService.getLegalEntityInfoForFileId(fileId);
    this.shippingInfo = await this.fileService.getShippingInfo(fileId);
    this.cargoItem = await this.fileService.getCargoInfo(fileId);
    this.loadDocuments();
    this.loadCorrespondance();
    // Validate if tracking is supplier / consignee
    if (this.auth.isConsignee() && !this.cargoItem[0].acceptTerms && this.trackType != 'supplier') {
      this.fullTerms = await this.fileService.getLatestTerms(fileId);
      this.showTerms = true;
    }

    this.loaderService.deactivateFullSpinner();

    this.timeline = await this.timelineService.getAndFormatTimeline(fileId);
    this.verticalTimeline = this.timeline.slice()
    this.verticalTimeline.splice(this.verticalTimeline.length-1, 1);
    this.timelineLoading = false;  
  }

  async loadDocuments() {
    // In its own as a EventEmitter uses it
    this.documents = await this.fileService.getFileDocuments(this.fileId, true);
  }

  async loadCorrespondance() {
    // In its own as a EventEmitter uses it
    this.correspondance = await this.fileService.getCorrespondance(this.fileId);
  }

  scrollTo(id) {
    let el = document.getElementById(id);
    el.scrollIntoView({ behavior: "smooth" });
  }

  setShowMessage(type: string, message: string) {
    if (!_.isEmpty(this.showMessage)) if (this.showMessage[0].summary == message) return;
    this.showMessage = [{ severity: type, summary: message }];
    setTimeout(() => {
      this.showMessage = [];
    }, 4000)
  }

  async acceptTermsSingle(fileId: any) {
    this.acceptLoading = true;
    let termsAccepted = await this.fileService.acceptTerms(fileId);
    if (termsAccepted.errorCode == 0) {
      this.showTerms = false;
    } else {
      this.setShowMessage('error', 'Failed to accept terms');
    }
    this.acceptLoading = false;
  }

  declineTerms() {
    this.showTerms = false;
    this.router.navigate(['client'])
  }
}

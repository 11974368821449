import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';


@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit, AfterViewInit {


  constructor(
    meta: Meta,
    title: Title,
    private router: Router
  ) {

    // title.setTitle('My about page');
    // meta.addTags([
    //   {
    //     name: 'author', content: 'Gary Simon'
    //   },
    //   {
    //     name: 'keywords', content: 'kws'
    //   },
    //   {
    //     name: 'description', content: 'aaaaaaaaaaaaaaaaaaaaaa.'
    //   },
    // ])

  }



  async ngOnInit() {

    window.scrollTo(0, 0);

  }


  async ngAfterViewInit() {

  }

}

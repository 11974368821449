import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { ApiService } from 'src/app/services/api.service';
import { DataService } from '../data.service';
import { DropdownService } from '../dropdowns.data.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import { ToastService } from '../toast.service';
import { LoaderService } from '../loader.service';

@Component({
  selector: 'app-b-personal-info',
  templateUrl: './b-personal-info.component.html',
  styleUrls: ['./b-personal-info.component.scss']
})
export class BPersonalInfoComponent implements OnInit {
  

  @Output() onNextClick = new EventEmitter();
  @Output() onBackClick = new EventEmitter();

  @Input('lastPage') lastPage;

  items: any[] = [];

  // Form Information
  titles = this.dropdown.titles;
  dateOfBirth;
  residentialStatus = this.dropdown.residentialStatus;
  maritalStatus = this.dropdown.maritalStatus;
  employmentStatus = this.dropdown.employmentStatus;
  accountType = this.dropdown.accountType;
  rangeDates;
  monthsToShow = 3;
  currentPage;

  stepperPositions: any[] = [];
  foundProgressItem;

  constructor(public data: DataService,
    private api: ApiService,
    public dropdown: DropdownService,
    private toast: ToastService,
    private loaderService: LoaderService) {
      this.data.getDropdowns()
    }

  ngOnInit(): void {
    this.loaderService.showLoader();
    window.scroll(0,0);
    this.items = [{
      label: "Personal Information",
      formError: false,
      active: 1
    },{
      label: "Current Residential Address Information",
      formError: false,
      active: 0
    },{
      label: "Marital Status & Dependents",
      formError: false,
      active: 0
    },{
      label: "Employment Details",
      formError: false,
      active: 0
    },{
      label: "Mobile banking Account Details",
      formError: false,
      active: 0
    },{
      label: "Financial Information",
      formError: false,
      active: 0
    }];
    this.stepperPositions = [{
      stepTxt: "Personal Information",
      stepCss: "pie-wrapper style-2 progress-17",
      labelTxt: "1/6",
      active: 1
    },{
      stepTxt: "Current Residential Address Information",
      stepCss: "pie-wrapper style-2 progress-33",
      labelTxt: "2/6",
      active: 0
    },{
      stepTxt: "Marital Status & Dependents",
      stepCss: "pie-wrapper style-2 progress-50",
      labelTxt: "3/6",
      active: 0
    },{
      stepTxt: "Employment Details",
      stepCss: "pie-wrapper style-2 progress-67",
      labelTxt: "4/6",
      active: 0
    },{
      stepTxt: "Mobile banking Account Details",
      stepCss: "pie-wrapper style-2 progress-83",
      labelTxt: "5/6",
      active: 0
    },{
      stepTxt: "Financial Information",
      stepCss: "pie-wrapper style-2 progress-100",
      labelTxt: "6/6",
      active: 0
    }];
    this.currentPage = this.items[0].label;
    console.log('Last Page?')
    console.log(this.lastPage)
    if (this.lastPage) {
      this.currentPage = this.items[5].label;
      this.stepperPositions[0].active = 1;
      this.stepperPositions[5].active = 1;
      for (let i = 0; i < this.items.length; i++) {
        if (i == 5) {
          this.items[i].active = 1;
        } else {
          this.items[i].active = 2;
        }
      }
    }

    // Code for manipulation of current page

    // var pageToLoad = 4;

    // this.currentPage = this.items[pageToLoad - 1].label;
    // this.stepperPositions[0].active = 0;
    // this.stepperPositions[pageToLoad - 1].active = 1;
    // for (let i = 0; i < this.items.length; i++) {
    //   if (i == (pageToLoad - 1)) {
    //     this.items[i].active = 1;
    //     break;
    //   } else {
    //     this.items[i].active = 2;
    //   }
    // }

    // End of code for manipulation of current page

    this.loaderService.hideLoader()
  }

  getActiveProgressStyle(nextStepper?: boolean) {
    if (nextStepper) {
      let indexOfItem = _.findIndex(this.stepperPositions, function(o) { return o.active == 1 });
      return this.stepperPositions[indexOfItem + 1];
    } else {
      return _.find(this.stepperPositions, { 'active': 1 });
    }
  }

  shiftPressed: boolean = false;

  onKeyDown(event, direction: string) {
    if (direction == 'in') {
      if (event.shiftKey) {
        if (event.key === "Tab") {
          event.preventDefault();
          // console.log(event);
        }
      }
    } else if (direction == 'inner-out') {
      console.log('direction inner out')
      if (this.data.application.personDetails._postalSameAsPhysical) {
        if (event.key === "Tab") {
          event.preventDefault();
          // console.log(event);
        }
      }
    } else {
      if (!event.shiftKey) { 
        if (event.key === "Tab") {
          event.preventDefault();
          // console.log(event);
        }
      }
    }  
  }

  back(item: string) {
    this.loaderService.showLoader();
    if (item == this.items[0].label) {
      this.loaderService.hideLoader()
      this.onBackClick.emit();
    } else {
      let indexOfItem = _.findIndex(this.items, function(o) { return o.label == item });
      this.currentPage = this.items[indexOfItem - 1].label
      let foundIndex = _.findIndex(this.stepperPositions, function(o) { return o.active == 1 });
      this.stepperPositions[foundIndex].active = 0;
      this.stepperPositions[foundIndex - 1].active = 1;
      window.scroll(0,0);
      this.saveBack(item);
      this.save(false)
      this.loaderService.hideLoader()
    }
  }

  next(item) {
    this.loaderService.showLoader();
    console.log(item)
    if (item == this.items[5].label) {
      this.save(true)
      this.loaderService.hideLoader();
    } else {
      if (this.saveAndValidate(item)) {
        let indexOfItem = _.findIndex(this.items, function(o) { return o.label == item });
        this.currentPage = this.items[indexOfItem + 1].label
        let foundIndex = _.findIndex(this.stepperPositions, function(o) { return o.active == 1 });
        this.stepperPositions[foundIndex].active = 0;
        this.stepperPositions[foundIndex + 1].active = 1;
        window.scroll(0,0);
        this.save(false)
        this.loaderService.hideLoader();
      } else {
        this.loaderService.hideLoader();
      }
    }
    
  }

  saveBack(item: string) {
    let indexOfItem = _.findIndex(this.items, function(o) { return o.label == item });
    this.items[indexOfItem].active--;
    setTimeout(() => {
      this.items[indexOfItem - 1].active--;
    },
    500);
  }

  saveNext(item: string) {
    let indexOfItem = _.findIndex(this.items, function(o) { return o.label == item });
    this.items[indexOfItem].active++;
    setTimeout(() => 
    {
      this.items[indexOfItem + 1].active++;
    },
    500);
  }

  checkNaN(toCheck) {
    return isNaN(toCheck);
  }

  saveAndValidate(routeItem) : boolean {
    console.log(routeItem)
    let foundIndex = _.findIndex(this.items, function(o) { return o.label == routeItem}) 
    console.log(foundIndex)
    console.log(this.items[foundIndex])
    if (routeItem == this.items[0].label) {
      console.log('if zero')
      console.log(this.data.application.personDetails.title)
      console.log(this.data.application.personDetails.firstName)
      console.log(this.data.application.personDetails.lastName)
      console.log(this.data.application.personDetails.dateOfBirth)
      console.log(this.data.application.personDetails.IDNumber)
      if (this.data.application.personDetails.title &&
          this.data.application.personDetails.firstName &&
          this.data.application.personDetails.lastName &&
          this.data.application.personDetails.dateOfBirth &&
          this.data.application.personDetails.IDNumber) {
            this.items[0].formError = false;
            this.saveNext(this.items[0].label)
            return true;
          } else {
            this.toast.toastError('Missing Fields!', 'There are some missing fields, please make sure to check you have entered all fields correctly', 0);
            this.items[0].formError = true;
          }
    } else if (routeItem == this.items[1].label) {
      console.log('if one')
      if (this.data.application.personDetails.addressLine1 &&
          this.data.application.personDetails.suburb &&
          this.data.application.personDetails.city &&
          this.data.application.personDetails.livedSinceDate &&
          this.data.application.personDetails.residentialStatus) {
            if (this.data.application.personDetails._postalSameAsPhysical) {
              console.log('Physical')
              this.items[1].formError = false;
              this.saveNext(this.items[1].label)
              return true;
            } else {
              if (this.data.application.personDetails.postalAddressLine1 &&
                  this.data.application.personDetails.postalCity &&
                  this.data.application.personDetails.postalPostalCode &&
                  this.data.application.personDetails.postalSuburb) {
                    this.items[1].formError = false;
                    this.saveNext(this.items[1].label)
                    return true;
                  } else {
                    this.toast.toastError('Missing Fields!', 'There are some missing fields, please make sure to check you have entered all fields correctly', 0);
                    this.items[1].formError = true;
                  }
            }

      } else {
        console.log('form error')
        this.toast.toastError('Missing Fields!', 'There are some missing fields, please make sure to check you have entered all fields correctly', 0);
        this.items[1].formError = true;
      }
    } else if (routeItem == this.items[2].label) {
      console.log('if two')
      if (this.data.application.personDetails.maritalStatus &&
          (this.data.application.personDetails.numberOfDependantsChildren || this.data.application.personDetails.numberOfDependantsChildren == 0 || !isNaN(this.data.application.personDetails.numberOfDependantsChildren)) &&
          (this.data.application.personDetails.numberOfDependantsOther || this.data.application.personDetails.numberOfDependantsOther == 0 || !isNaN(this.data.application.personDetails.numberOfDependantsOther)) &&
          (this.data.application.personDetails.numberOfDependantsSpouse || this.data.application.personDetails.numberOfDependantsSpouse == 0 || !isNaN(this.data.application.personDetails.numberOfDependantsSpouse)) &&
          this.data.application.personDetails.nextOfKinTitle &&
          this.data.application.personDetails.nextOfKinFirstName &&
          this.data.application.personDetails.nextOfKinLastName &&
          this.data.application.personDetails.nextOfKinMobileNumber) {
            this.items[2].formError = false;
            this.saveNext(this.items[2].label);
            return true;
          }
          else {
            this.items[2].formError = true;
            this.toast.toastError('Missing Fields!', 'There are some missing fields, please make sure to check you have entered all fields correctly', 0);
          }
    } else if (routeItem == this.items[3].label) {
      console.log('if three')
      if (this.data.application.personDetails.employmentStatus &&
          this.data.application.personDetails.employmentAddress &&
          this.data.application.personDetails.employmentCity &&
          this.data.application.personDetails.employmentTellNumber &&
          this.data.application.personDetails.employmentContractExpiryDate &&
          this.data.application.personDetails.employmentDesignation &&
          this.data.application.personDetails.employmentOccupation &&
          this.data.application.personDetails.employmentDate &&
          this.data.application.personDetails.highestLevelEducation &&
          this.data.application.personDetails.grossMonthlySalary &&
          this.data.application.personDetails.netMonthlySalary &&
          this.data.application.personDetails.nameOfEmployer &&
          this.data.application.personDetails.employmentPeriod) {
            this.items[3].formError = false;
            this.saveNext(this.items[3].label);
            return true;
          }
          else {
            this.items[3].formError = true;
            this.toast.toastError('Missing Fields!', 'There are some missing fields, please make sure to check you have entered all fields correctly', 0);
          }
    } else if (routeItem == this.items[4].label) {
      console.log('if four')
      if (this.data.application.personDetails.accountType && 
          this.data.application.personDetails.accountName &&
          this.data.application.personDetails.bankName &&
          this.data.application.personDetails.branchName &&
          this.data.application.personDetails.branchCode &&
          this.data.application.personDetails.accountNumber &&
          this.data.application.personDetails.dateAccountOpened) {
            this.items[4].formError = false;
            this.saveNext(this.items[4].label);
            return true;
          }
          else {
            this.items[4].formError = true;
            this.toast.toastError('Missing Fields!', 'There are some missing fields, please make sure to check you have entered all fields correctly', 0);
          }
    }
  }

  checkIfActive(item: string) : Boolean {
    let foundItem = _.find(this.items, { 'label': item, 'active': 1 })
    if (foundItem) {
      return true;
    } else {
      return false;
    }
  }

  getActiveNum(item: string) : Number {
    let activeNumSelected = _.find(this.items, { 'label': item });
    return activeNumSelected.active;
  }

  checkIfFormComplete() : Boolean {
    let lastItem = _.last(this.items)
    if (lastItem.active == 1) {
      return false;
    }
    return true;
  }

  getYearRange(yearsBack: number, yearsAhead: number = 0) {
    return moment().subtract(yearsBack, 'year').year() + ':' + moment().add(yearsAhead, 'year').year();
  }

  save(routeNext) {
    console.log(this.data.application.personDetails)
    let formattedJson: any = this.data.application.personDetails
    formattedJson.applicationId = this.data.application.applicationId
    // formattedJson.applicationId = 1
    formattedJson.toNextStep = routeNext
    if (this.data.application.personDetails._postalSameAsPhysical) {
      formattedJson.postalAddressLine1 = this.data.application.personDetails.addressLine1
      formattedJson.postalAddressLine2 = this.data.application.personDetails.addressLine2
      formattedJson.postalSuburb = this.data.application.personDetails.suburb
      formattedJson.postalCity = this.data.application.personDetails.city
      formattedJson.postalPostalCode = this.data.application.personDetails.postalCode
    }
    if (this.data.application.personDetails.residentialStatus == 'other') {
      formattedJson.residentialStatus = this.data.application.personDetails._residentialStatusOther
    }
    if (this.data.application.personDetails.maritalStatus == 'other') {
      formattedJson.maritalStatus = this.data.application.personDetails._maritalStatusOther
    }
    if (this.data.application.personDetails.accountType == 'other') {
      formattedJson.accountType = this.data.application.personDetails._accountTypeOther
    }
    if (this.data.application.personDetails.employmentPeriod && this.data.application.personDetails.employmentPeriod.length == 2) {
      formattedJson.employmentPeriodStart =  this.data.application.personDetails.employmentPeriod[0]
      formattedJson.employmentPeriodEnd =  this.data.application.personDetails.employmentPeriod[1]
    }

    this.api.updateApplicationPersonDetails(formattedJson).subscribe((resp) => {
      if (resp['errorCode'] == 0) {
        if (routeNext) {
          this.onNextClick.emit();
        }
      }
      else {
        if (routeNext) {
          this.toast.toastError('Unable to communicate', 'Something is wrong with connecting to the services, please try again!', 0);
        }
      }
    })


    console.log(formattedJson)
  }

  getCurrencyCode() {
    if (this.data.application.formDetails.selectedCurrency) {
      return this.data.application.formDetails.selectedCurrency.data.currencyCode
    }
    else {
      return 'ZAR'
    }
  }

  getLocale() {
    if (this.data.application.formDetails.selectedCurrency) {
      return this.data.application.formDetails.selectedCurrency.locale
    }
    else {
      return 'en-ZA'
    }
  }

}

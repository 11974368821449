import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from './services/auth/authentication.service';
import { FileService } from './services/file.service';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html',
    styleUrls: ['./app.menu.component.scss'],
})
export class AppMenuComponent implements OnInit {

    portal: boolean = true;

    constructor(
        public auth: AuthenticationService,
        public  fileService: FileService
    ) {  }

    ngOnInit() : void { }
}
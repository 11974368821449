import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as _ from 'lodash';
import { LogBase } from '../services/logger.service';
import { Router } from '@angular/router';
import { LogInService } from '../services/rxjs.service';
import { LoginUsernameService } from '../services/rxjs.service';

export interface HttpResponse {
  errorCode: number;
  errorMessage: string;
  userId: number;
  userName: string;
  token: string;
  device: string;
  tokenExpires: Date;
  result: any;
  editPermission: any;
  viewPermission: any;
  getMs: number;
  callingMethod: string;
  inParams: any;
}

@Injectable()
export class WebApiService {

  constructor(

    private http: HttpClient,
    private searchsvc: LogInService,
    private router: Router,
    private usernamesvc: LoginUsernameService,
    private log: LogBase

  ) { }

  // Strict typing API Get
  async apiGet(url: string) : Promise<HttpResponse> {
    try {
      let token = await window.sessionStorage.getItem('token');
      if (!token) token = ''
      let device = await window.sessionStorage.getItem('device');
      if (!device) device = ''
      let portalUserId = await window.sessionStorage.getItem('userId');
      if (!portalUserId) portalUserId = ''

      const headers = new HttpHeaders()
        .set('content-type', 'application/json')
        .set('Token', token)
        .set('Device', device)
        .set('Id', portalUserId);

      let response = await this.http.get<HttpResponse>(url, { headers: headers }).toPromise();

      if (response.errorCode == 2) {
        window.sessionStorage.clear();
 
        this.router.navigate(['/home']);
      }
      return response;
    } catch (error) {
      return new Promise((resolve, reject) => {
        this.log.error(error);
        reject(error.toString());
      });
    }
  }

  // Strict typing API post
  async apiPost(url: string, payload: any) : Promise<HttpResponse> {
    try {
      let token = await window.sessionStorage.getItem('token');
      if (!token) token = ''
      let device = await window.sessionStorage.getItem('device');
      if (!device) device = ''
      let portalUserId = await window.sessionStorage.getItem('userId');
      if (!portalUserId) portalUserId = ''

      const headers = new HttpHeaders()
        .set('content-type', 'application/json')
        .set('Token', token)
        .set('Device', device)
        .set('Id', portalUserId);

      let response = await this.http.post<HttpResponse>(url, payload, { headers: headers }).toPromise();

      if (response.errorCode == 2) {
        window.sessionStorage.clear();

        // this.searchsvc.sendLoggedIn(false);
        // this.usernamesvc.sendProfile('');

        this.router.navigate(['/home']);
      }

      return response;
    } catch (error) {
      this.log.error(error);
      return new Promise((resolve, reject) => {
        reject(error.toString());
      })

    }
  }

  async get(apiUrl: string) {

    let res: any;

    let token = await window.sessionStorage.getItem('token');
    if (!token) token = ''
    let device = await window.sessionStorage.getItem('device');
    if (!device) device = ''
    let portalUserId = await window.sessionStorage.getItem('userId');
    if (!portalUserId) portalUserId = ''

    const headers = new HttpHeaders()
      .set('content-type', 'application/json')
      //.set('Access-Control-Allow-Origin', '*')
      .set('Token', token)
      .set('Device', device)
      .set('Id', portalUserId);
    //console.log(headers);
    try {
      const response = await this.http.get(apiUrl, { headers: headers }).toPromise();
      //console.log(response)
      const res = response;
      if (res['errorCode'] === 0 || res['errorCode'] == 15) {
        res['result'] = res['result'];
        //console.log(res);
        return res;
      } else if (res['errorCode'] === 2) {
        sessionStorage.removeItem('userId');
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('device');
        sessionStorage.removeItem('tokenExpires');
        sessionStorage.removeItem('editPermission');
        sessionStorage.removeItem('accessRights');
        sessionStorage.removeItem('viewPermission');
        sessionStorage.removeItem('userName');
        this.searchsvc.sendLoggedIn(false);
        this.usernamesvc.sendProfile('');

        this.router.navigate(['/home']);
      } else {
        return false;
      }

    } catch (error) {
      this.log.error(error);
      return error;
    }
  }

  async delete(apiUrl: string,) {
    let token = await window.sessionStorage.getItem('token');
    if (!token) token = '';
    let portalUserId = await window.sessionStorage.getItem('userId');
    if (!portalUserId) portalUserId = '';

    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Token', token)
      .set('Id', portalUserId);

    try {
      const response = await this.http.delete(apiUrl, { headers: headers }).toPromise();
      if (response['errorCode'] === 0 || response['errorCode'] === 15) {
        return response;
      } else if (response['errorCode'] === -2) {
        // TODO: Change this section
        window.sessionStorage.clear();
        this.searchsvc.sendLoggedIn(false);
        this.usernamesvc.sendProfile('');
        this.router.navigate(['']);
      } else {
        return false;
      }
    } catch (error) {
      this.log.error(error);
      return error;
    }
  }

  async post(apiUrl: string, variables: any) : Promise<HttpResponse> {

    let token = await window.sessionStorage.getItem('token');
    if (!token) token = '';
    let device = await window.sessionStorage.getItem('device');
    if (!device) device = '';
    let portalUserId = await window.sessionStorage.getItem('userId');
    if (!portalUserId) portalUserId = '';

    const headers = new HttpHeaders()
      .set('content-type', 'application/json')
      .set('Access-Control-Allow-Origin', '*')
      .set('Token', token)
      .set('Id', portalUserId);

    try {
      const response = await this.http.post<HttpResponse>(apiUrl, variables, { headers: headers }).toPromise();
      if (response['errorCode'] === 0 || response['errorCode'] === 15) {
        return response;
      } else if (response['errorCode'] === -2) {
        // TODO: Clean up and remove
        sessionStorage.removeItem('userId');
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('device');
        sessionStorage.removeItem('tokenExpires');
        sessionStorage.removeItem('editPermission');
        sessionStorage.removeItem('accessRights');
        sessionStorage.removeItem('viewPermission');
        sessionStorage.removeItem('userName');
        this.searchsvc.sendLoggedIn(false);
        this.usernamesvc.sendProfile('');

        this.router.navigate(['']);
        //return false;
      }
      else {
        // this.log.info(res);
        return response;
      }


    } catch (e) {
      this.log.error(e);
      return e;
    }
  }

  async currenciesPost(user: string, apiUrl: string, variables: string) {

    let res: any;

    let token = await window.sessionStorage.getItem('token');
    if (!token) token = ''
    let device = await window.sessionStorage.getItem('device');
    if (!device) device = ''
    let portalUserId = await window.sessionStorage.getItem('userId');
    if (!portalUserId) portalUserId = ''

    const headers = new HttpHeaders()
      .set('content-type', 'application/json')
      //.set('Access-Control-Allow-Origin', '*')
      .set('Token', token)
      .set('Device', device)
      .set('Id', portalUserId);

    try {
      const response = await this.http.post(apiUrl, variables, { headers: headers }).toPromise();
      if (response['status'] === 200) {
        const res = response;
        if (res['errorCode'] === 0) {
          res['result'] = JSON.stringify(res['result']);
          return res;
        } else {
          return false;
        }
      }
    } catch (e) {
      this.log.error(e);
      return e;
    }
  }

  // new Observable methods

  doPost(url: string, payload) {

    try {

    let token = window.sessionStorage.getItem('token');
    if (!token) token = '';
    let device = window.sessionStorage.getItem('device');
    if (!device) device = '';
    let portalUserId = window.sessionStorage.getItem('userId');
    if (!portalUserId) portalUserId = '';

    const headers = new HttpHeaders()
      .set('content-type', 'application/json')
      .set('Token', token)
      .set('Device', device)
      .set('Id', portalUserId);

    //console.log(headers)

      // this.log.info('Headers: ', JSON.stringify(options));
      return new Observable(o => {
        this.http.post(url, payload, { headers: headers }).subscribe(response => {
          // let test = JSON.parse(JSON.stringify(response));
          // this.log.info('API Post Response: ');
          // this.log.info(test)
          o.next(response);
        }),
          error => {
            this.log.error('API Post Error: ', error);
            o.next(error);
          }
      });
    } catch (error) {
      var responseError = {
        errorCode: 2,
        errorMessage: error,
        payload: JSON.stringify(payload)
      }
      return new Observable(o => {
        this.log.error('API Post Critical Error; ', responseError);
        o.next(responseError);
      });
    }
  }

  doStandardHeadersPost(url: string, payload) {
    try {
      let options;
      options = {
        headers: new HttpHeaders().set('Content-Type', 'application/json')
      }
      return new Observable(o => {
        this.http.post(url, payload, options).subscribe(response => {
          o.next(response);
        }),
          error => {
            this.log.error('API Post Error: ', error);
            o.next(error);
          }
      });
    } catch (error) {
      var responseError = {
        errorCode: 2,
        errorMessage: error,
        payload: payload
      }
      return new Observable(o => {
        this.log.error('API Post Critical Error; ', responseError);
        o.next(responseError);
      });
    }
  }

  doGet(url: string) {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Token', sessionStorage.getItem('token'));
    headers.append('Device', sessionStorage.getItem('device'));
    headers.append('Id', sessionStorage.getItem('userId'));
    try {
      const options = {
        headers: headers
      };
      return new Observable(o => {
        this.http.get(url, options).subscribe(response => {
          this.log.info('API Get Response: ', response);
          o.next(response);
        }),
          error => {
            this.log.error('API Get Error: ', error);
            o.next(error)
          }
      });
    } catch (error) {
      var responseError = {
        errorCode: 2,
        errorMessage: error,
      }
      return new Observable(o => {
        this.log.error('API Get Critical Error: ', responseError);
        o.next(responseError);
      });
    }
  }

  doFinanceGet(url: string) {
    try {
      let options = {
        headers: new HttpHeaders({
          'Content-Type': 'applications/json',
          'Token': window.sessionStorage.getItem('finance-token')
        })
      }
      //console.log(options);
      return new Observable(o => {
        this.http.get(url, options).subscribe(response => {
          this.log.info(`API Get Response: ${response}`)
          o.next(response);
        }),
        error => {
          this.log.error(`API Get Error: ${error}`);
          o.next(error);
        }
      });
    } catch (error) {
      var responseError = {
        errorCode: 2, 
        errorMessage: error
      }
      this.log.error(`API Get Critical Error: ${responseError}`);
      return new Observable(o => {
        o.next(responseError);
      })
    }
  } 

}
import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  application = {
    applicationId: null,
    formDetails: {
      deposit: null,
      estimatedMonthlyRepayments: null,
      estimatedTotalExpenses: null,
      interestRate: 14,
      loanTerm: 60,
      monthlySalaryAfterTax: null,
      selectedCountry: null,
      selectedCurrency: null,
      vehicleValue: null
    },
    personDetails: {
      fullName: null,
      emailAddress: null,
      cellNumber: null,
      title: null,
      firstName: null,
      lastName: null,
      dateOfBirth: null,
      IDNumber: null,
      homeTellNumber: null,
      workTellNumber: null,
      addressLine1: null,
      addressLine2: null,
      suburb: null,
      city: null,
      postalCode: null,
      livedSinceDate: null,
      residentialStatus: null,
      _residentialStatusOther: null,
      _postalSameAsPhysical: null,
      postalAddressLine1: null,
      postalAddressLine2: null,
      postalSuburb: null,
      postalCity: null,
      postalPostalCode: null,
      maritalStatus: null,
      _maritalStatusOther: null,
      numberOfDependantsSpouse: null,
      numberOfDependantsChildren: null,
      numberOfDependantsOther: null,
      nextOfKinTitle: null,
      nextOfKinFirstName: null,
      nextOfKinLastName: null,
      nextOfKinMobileNumber: null,
      employmentStatus: null,
      employmentAddress: null,
      employmentCity: null,
      employmentTellNumber: null,
      employmentContractExpiryDate: null,
      employmentDesignation: null,
      employmentOccupation: null,
      employmentDate: null,
      highestLevelEducation: null,
      grossMonthlySalary: null,
      netMonthlySalary: null,
      nameOfEmployer: null,
      employmentPeriod: [],
      accountType: null,
      _accountTypeOther: null,
      accountName: null,
      salaryAccount: null,
      bankName: null,
      branchName: null,
      branchCode: null,
      accountNumber: null,
      dateAccountOpened: null,
      rentMortgagePayments: null,
      vehiclePayments: null,
      otherVeritableMonthlyIncome: null,
      otherDebtPayments: null,
      bonusThirteenthCheque: null,
      otherMonthlyCommitments: null
    },
    vehicleDetails: {
      vehicleMake: null,
      vehicleModel: null,
      manufactureYear: null,
      other: null,
      fuelType: null,
      engineSize: null,
      vehicleValue: null
    },
    companyDetails: {
      schemeId: null,
      companyName: null,
      companyAddress: null
    },
    stanbicTermsAccepted: null,
    loanType: null,
    stanbicClient: null,
  }
  application1 = {
    applicationId: null,
    formDetails: {
      deposit: 5555,
      estimatedMonthlyRepayments: null,
      estimatedTotalExpenses: 1234,
      interestRate: 12.5,
      loanTerm: 60,
      monthlySalaryAfterTax: 10000,
      selectedCountry: null,
      selectedCurrency: null,
      vehicleValue: 100000
    },
    personDetails: {
      fullName: "Keenen O'Brien",
      firstName: "Keenen",
      lastName: "O'Brien",
      emailAddress: "keenenobrien@gmail.com",
      cellNumber: "0832860250",
      telNo: "0832860251",
      IDNumber: "9705145093089",
      addressLine1: "addy1",
      addressLine2: "addy2",
      addressLine3: "addy3",
      suburb: "Pinetown",
      city: "Durban",
      postalCode: "3610"
    },
    vehicleDetails: {
      vehicleMake: "VW",
      vehicleModel: "Golf 1",
      manufactureYear: null,
      other: "Nice rims",
      fuelType: 1,
      engineSize: 1600,
      vehicleValue: 100000
    },
    companyDetails: {
      schemeId: null,
      companyName: null,
      companyAddress: null
    },
    stanbicTermsAccepted: null,
    loanType: null,
    stanbicClient: null,

  }

  documentUploadStatus = [{
    type: 'id',
    status: 0
  },{
    type: 'pin',
    status: 0
  },{
    type: 'poi',
    status: 0
  },{
    type: 'inv',
    status: 0
  },{
    type: 'bs',
    status: 0
  },{
    type: 'app',
    status: 0
  }];

  countries = [];
  currencies = [];

  accountExists: boolean = false;
  loggedIn: boolean = false;

  constructor(private api: ApiService) {
    
    
   }
  
  getDropdowns() {
    this.api.getDropdowns().subscribe(data => {
      this.countries = []
      this.currencies = []
      if (data['errorCode'] == 0) {
        data['result']['countries'].forEach(country => {
          this.countries.push({
            id: country.id,
            name: country.countryTxt,
            data: country,
            primaryCurrency: country.primaryCurrency
          })
        });

        data['result']['currencies'].forEach(currency => {
          this.currencies.push({
            id: currency.id,
            name: currency.currencyTxt,
            data: currency,
            currencyCode: currency.currencyCode,
            locale: currency.locale
          })
        });
      }
      else {
        //Handle errors here
        console.error('ERROR')
      }
    })
  }
}

import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { DownloadService } from '../download.service';
import { LoaderService } from '../loader.service';

@Component({
  selector: 'app-k-review-application',
  templateUrl: './k-review-application.component.html',
  styleUrls: ['./k-review-application.component.scss']
})
export class KReviewApplicationComponent implements OnInit {

  constructor(
    private data: DataService,
    private download: DownloadService
  ) { }

  ngOnInit(): void {
    window.scroll(0,0);
  }

  loanApplicationFormDownload() {
    if (this.data.application.loanType == 1) { // Personal
     this.download.downloadPersonalAppForm();
    } else if (this.data.application.loanType == 2) { // Business
     this.download.downloadBusinessAppForm();
    } else {
     // console.log('test')
     // window.location.href = '/assets/finance/forms/Business Loan Application Form.pdf';
    }
  }

  next() {
    
  }

}

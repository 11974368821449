import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MessageService } from 'primeng/api';
import { AuthenticationService } from 'src/app/services/auth/authentication.service';


@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  encapsulation: ViewEncapsulation.None 
})

export class ResetPasswordComponent implements OnInit {

  currentPassword: string;
  newPassword: string;
  newPasswordConfirmation: string;

  email: string;
  emailConfirm: string;

  sms: string;
  smsConfirm: string;

  constructor(
    private auth: AuthenticationService,
    private messageService: MessageService,
    private authService: AuthenticationService
    ) { }

  async ngOnInit() { }

  changePassword() {
    if (this.newPassword == this.newPasswordConfirmation) {
      // let passwordChange = this.authService.changePassword(this.currentPassword, this.newPassword);
      // if (passwordChange) {
      //   this.messageService.add({
      //     severity: 'success', 
      //     summary: 'You have successfully changed your password'
      //   });
      // } else {
      //   this.messageService.add({
      //     severity: 'error', 
      //     summary: 'Failed to change your password, please make sure current password is correct'
      //   });
      // }
    } else {
      this.messageService.add({
        severity: 'warning',
        summary: 'Your passwords do not match'
      });
    }
  }

  obfuscateEmail() {
    return this.authService.getEmail();
  }

  beginEmailOTP() {
    
  }

  initOTP(type: number) {
    if (type == 1) { // email
      
    } else if (type == 2) { // sms

    }
  }

}
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-help-centre',
  templateUrl: './help-centre.component.html',
  styleUrls: ['./help-centre.component.css']
})
export class HelpCentreComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { DataService } from '../data.service';
import { LoaderService } from '../loader.service';
import { ToastService } from '../toast.service';

@Component({
  selector: 'app-d-open-sb-account',
  templateUrl: './d-open-sb-account.component.html',
  styleUrls: ['./d-open-sb-account.component.scss']
})
export class DOpenSbAccountComponent implements OnInit {

  @Output() onNextClick = new EventEmitter();

  constructor(private api: ApiService,
  public data: DataService,
  private toast: ToastService,
  private loaderService: LoaderService) { }

  ngOnInit(): void {
    window.scroll(0,0);
  }

  changed() {
    this.data.application.stanbicTermsAccepted = !this.data.application.stanbicTermsAccepted
  }

  stanbicYes() {
    this.data.application.stanbicTermsAccepted = 1
    this.next()
  }

  next() {
    this.loaderService.showLoader();
    this.api.updateApplicationAcceptStanbicTerms(this.data.application.applicationId).subscribe((resp) => {
      if (resp['errorCode'] == 0) {
        this.loaderService.hideLoader();
        this.onNextClick.emit();
      }
      else {
        this.loaderService.hideLoader();
        this.toast.toastError('Unable to communicate', 'Something is wrong with connecting to the services, please try again!', 0);
      }
    })
  }

}

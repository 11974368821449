import { Component, Input } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'dtText',
  template: '<p><strong>{{ displayText }}:</strong> {{ format(itemValue)  }}</p>',
  styles: [
    'p { margin-bottom: 0px; } @media screen and (max-width: 768px) { strong { width 100%; display: block; padding-top: 8px; } p { text-align: center; } }' 
  ]
})
export class DtTextComponent {
  @Input('text') displayText: string;
  @Input('value') itemValue: String | Date;

  constructor() { }

  format(itemValue) : String {
    if (!itemValue) return "";
    var dateTest = moment(itemValue);
    if(dateTest.isValid()) {
      console.log(itemValue)
      let momentValue = moment(itemValue);
      if (momentValue.isSameOrBefore(moment('2001-05-05 00:00:00.000'))) return "";
      else return momentValue.format('DD.MM.YYYY').toString();
    } else return itemValue;
  }

}

import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { ApiService } from './api.service';
import { TimelineItem } from './global.interface';



@Injectable({
  providedIn: 'root'
})
export class TimelineService {

  currentFileId: number;
  timeline: TimelineItem[];
  assetsFolder: string = '/assets/portal/timeline';
  countryLoc: string = 'https://flagicons.lipis.dev/flags/4x3';

  constructor(private api: ApiService) { }

  async getAndFormatTimeline(fileId: number) : Promise<TimelineItem[]> {
    let momentStart = moment();
    if (this.currentFileId == fileId) return this.timeline;
    else {
      this.currentFileId = fileId;
      let overallTimeline: TimelineItem[] = [];
      try {
        let timeline: TimelineItem[] = (await this.api.getPortalTimelineByFileId(fileId)).result;
        let orderIncrement: number = 1
        for (let i = 0; i < timeline.length; i++) {
          if (!timeline[i]) break;
          // Filter find item at index and insert then take other value and push after
          let foundItemWithOverride = _.findIndex(timeline, { 'orderOverride': (orderIncrement) });
          if (foundItemWithOverride != -1) {
            if (!_.includes(overallTimeline, timeline[foundItemWithOverride])) {
              overallTimeline.push(timeline[foundItemWithOverride])
              timeline.splice(foundItemWithOverride, 1); 
            }
          } else {
            overallTimeline.push(timeline[i])
            timeline.splice(i, 1)
          }
          i--;
          orderIncrement++;
        }
        // Add one blank item to cater for CSS and Styling
        overallTimeline.push({
          status: null,
          date: null, 
          buttons: false,
          buttonGoTo: null, 
          genericIds: null, 
          image: null, 
          orderOverride: null, 
          completionStatus: 0
        });
      } catch (error) {
        console.log(error);
      }
      this.timeline = overallTimeline;
      let momentAfter = moment();
      console.log(moment(momentStart).diff(momentAfter, 'milliseconds'));
      return overallTimeline;
    }
  }

  getImageForTimeline(image: string) : string {
    if(!image) return;
    switch(true) {
      case image.startsWith('country'):
        let countrySplit = image.split(':');
        return `${this.countryLoc}/${countrySplit[1]}.svg`;
      default:
        return `${this.assetsFolder}/${image}.svg`;
    }
  }
}
import { Injectable } from '@angular/core';
import * as md5 from 'md5';
import { Gravatar } from './global.interface';
import { WebApiService } from './web.api.service';

@Injectable({
  providedIn: 'root'
})
export class GravatarService {

  private gravatarUrl: string = 'https://en.gravatar.com/';
  private gravatarInfo: Gravatar;
  private userEmail: string;
  private userHash: string;

  constructor(private api: WebApiService) { }

  public async setGravatar(email: string) {
    if (!this.gravatarInfo) {
      if (email) {
        this.userEmail = email;
        this.userHash = this.convertToMd5(this.userEmail);        
        let url = this.gravatarUrl + this.userHash + '.json';
        let response = await this.api.get(url);
        if (response && response.entry) {
          this.gravatarInfo = response.entry[0];
        }
      }
    }
  }

  public getThumbnail() {
    if (this.gravatarInfo) return this.gravatarInfo.thumbnailUrl;
    return `${this.gravatarUrl}avatar`
  }

  private convertToMd5(inputString: string) : string {
    if (inputString) {
      var trimmedAndLowerCaseInput = inputString.trim().toLocaleLowerCase();
      return md5(trimmedAndLowerCaseInput);
    }
    return undefined
  }
}

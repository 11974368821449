import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs'; 

@Injectable()
export class LogInService {
    
    private subject = new Subject<any>();
    
    sendLoggedIn(message: boolean) {
        this.subject.next({ flag: message });
    }
    
    clearLoggedIn() {
        this.subject.next();
    }
    
    getLoggedIn(): Observable<any> {
        return this.subject.asObservable();
    }
}

export class LoginUsernameService{
    
    private subject = new Subject<any>();
    
    sendProfile(message: string) {
        this.subject.next({ name: message });
    }
    
    clearProfile() {
        this.subject.next();
    }
    
    getProfile(): Observable<any> {
        return this.subject.asObservable();
    }
}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  private loader: boolean = false;

  private fullSpinner: boolean = false;

  constructor() { }

  public loaderStatus() : boolean { return this.loader; }

  public activateLoader() : void { this.loader = true; }

  public deactivateLoader() : void { this.loader = false; }

  public showSpinner = this.activateLoader;

  public hideSpinner = this.deactivateLoader;

  public fullSpinnerStatus() : boolean { return this.fullSpinner; }
  public activateFullSpinner() : void { this.fullSpinner = true; }
  public deactivateFullSpinner() : void { this.fullSpinner = false; }
  
}

import { Component, OnChanges, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { MessageService } from 'primeng/api';
import { AuthenticationService } from './services/auth/authentication.service';
import { LoaderService } from './services/loader.service';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { Location } from '@angular/common';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {

    menuOpen: boolean = true;

    portal: boolean = true;

    constructor(
        public auth: AuthenticationService,
        public loaderService: LoaderService
    ) { }

    ngOnInit() : void { }

    setMenu(event) {
        this.menuOpen = event;
    }

}

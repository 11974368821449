import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { CountryData, CurrencyData, LoanTerm, FinanceCalculationScreen } from '../finance.interfaces';
import { ApiService } from 'src/app/services/api.service';
import { DataService } from 'src/app/components/complete-finance/data.service';
import * as _ from 'lodash';    
import { ToastService } from '../toast.service';
import { LoaderService } from '../loader.service';

@Component({
  selector: 'app-a-calculator',
  templateUrl: './a-calculator.component.html',
  styleUrls: ['./a-calculator.component.scss']
})
export class ACalculatorComponent implements OnInit {

  @Output() onNextClick = new EventEmitter();
  loanTerms: LoanTerm[];
  selectedLoanTerm: LoanTerm;

  vehicleValue: number;

  formError: boolean = false;

  constructor(
    private api: ApiService,
    public data: DataService,
    private toast: ToastService,
    private loaderService: LoaderService
  ) { }

  ngOnInit(): void {
    window.scroll(0,0);
    this.loanTerms = [{
      id: 1, 
      term: '12 Months',
      data: 12
    },{
      id: 2, 
      term: '24 Months',
      data: 24
    },{
      id: 3, 
      term: '36 Months',
      data: 36
    },{
      id: 4, 
      term: '48 Months',
      data: 48
    },{
      id: 5, 
      term: '60 Months',
      data: 60
    }];

  }

  loadNewTabWithFinCalc() {
    console.log('test')
  }

  disableFields() {
    if (this.data.application.formDetails.selectedCountry && this.data.application.formDetails.selectedCurrency) {
      //False, do not disable the field
      return false
    }
    else {
      //true, disable the field
      return true
    }
  }
  
  calc_montlyPayments() {
    if (this.formValid) {
      // console.log(this.data.application.formDetails.loanTerm)
      let total;
      if (this.data.application.formDetails.deposit) {
        total = this.data.application.vehicleDetails.vehicleValue - this.data.application.formDetails.deposit
      }
      else {
        total = this.data.application.vehicleDetails.vehicleValue
      }
      let interestPercent = (this.data.application.formDetails.interestRate / 100) / 12

      let part1 = interestPercent * Math.pow((1 + interestPercent), this.data.application.formDetails.loanTerm)
      let part2 = Math.pow((1 + interestPercent), this.data.application.formDetails.loanTerm) - 1
      // console.log(part1)
      // console.log(part2)
      // console.log('sum',total * (part1 / part2))
      this.data.application.formDetails.estimatedMonthlyRepayments = Math.round(total * (part1 / part2))
      return Math.round(total * (part1 / part2))
    }
    else {
      return null
    }
  }

  validateForm() {
    if (this.data.application.formDetails.monthlySalaryAfterTax &&
        this.data.application.formDetails.estimatedTotalExpenses) {
          this.formError = false;
          return true;
        } else {
          this.formError = true;
          return false;
        }
  }

  applyNow() {
    this.loaderService.showLoader()
    if (this.validateForm()) {
      let expenses = this.data.application.formDetails.estimatedTotalExpenses ? this.data.application.formDetails.estimatedMonthlyRepayments : 0
      let deposit = this.data.application.formDetails.deposit ? this.data.application.formDetails.deposit : 0
      let formattedJson = {
        applicationId: this.data.application.applicationId,
        currencyId: this.data.application.formDetails.selectedCurrency.id,
        totalValue: this.data.application.vehicleDetails.vehicleValue,
        loanTerm: this.data.application.formDetails.loanTerm,
        monthlySalary: this.data.application.formDetails.monthlySalaryAfterTax,
        interestRate: this.data.application.formDetails.interestRate,
        estimatedExpenses: expenses,
        deposit: deposit
      }
      this.api.updateApplicationWithCalculatorValues(formattedJson).subscribe((resp) => {
        if (resp['errorCode'] == 0) {
          this.loaderService.hideLoader()
          this.onNextClick.emit();
        }
        else {
          this.toast.toastError('Unable to communicate', 'Something is wrong with connecting to the services, please try again!', 0);
          this.loaderService.hideLoader()
        }
      });
    } else {
      this.toast.toastError('Missing fields!', 'Please make sure to enter all the required fields.', 0);
      this.loaderService.hideLoader()
    }
      
  }

  getCurrencyCode() {
    if (this.data.application.formDetails.selectedCurrency) {
      return this.data.application.formDetails.selectedCurrency.data.currencyCode
    }
    else {
      return 'ZAR'
    }
  }

  getLocale() {
    if (this.data.application.formDetails.selectedCurrency) {
      return this.data.application.formDetails.selectedCurrency.locale
    }
    else {
      return 'en-ZA'
    }
  }

  formValid() {
    if (this.data.application.vehicleDetails.vehicleValue && this.data.application.formDetails.monthlySalaryAfterTax && this.data.application.formDetails.loanTerm
      && this.data.application.formDetails.interestRate && this.data.application.formDetails.selectedCountry && this.data.application.formDetails.selectedCurrency) {
      return true
    }
    else {
      return false
    }
  }

}

import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LogallService {

  constructor(private api: ApiService) { }

  public info(message: string, caller: string, json?: any) {
    if (environment.isDebugMode && environment.loginfo) {
      console.log(`${new Date()} - [${caller}]: ${message}`);
      if (json) {
        console.log(JSON.parse(json.toString()));
      }
    }
  }

  public warn(message: string, caller: string, json?: any) {
    if (environment.isDebugMode && environment.logwarning) {
      console.warn(`${new Date()} - [${caller}]: ${message}`)
      if (json) {
        console.warn(JSON.parse(json.toString()));
      }
    }
  }

  public error(message: string, caller: string, json?: any) {
    if (environment.isDebugMode && environment.logerror) {
      console.error(`${new Date()} - [${caller}]: ${message}`);
      if (json) {
        console.error(JSON.parse(json.tostring()));
      }
    }
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthenticationService } from 'src/app/services/auth/authentication.service';
import { GravatarService } from 'src/app/services/gravatar.service';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {
  @Input() isPortal: boolean = false;
  @Input() authService: AuthenticationService;

  @Output() outputMenuState = new EventEmitter<boolean>();

  menuState: boolean = true;
  username: string = '';

  smallIcon: string = 'assets/small-icon.png';
  largeIcon: string = 'assets/newbuild/Logo-white-png.png'
  currentIcon: string = ''

  constructor(public gravatar: GravatarService) { }

  ngOnInit(): void {
    if (this.menuState) this.currentIcon = this.smallIcon
    else this.currentIcon = this.largeIcon
  }

  menuStateChange() {
    this.menuState = !this.menuState;

    this.outputMenuState.emit(this.menuState);

    if (this.menuState) this.currentIcon = this.smallIcon
    else this.currentIcon = this.largeIcon
  }
  
}

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AuthenticationService } from 'src/app/services/auth/authentication.service';
import * as _ from 'lodash';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Message } from 'primeng/api';

@Component({
  selector: 'app-otp-password-reset',
  templateUrl: './otp-password-reset.component.html',
  styleUrls: ['./otp-password-reset.component.scss']
})
export class OtpPasswordResetComponent implements OnInit {

  @ViewChild('refOtpOne') refOtpOne: ElementRef;
  @ViewChild('refOtpTwo') refOtpTwo: ElementRef;
  @ViewChild('refOtpThree') refOtpThree: ElementRef;
  @ViewChild('refOtpFour') refOtpFour: ElementRef;
  @ViewChild('refOtpFive') refOtpFive: ElementRef;

  emailForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email])
  });

  otpCode: number[] = [];

  passwordForm = new FormGroup({
    password: new FormControl('', [Validators.required, Validators.minLength(8)]),
    confirmPassword: new FormControl('', [Validators.required, PasswordValidation.matchPassword])
  });

  baseLoadingIcon: string = '<i class="pi pi-spin pi-spinner"></i>';
  loading: boolean = false;

  resetToken: string;

  timerTime: number = 0;
  timeoutTimer: number = 300;
  time: string = '00:00';

  currentStep: number = 1;

  showMessage: Message[] = [];

  constructor(
    private auth: AuthenticationService,
    private router: Router
  ) { }

  ngOnInit(): void {  }

  setShowMessage(type: string, message: string) {
    if (!_.isEmpty(this.showMessage)) if (this.showMessage[0].summary == message) return;
    this.showMessage = [{ severity: type, summary: message }];
    setTimeout(() => {
      this.showMessage = [];
    }, 4000)
  }

  async beginPasswordReset() {
    if (this.emailForm.valid) {
      if (this.emailForm.value.email) {
        this.loading = true;
        let otpResponse = await this.auth.beginOTPReset(1, this.emailForm.value.email);
        if (otpResponse.errorCode == 0) {
          this.currentStep = 2;
          this.beginResendTimer();
        } else {
          this.setShowMessage('error', otpResponse.errorMessage);
        }
        this.loading = false;
      }
    }
  }

  async submitOTP() {
    this.loading = true;
    let otpString = '';
    for (let i = 0; i < this.otpCode.length; i++) otpString += this.otpCode[i]
    let otpResponse = await this.auth.checkOTP(otpString);
    if (otpResponse.errorCode == 0) {
      this.resetToken = otpResponse.result;
      this.currentStep = 3;
    } else if (otpResponse.errorCode == 2) {
      this.timerTime = 300;
      this.setShowMessage('error', otpResponse.errorMessage);
    } else {
      this.setShowMessage('error', otpResponse.errorMessage);
    }
    console.log(otpResponse);
    this.loading = false;
  }

  disableTab(event: KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault()
    }
  }

  otpInputUsed(event, otpIncrement) {
    if (event instanceof KeyboardEvent) {
      if (event.key == 'Backspace') {
        if (this.otpCode[otpIncrement - 1] > 0) return;
        switch (otpIncrement) {
          case 2: 
            this.refOtpOne.nativeElement.focus();
            break;
          case 3:
            this.refOtpTwo.nativeElement.focus();
            break;
          case 4:
            this.refOtpThree.nativeElement.focus();
            break;
          case 5:
            this.refOtpFour.nativeElement.focus();
            break;
        }
        return;
      }
    }
    if (!event) return;     
    let itemString = event.toString().trim();
    if (!itemString) return;
    if (itemString.length == 1) {
      switch (otpIncrement) {
        case 1: 
          this.refOtpTwo.nativeElement.focus();
          break;
        case 2:
          this.refOtpThree.nativeElement.focus();
          break;
        case 3:
          this.refOtpFour.nativeElement.focus();
          break;
        case 4:
          this.refOtpFive.nativeElement.focus();
          break;
        default:
          this.submitOTP();
          break;
      }
    } else if (itemString.length == 5) { // Handle Copy Paste of Codes from email / sms
      if (itemString) {
        for (let i = 0; i < 5; i++) this.otpCode[i] = itemString.charAt(i);
        this.refOtpFive.nativeElement.focus();
        this.submitOTP();
      }
    }
  }

  async resendOtp() {
    this.loading = true;
    let otpResponse = await this.auth.beginOTPReset(1, this.emailForm.value.email);
    if (otpResponse.errorCode == 0) {
      this.otpCode = [];
      this.setShowMessage('success', 'OTP Code has been resent.')
      this.beginResendTimer();
    } else {
      this.setShowMessage('error', otpResponse.errorMessage);
    }
    this.loading = false;
  }

  async changePassword() {
    this.loading = true;
    if (this.passwordForm.value.password == this.passwordForm.value.confirmPassword) {
      let passwordChangeResponse = await this.auth.changePasswordWithOTP(this.passwordForm.value.password);
      if (passwordChangeResponse.errorCode == 1) {
        this.loading = false;
        this.setShowMessage('error', passwordChangeResponse.errorMessage);
      } else {
        this.setShowMessage('success', 'Your password has been changed...redirecting');
        setTimeout(() => {
          this.router.navigate(['login']);
        }, 2500);
      }
    } else {
      this.loading = false;
      this.setShowMessage('error', 'Password\'s do not match.')
    }
  }

  beginResendTimer() {
    this.timerTime = 0;
    let timerInterval = setInterval(() => {
      if (this.timerTime == this.timeoutTimer) {
        clearInterval(timerInterval);
      } 
      var minutes = Math.floor((this.timerTime / 60));
      var seconds = (this.timerTime % 60);
      this.time = (minutes < 10 ? '0' : '') + minutes + ':' + (seconds < 10 ? '0' : '') +  seconds;

      this.timerTime++;
    }, 1000)
  }


}

export class PasswordValidation {

  static matchPassword(control: AbstractControl) {
    let formGroup = control.parent;
    if (formGroup) {
      let passwordControl = formGroup.get('password');
      let confirmPasswordControl = formGroup.get('confirmPassword');

      if (passwordControl && confirmPasswordControl) {
        let password = passwordControl.value;
        let confirmPassword = confirmPasswordControl.value;
        if (password != confirmPassword) {
          return { matchPassword: true };
        }
      }
    }
    return null;
  }

}
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';


@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent implements OnInit, AfterViewInit {


  constructor(
    meta: Meta,
    title: Title,
    private router: Router,
  ) {

    // title.setTitle('AVECS : Terms and Conditions');
    // meta.addTags([
    //   {
    //     name: 'author', content: 'Gary Simon'
    //   },
    //   {
    //     name: 'keywords', content: 'kws'
    //   },
    //   {
    //     name: 'description', content: 'aaaaaaaaaaaaaaaaaaaaaa.'
    //   },
    // ])

  }



  async ngOnInit() {

    window.scrollTo(0, 0);
  }


  async ngAfterViewInit() {

  }

}

import { Component, Input } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, NG_VALIDATORS, Validators } from '@angular/forms';
import * as _ from "lodash";

@Component({
  selector: 'aInput',
  template: `
  
  <label [for]="_inputId">{{ _label }}</label>
  <input pInputText [id]="_inputId" [type]="_type" [formControl]="_formControlProperty" [style]="_customStyling" />
  <span class="error-text">
      {{ getError(_formControlProperty, _label) }}
  </span>
  
  `,
  styleUrls: ['./input.component.scss']
})
export class InputComponent  {
  @Input('type') _type = '';
  @Input('id') _inputId = '';
  @Input('label') _label = '';
  @Input('formGroup') _formGroup: FormGroup 
  @Input('validators') _validators: Validators;
  @Input('formControlProperty') _formControlProperty: FormControl;
  @Input('customStyling') _customStyling = '';
  @Input('errorMessageOverride') _errorMessageOverride = '';

  constructor() { 
  }

  getError(formControlItem: FormControl | AbstractControl, friendlyInputName: string) {
    if (!_.isEmpty(this._errorMessageOverride)) return this._errorMessageOverride;
    if (formControlItem.touched && formControlItem.invalid) {
      if (formControlItem.errors) {
        // Handle if field is required
        if (formControlItem.errors.required) return `${friendlyInputName} is required.`;
        // Handle if field is too short
        if (formControlItem.errors.minlength) return `${friendlyInputName} is not long enough.`;
        // Handle if field is too long
        if (formControlItem.errors.maxlength) return `${friendlyInputName} is too long.`;
        // Handle if field is not an email
        if (formControlItem.errors.email) return `Email address not valid.`;
        // Hand if field does not match provided password
        if (formControlItem.errors.matchPassword) return `Your passwords do not match.`;
      } 
    }
  }

  

  

}

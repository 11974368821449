import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Rate } from './global.interface';

@Injectable()
export class CurrencyService {

  apikey : string = 'dd0abba47cca612fe3f3dd417b5da39e';

  constructor(private http: HttpClient) {}

  // getRates(base:string): Observable<Rate[]> {
  //   let apiUrl = 'http://data.fixer.io/api/latest?access_key=' + this.apikey; // dd0abba47cca612fe3f3dd417b5da39e';
  //   let RATES = [];
  //   apiUrl = `${apiUrl}?base=${base}`;

  //   return from(fetch(apiUrl)
  //     .then(res => res.json())
  //     .then(data => data.rates));
  // };
}
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { WindowRefService } from './../services/alert.service';

export let isDebugMode = environment.isDebugMode;
export let loginfo = environment.loginfo;
export let logwarning = environment.logwarning;
export let logerror = environment.logerror;

const noop = (): any => undefined;

export abstract class Logger {
  info: any;
  warning: any;
  error: any;
}

@Injectable()
export class LogBase implements Logger {
  info: any;
  warning: any;
  error: any;
}

@Injectable()
export class LogService implements LogBase {
  private winRef: Window;
  constructor(
    private windowRef: WindowRefService
  ) {
    this.winRef = windowRef.nativeWindow;
  }

  info(args: any) {

    if (isDebugMode) {

      const logFn: Function = (console)['info'] || console.log;
      logFn.apply(console, [args]);
    } else {
      return noop;
    }
  }

  warning(args: any) {
    if (isDebugMode) {
      const logFn: Function = (console)['warning'] || console.log;
      logFn.apply(console, [args]);
      //this.winRef.alert([args][0]);
    } else {
      return noop;
    }
  }

  error(args: any) {
    if (isDebugMode) {
      const logFn: Function = (console)['error'] || console.log;
      logFn.apply(console, [args]);
      // this.winRef.alert([args][0]);
    } else {
      return noop;
    }
  }
}


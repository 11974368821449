import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { SupplierCargo, SupplierVessels, SupplierVoyages } from './global.interface';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class SupplierService {

  private vessels: SupplierVessels[] = []
  private selectedVessel: SupplierVessels;
  private voyages: SupplierVoyages[] = [];
  private selectedVoyage: SupplierVoyages
  private voyageETA: string;
  private shippingInfo: SupplierCargo[] = [];

  constructor(private api: ApiService) {

  }

  public getSelectedVessel() : SupplierVessels {
    return this.selectedVessel;
  }

  public getSelectedVoyage() : SupplierVoyages {
    return this.selectedVoyage;
  }

  public getExistingVoyage() : SupplierVoyages[] {
    return this.voyages;
  }

  public getFiles() : SupplierCargo[] {
    return this.shippingInfo;
  }

  async getVessels() : Promise<SupplierVessels[]> {
    return new Promise<SupplierVessels[]>(async (resolve, reject) => {
      try {
        if (_.isEmpty(this.vessels)) {
          let result = await this.api.getSupplierVessels();
          if (result.errorCode == 0) {
            this.vessels = result.result;
          } else {
            this.vessels = undefined;
          }
        }
        resolve(this.vessels);
      } catch (error) {
        reject(error);
      }
    });
  } 

  async getVoyagesByVessel(vessel: SupplierVessels) : Promise<SupplierVoyages[]> {
    return new Promise<SupplierVoyages[]>(async (resolve, reject) => {
      try {
        let result = await this.api.getSupplierVoyages(vessel.id);
        if (result.errorCode == 0) {
          this.voyages = result.result
        } else this.voyages = undefined;
        resolve(this.voyages)
      } catch (error) {
        reject(error);
      }
    });
  }
}

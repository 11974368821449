import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class AuthManager implements CanActivate {
    legalEntityId: string;
    token: string;
    tokenExpires: string;
    accessRights: string;
    userName: string;

    constructor(private auth: AuthenticationService, private router: Router) { }


    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) : Observable<boolean|UrlTree>|Promise<boolean|UrlTree>|boolean|UrlTree {
        try { 
            return new Observable<boolean>(subscribe => {
                if (this.auth.isAuthValid()) {
                    subscribe.next(true);
                    return;
                } else {
                    this.auth.validateSession().then(result => {
                        if (result) {
                            subscribe.next(true);
                            return;
                        }
                    }).catch(error => {
                        console.log(error);
                        this.router.navigate(['login']);
                        subscribe.next(false);
                    })
                }
            });
        } catch (error) {
            console.log(error);
            // TODO: New Logger
            return new Observable<boolean>(subscribe => {
                this.router.navigate(['login']);
                subscribe.next(false)
            })
        }
    }
}
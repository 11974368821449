import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/auth/authentication.service';
import { PageLoads } from 'src/app/services/auth/enum/authentication.enum';
import * as _ from 'lodash';

@Component({
  selector: 'deep-link-handler',
  template: '<p>Loading...</p>'
})
export class DeepLinkHandlerComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private auth: AuthenticationService,
    private router: Router
  ) { }


// https://avecs.co.za/dpl/94d4a074b4524b9cb28aca2ae56d9c94/1/130927

  ngOnInit() {
    this.route.params.subscribe(async params => { 
      let OTP = params['OTP'];
      let loadType = params['loadtype'];
      let genericId = params['genericId'];

      let authResponse = await this.auth.authenticateOTP(OTP);
      console.log(authResponse)
      if (authResponse.token != null) {
        this.router.navigate([this.getRouteToLoad(loadType, genericId)]);
      } else {
        // Redirect to home page and show message
      }
    });
  }

  getRouteToLoad(pageToLoad: PageLoads, genericId: number) : string {
    try {
      if (pageToLoad == PageLoads.FILE) {
        let route = `file-viewer/consignee/${genericId}`;
        return route;
        
      }
    } catch (error) {
      // Redirect to home page and show message
      return null
    }
  }

}

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { DataService } from '../data.service';
import { LoaderService } from '../loader.service';
import { ToastService } from '../toast.service';

@Component({
  selector: 'app-e-app-type',
  templateUrl: './e-app-type.component.html',
  styleUrls: ['./e-app-type.component.scss']
})
export class EAppTypeComponent implements OnInit {

  @Output() onNextClick = new EventEmitter();
  @Output() onBackClick = new EventEmitter();
  
  selectedPersonal: boolean = false;
  selectedBusiness: boolean = false;

  selectedLoanType: any;

  loanOptions = [
    {
      icon: 'bi bi-building', 
      text: 'Business Vehicle Finance',
      val: 2
    },{
      icon: 'bi bi-person-lines-fill',
      text: 'Personal Vehicle Finance',
      val: 1
    }
  ];
  selectedLoanOptions;

  constructor(public data: DataService,
  private api:ApiService,
  private toast: ToastService,
  private loaderService: LoaderService) { }

  ngOnInit(): void {
    window.scroll(0,0);
    switch (this.data.application.loanType) {
      case 1: 
        this.selectedBusiness = false; 
        this.selectedPersonal = true; 
        break; //Personal
      case 2: 
        this.selectedBusiness = true; 
        this.selectedPersonal = false; 
        break;//Business
      default: 
        this.selectedBusiness = false; 
        this.selectedPersonal = false;
        break;
    }
  }

  selectedItem(loanType) {
    this.data.application.loanType = loanType
    switch (loanType) {
      case 1: 
        this.selectedBusiness = false; 
        this.selectedPersonal = true; 
        break; //Personal
      case 2: 
        this.selectedBusiness = true; 
        this.selectedPersonal = false; 
        break;//Business
      default: 
        this.selectedBusiness = false; 
        this.selectedPersonal = false;
        break;
    }
  }

  // selectToggleRadio(loanType) {
  //   console.log(loanType);
  //   this.data.application.loanType = loanType;
  //   console.log(this.data.application)
  // }

  back() {
    this.onBackClick.emit()
  }

  next() {
    this.loaderService.showLoader();
    // console.log(this.data.application)
    let formattedPayload = {
      applicationId: this.data.application.applicationId,
      loanType: this.data.application.loanType
    }
    this.api.updateApplicationWithLoanType(formattedPayload).subscribe((resp) => {
      if (resp['errorCode'] == 0) {
        this.loaderService.hideLoader();
        this.onNextClick.emit();
      }
      else {
        this.loaderService.hideLoader();
        this.toast.toastError('Unable to communicate', 'Something is wrong with connecting to the services, please try again!', 0);
      }
    })
  }

}

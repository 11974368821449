import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-financing-faqs',
  templateUrl: './financing-faqs.component.html',
  styleUrls: ['./financing-faqs.component.css']
})
export class FinancingFaqsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

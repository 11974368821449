import {
  Injectable
} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class DropdownService {

  titles = [{
    name: 'Mr',
    value: 'mr'
  }, {
    name: 'Mrs',
    value: 'mrs'
  }, {
    name: 'Miss',
    value: 'miss'
  }, {
    name: 'Ms',
    value: 'ms'
  }, {
    name: 'Mx',
    value: 'mx'
  }, {
    name: 'Dr',
    value: 'dr'
  }, {
    name: 'Prof',
    value: 'prof'
  }, {
    name: 'Rev',
    value: 'rev'
  }, {
    name: 'Sir',
    value: 'sir'
  }, {
    name: 'Lady',
    value: 'lady'
  }, {
    name: 'Lord',
    value: 'lord'
  }, {
    name: 'Judge',
    value: 'judge'
  }];

  residentialStatus = [{
    name: 'Rent',
    value: 'rent'
  },{
    name: 'Own (mortgaged)',
    value: 'own (mortgaged)'
  },{
    name: 'Own (not mortgaged)',
    value: 'own (not mortgaged)'
  },{
    name: 'Living with Parents',
    value: 'living with parents'
  },{
    name: 'Employer Provided',
    value: 'employer provided'
  },{
    name: 'Other (Please Specify)',
    value: 'other'
  }];
  maritalStatus = [{
    name: 'Single',
    value: 'single'
  },{
    name: 'Married',
    value: 'married'
  },{
    name: 'Divorced',
    value: 'divorced'
  },{
    name: 'Widowed',
    value: 'widowed'
  },{
    name: 'Seperated',
    value: 'seperated'
  },{
    name: 'Other - specify',
    value: 'other'
  }];
  employmentStatus = [{
    name: 'Permanent',
    value: 'Permanent'
  },{
    name: 'Private Practice',
    value: 'Private Practice'
  },{
    name: 'Self Employment scheme Name',
    value: 'Self Employment scheme Name'
  },{
    name: 'Contract',
    value: 'Contract'
  },{
    name: 'Part Time',
    value: 'Part Time'
  },{
    name: 'Pensioner',
    value: 'Pensioner'
  },{
    name: 'Student',
    value: 'Student'
  },{
    name: 'Unemployed',
    value: 'Unemployed'
  }];
  accountType = [{
    name: 'Cheque/Current',
    value: 'Cheque/Current'
  },{
    name: 'Savings',
    value: 'Savings'
  },{
    name: 'Other (specify)',
    value: 'other'
  }];
  education = [{
    name: 'Secondary School',
    value: 'secondary school'
  },{
    name: 'University Degree',
    value: 'university degree'
  },{
    name: 'Honours',
    value: 'honours'
  },{
    name: 'Doctorate',
    value: 'doctorate'
  }]

  constructor() {

  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-otp-passreset',
  templateUrl: './otp-passreset.component.html',
  styleUrls: ['./otp-passreset.component.scss']
})
export class OtpPassresetComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DataService } from '../data.service';
import { LoaderService } from '../loader.service';

@Component({
  selector: 'app-h-loan-terms',
  templateUrl: './h-loan-terms.component.html',
  styleUrls: ['./h-loan-terms.component.scss']
})
export class HLoanTermsComponent implements OnInit {

  @Output() onNextClick = new EventEmitter();
  @Output() onBackClick = new EventEmitter();

  business: boolean = false;
  personal: boolean = false;


  constructor(public data:DataService,
        private loaderService: LoaderService) { }

  ngOnInit(): void {
    window.scroll(0,0);
    if (this.data.application.loanType == 2) {
      this.business = true;
    } else if (this.data.application.loanType == 1) {
      this.personal = true;
    } else {
      this.personal = true;
    }
  }

  back() {
    this.loaderService.showLoader();
    this.onBackClick.emit();
    this.loaderService.hideLoader();
  }

  next() {
    this.loaderService.showLoader();
    this.onNextClick.emit();
    this.loaderService.hideLoader();
  }

}

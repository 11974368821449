import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
declare var google: any;

import { Router } from '@angular/router'

@Component({
    selector: 'app-contact-us',
    templateUrl: './contact-us.component.html',
    styleUrls: ['./contact-us.component.css'],

})
export class ContactComponent implements OnInit {

    msgs: any[];


    options: any;
    overlays: any[];

    dialogVisible: boolean;
    markerTitle: string;
    selectedPosition: any;
    infoWindow: any;
    draggable: boolean;


    name: string;
    email: string;
    telephone: string;
    message: string;

    disabled: boolean = true;
    sent: boolean = false;

    constructor(private api: ApiService, private router: Router) { }

    ngOnInit() {

        window.open("https://avecsgroup.com/contact/", "_blank");
        this.router.navigate(['landing-page-client'])

        this.options = {
            center: { lat: -29.825097, lng: 30.956032 },
            zoom: 16
        };

        this.overlays = [
            new google.maps.Marker({
                position: { lat: -29.825097, lng: 30.956032 },
                title: 'AVECS (Pty) Ltd <br/> 9/11 University Road <br/> Unit 3, Imperial Office Park<br/>Westville,3631<br/>Durban, South Africa'
            })
        ];
        //this.initOverlays();
        this.infoWindow = new google.maps.InfoWindow()

        window.scrollTo(0, 0);

    }

    CheckIfReadyToSend() {
        if (this.name && this.email && this.message) {
            this.disabled = false;
        } else {
            this.disabled = true;
        }
    }

    async sendMessage() {
        var toEmail = 'info@avecs.co.za';
        var fromEmail = 'noreply@avecs.co.za';
        var body = 'Contact :' + this.name + '<br/>' + 'Phone Number :' + this.telephone + '<br/>' + 'Email Address :' + this.email + '<br/> Message : ' + this.message;
        var subject = 'Website Enquiry';
        var fileId = -1;
        var contactId = -1;

        this.api.sendWebsiteMessage(toEmail, fromEmail, body, subject, fileId, contactId).subscribe(result => {
            if (result['errorCode'] == 0) {
                this.sent = JSON.parse(result['result']);

                this.msgs = [];
                this.msgs.push({ severity: 'info', summary: 'Enquiry Received', detail: 'Thank You We will be in contact with you shortly.' });

                this.telephone = null;
                this.email = null;
                this.name = null;
                this.message = null;
                this.disabled = true;
            } else {
                // TODO: Handle Error
            }
        })

        

    }

    handleMapClick(event) {
        this.dialogVisible = true;
        this.selectedPosition = event.latLng;
    }

    handleOverlayClick(event) {
        let isMarker = event.overlay.getTitle != undefined;

        if (isMarker) {
            let title = event.overlay.getTitle();
            this.infoWindow.setContent('<div>' + title + '</div>');
            this.infoWindow.open(event.map, event.overlay);
            event.map.setCenter(event.overlay.getPosition());
        }
    }

    addMarker() {
        this.overlays.push(new google.maps.Marker({ position: { lat: this.selectedPosition.lat(), lng: this.selectedPosition.lng() }, title: this.markerTitle, draggable: this.draggable }));
        this.markerTitle = null;
        this.dialogVisible = false;
    }

    handleDragEnd(event) {
        //  this.messageService.add({severity:'info', summary:'Marker Dragged', detail: event.overlay.getTitle()});
    }

    initOverlays() {
        if (!this.overlays || !this.overlays.length) {
            this.overlays = [
                new google.maps.Marker({ position: { lat: -29.836452, lng: 30.916953 }, title: 'AVECS (Pty) Ltd' })

            ];
        }
    }

    zoomIn(map) {
        map.setZoom(map.getZoom() + 1);
    }

    zoomOut(map) {
        map.setZoom(map.getZoom() - 1);
    }

    clear() {
        this.overlays = [];
    }

}


import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-emptydash',
  templateUrl: './emptydash.component.html',
  styleUrls: ['./emptydash.component.css']
})
export class EmptydashComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { DataService } from '../data.service';
import { LoaderService } from '../loader.service';
import { ToastService } from '../toast.service';

@Component({
  selector: 'app-j-terms',
  templateUrl: './j-terms.component.html',
  styleUrls: ['./j-terms.component.scss']
})
export class JTermsComponent implements OnInit {

  _termsAccept: boolean = false;

  @Output() onNextClick = new EventEmitter();

  selectedValues: boolean = false;

  constructor(private api: ApiService,
  private data: DataService,
  private toast: ToastService,
  private loaderService: LoaderService) { }

  ngOnInit(): void {
    window.scroll(0,0);
  }

  termsAccept() {
    this._termsAccept = !this._termsAccept;
  }

  next() {
    this.loaderService.showLoader();
    this.api.updateApplicationWithTerms(this.data.application.applicationId).subscribe((resp) => {
      if (resp['errorCode'] == 0) {
        this.loaderService.hideLoader();
        this.onNextClick.emit();
      }
      else {
        this.loaderService.hideLoader();
        this.toast.toastError('Unable to communicate', 'Something is wrong with connecting to the services, please try again!', 0);
      }
    })
  }

}

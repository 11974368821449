import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';

@Component({
    selector: 'app-footer',
    templateUrl: './app.footer.component.html',
    styleUrls: ['./app.footer.component.css'],
})
export class AppFooterComponent implements OnInit {

    visibleSidebar2: boolean = false;
    showFooter: boolean = false;
    year: string;
    constructor() { }


    async ngOnInit() {

        this.year = moment().format('YYYY');
        
        var token = sessionStorage.getItem('token');

        if (token != null) {
            this.showFooter = false;
            console.log('Footer Logged in', token);
        }
        else {
            this.showFooter = true;
            console.log('Footer Not Logged in', token);
        }
    }

}

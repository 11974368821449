import { Injectable } from '@angular/core';
import { ConfigService } from '../config.service';
import * as _ from 'lodash';
import { WebApiService } from '../web.api.service';
import { LogBase } from '../logger.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { HttpResponse, Permission, UserInformation, LegalEntityInfo } from '../global.interface';
import { GravatarService } from '../gravatar.service';
import { Groups } from './enum/authentication.enum';
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private isAuthenticated: boolean = false;
  private client: UserInformation | undefined = undefined;

  private editPermissions: Permission[] = [];
  private viewPermissions: Permission[] = [];
  
  private agent: boolean = false;
  private consignee: boolean = false;
  private transporter: boolean = false;
  private borderagent: boolean = false;
  private supplier: boolean = false;
  private webuser: boolean = false;
  private legalEntityInfo: LegalEntityInfo;
  private legalFullName: string | undefined;

  // OTP Reset Stuff
  otpResetId: number;
  otpResetToken: string;


  constructor(
    private api: WebApiService,
    private config: ConfigService,
    private log: LogBase,
    private location: Location,
    private router: Router,
    private gravatar: GravatarService
  ) { }

  public isAuthValid() : boolean { return this.isAuthenticated }

  public isAuthInvalid() : boolean { return !this.isAuthenticated }

  public getUser() : UserInformation | undefined { return this.client };

  private setUser(userInfo: UserInformation) { this.client = userInfo }

  public getLegalName() : string {
    if (this.legalEntityInfo) {
      if (!this.legalFullName) this.legalFullName = `${this.legalEntityInfo.name} ${this.legalEntityInfo.middleNames || this.legalEntityInfo.middleNames + ' '} ${this.legalEntityInfo.lastName}`;
      return this.legalFullName
    }
    return null;
  } 

  public getEmail() : string {
    if (this.legalEntityInfo) return this.legalEntityInfo.email;
    return undefined;
  }

  public getLegalEntityInfo() : LegalEntityInfo {
    if (this.legalEntityInfo) return this.legalEntityInfo
    return undefined;
  }

  private invalidateAuth() : void { 
    this.isAuthenticated = false;
    this.client = undefined
  }

  private validateAuth() : void {
    this.isAuthenticated = true;
  }

  private clearUser() : void { 
    sessionStorage.clear();  
  }

  private setupSessionStorage(userInfo: UserInformation) : void {
    sessionStorage.setItem('userId', userInfo.userId.toString());
    sessionStorage.setItem('userName', userInfo.userName);
    sessionStorage.setItem('token', userInfo.token);
    sessionStorage.setItem('device', userInfo.device);
    sessionStorage.setItem('tokenExpires', userInfo.tokenExpires.toString());
  } 

  private clearSessionStorage () {
    // I am a little pedantic so added remove items just in case
    sessionStorage.removeItem('userId')
    sessionStorage.removeItem('userName');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('device');
    sessionStorage.removeItem('tokenExpires');
    // Clearing the session as a last resort
    sessionStorage.clear();
  }
  
  private convertUser(httpUserResponse: HttpResponse) : UserInformation { 
    return {
      userId: httpUserResponse.userId,
      device: httpUserResponse.device,
      token: httpUserResponse.token,
      userName: httpUserResponse.userName, 
      tokenExpires: httpUserResponse.tokenExpires,
      editPermission: httpUserResponse.editPermission,
      viewPermission: httpUserResponse.viewPermission
    }
  } 

  private async setLegalEntityInfo() : Promise<boolean> {
    try {
      let url = this.config.getServerUrl() + `api/portal/legalentity`;
      let response = await this.api.apiGet(url);
      if (response.errorCode == 0) {
        this.legalEntityInfo = response.result
        return new Promise<boolean>((resolve, reject) => {
          resolve(true);
        });
      } else {
        this.log.error(`Failed to get Legal Entity Info for UserId: ${this.getUser().userId}`);
      }
    } catch (error) {
      return new Promise<boolean>((resolve, reject) => {
        reject(null)
      });
    }
  }

  public async validateSession() : Promise<UserInformation | boolean> {
    try {
      if (window.sessionStorage.getItem('token')) {
        let url = this.config.getServerUrl() + `api/portal/session/validate`;
        let response = await this.api.apiGet(url);
        if (response.errorCode == -2) {
          this.invalidateAuth();
          this.clearUser();
          this.clearPermissions();
          return new Promise<boolean>((resolve, reject) => {
            resolve(false);
          });
        }
        this.validateAuth();
        this.setLegalEntityInfo();
        let user = this.convertUser(response);
        this.gravatar.setGravatar(user.userName);
        this.setupSessionStorage(user);
        this.setEditPermissions(user.editPermission);
        this.setViewPermissions(user.viewPermission);
        console.log(user);
        this.setUser(user);
        console.log(this.getUser())
        return new Promise<UserInformation>((resolve, reject) =>  {
          resolve(user);
        });
      } else {
        this.invalidateAuth();
        return new Promise<boolean>((resolve, reject) => {
          resolve(false);
        });
      }
      
    } catch (error) {
      this.log.error(error);
      return new Promise<boolean>((resolve, reject) => {
        reject(false);
      })
    }
  }

  public async resetPassword(email: string) {
    try {
      let url = this.config.getServerUrl() + `api/portal/password/resetrequest?email=${email}`;
      return await this.api.post(url, null);
    } catch (error) {
      this.log.error(error);
      return error;
    }
  }

  // Authenticate using a One Time Pin (logins from Dockutrack or login from sms)
  public async authenticateOTP(otp: string) : Promise<UserInformation> {
    return new Promise<UserInformation>(async (resolve, reject) => {
      try {
        let url = this.config.getServerUrl() + `api/auth/authenticateOTP?otp=${otp}`;
        let response = await this.api.apiPost(url, null);
        if (response.errorCode == 0) {
          let user = this.convertUser(response)
          this.setupSessionStorage(user);
          this.setUser(user);
          this.validateAuth();
          this.setLegalEntityInfo();
          this.gravatar.setGravatar(user.userName);
          this.setEditPermissions(user.editPermission);
          this.setViewPermissions(user.viewPermission);
          resolve(user);
        } else {
          reject(response);
        }
      } catch (error) {
        this.log.error(error);
        reject(error);
      }
    });
  }

  // Authenticate using standard Username and Password
  public async authenticate(username: string, password: string) : Promise<UserInformation> {
    return new Promise(async (resolve, reject) => {
      try {
        let url = `${this.config.getServerUrl()}api/auth/authenticateweb?username=${username}&password=${password}`;
        let response = await this.api.apiPost(url, null);
        if (response.errorCode == 0) {
          let user = this.convertUser(response);
          this.setupSessionStorage(user);
          this.setUser(user);
          this.validateAuth();
          this.setLegalEntityInfo();
          this.gravatar.setGravatar(user.userName);
          this.setEditPermissions(user.editPermission);
          this.setViewPermissions(user.viewPermission);
          resolve(user);
        } else {
          reject(response)
        }
      } catch (error) {
        this.log.error(error);
        reject(error);
      }
    });
  }

  async beginOTPReset(resetTypeId: number, sentTo: string) : Promise<HttpResponse> {
    try {
      const url = this.config.getServerUrl() + `api/portal/password/reset/otp/init?resetTypeId=${resetTypeId}&sentTo=${sentTo}&existingResetId=${this.otpResetId}`;
      let response = await this.api.post(url, null);
      this.otpResetId = response.result;
      return response;
    } catch (error) {
      this.log.error(error);
    }
  }

  async checkOTP(otp: string) : Promise<HttpResponse> {
    try {
      const url = this.config.getServerUrl() + `api/portal/password/reset/otp/check?existingResetId=${this.otpResetId}&otp=${otp}`;
      let response = await this.api.post(url, null);
      this.otpResetToken = response.result;
      return response
    } catch (error) {
      this.log.error(error);
    }
  }

  async changePasswordWithOTP(password: string) : Promise<HttpResponse> {
    try {
      const url = this.config.getServerUrl() + `api/portal/password/reset/otp?resetToken=${this.otpResetToken}&existingResetId=${this.otpResetId}&newPassword=${password}`;
      let response = await this.api.post(url, null);
      return response;
    } catch (error) {
      this.log.error(error);
    }
  }

  async changePassword(oldPassword: string, newPassword: string) {
    try {
      const url = this.config.getServerUrl() + `api/portal/password/reset/existing?oldPassword=${oldPassword}&newPassword=${newPassword}`;
      return await this.api.post(url, null);
    } catch (error) {
      this.log.error(error);
    }
  }

  // Logout and break session storage
  public async logout() : Promise<boolean> {
    return new Promise((resolve, reject) => {
      try {
        this.invalidateAuth();
        this.clearUser();
        this.clearPermissions();
        this.clearSessionStorage();
        this.router.navigate(['']);
        resolve(true);
      } catch (error) {
        this.log.error(error);
        reject(error);
      }
    });
  }

  public checkForPortal() : boolean {
    try {
      let route = this.location.path();
      if (!route) return false;
      route = route.length == 0 ? route : route.substr(1);
      if (route.split('/')[0] == 'file-viewer') route = `${route.split('/')[0]}/:type/:fileid`;
      if (route.split('/')[1] == 'sysadmin') route = `${route.split('/')[0]}/${route.split('/')[1]}/:OTP`;
      if (route.split('/')[0] == 'upload-docs' && route.split('/')[1]) route = `${route.split('/')[0]}/:fileid`;
      let foundRoute = _.find(this.router.config, ['path', route]);
      if ("data" in foundRoute) {
        if ("portal" in foundRoute.data) {
          return foundRoute.data.portal;
        }
      }
    } catch (error) {
      this.log.error(error);
    }
    return false;
  }

  public checkForFileViewer() : boolean {
    try {
      let route = this.location.path();
      route = route.length == 0 ? route : route.substr(1);
      if (!route) return false;
      if (route.split('/')[0] == 'file-viewer') return true;
    } catch (error) {
      this.log.error(error);
    }
    return false;
  }

  public checkForUploadDoc() : boolean {
    try {
      let route = this.location.path();
      route = route.length == 0 ? route : route.substr(1);
      if (!route) return false;
      if (route.split('/')[0] == 'upload-docs' && route.split('/')[1]) return true;
    } catch (error) {
      this.log.error(error);
    }
    return false;
  }

  public getFileIdForUpload() : string {
    try {
      let route = this.location.path();
      route = route.length == 0 ? route : route.substr(1);
      if (!route) return '';
      if (route.split('/')[0] == 'upload-docs' && route.split('/')[1]) return route.split('/')[1];
    } catch (error) {
      this.log.error(error);
    }
    return ''
  }

  public getEditPermissions() : Permission[] { return this.editPermissions }

  public getViewPermissions() : Permission[] { return this.viewPermissions }

  public setViewPermissions(permsReceived: Permission[]) : void {
    try {
      this.viewPermissions = permsReceived;
    } catch (error) {
      this.log.error(error);
    }
  }

  public setEditPermissions(permsReceived: Permission[]) : void {
    try {
      this.editPermissions = permsReceived;

      this.getEditPermissions().forEach(permission => {
        if (permission.id == Groups.AGENCY) this.agent = true;
        if (permission.id == Groups.CONSIGNEE) this.consignee = true;
        if (permission.id == Groups.BORDER_AGENT) this.borderagent = true;
        if (permission.id == Groups.TRANSPORTER) this.transporter = true;
        if (permission.id == Groups.SUPPLIER) this.supplier = true;
      });      
    } catch (error) {
      this.log.error(error);
    }
  }

  private clearPermissions() : void {
    try {
      this.editPermissions = [];
      this.viewPermissions = [];
    } catch (error) {
      this.log.error(error);
    }
  }

  public getRouteLocation() : string {
    if (this.borderagent) return 'border';
    if (this.agent || this.consignee) return 'client';
    if (this.supplier) return 'supplier';
    if (this.transporter) return 'transporter';
    return 'dashboard';
  }

  public isAgent() : boolean {
    if (this.config.getDebugMode()) return true // Override for development mode
    return this.agent;
  }

  public isConsignee() : boolean {
    if (this.config.getDebugMode()) return true // Override for development mode
    return this.consignee;
  }

  public isTransporter() : boolean {
    if (this.config.getDebugMode()) return true // Override for development mode
    return this.transporter;
  }

  public isSupplier() : boolean {
    if (this.config.getDebugMode()) return true // Override for development mode
    return this.supplier;
  }
  
  public isBorderAgent() : boolean {
    if (this.config.getDebugMode()) return true // Override for development mode
    return this.borderagent;
  }

  public isWebUser() : boolean {
    if (this.config.getDebugMode()) return true // Override for development mode
    return this.webuser
  }

  public canRead(permissionId: Groups) : boolean {
    try {
      if (this.config.getDebugMode()) return true // Override for development mode
      if(this.getViewPermissions()) {
        this.getViewPermissions().forEach(permission => {
          if (permission.id == permissionId) return true;
        });
        return false;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

  public canEdit(permissionId: Groups) : boolean {
    try {
      if (this.config.getDebugMode()) return true // Override for development mode
      if (this.getEditPermissions()) {
        this.getEditPermissions().forEach(permission => {
          if (permission.id == permissionId) return true;
        });
        return false;
      }
    } catch (error) {
      this.log.error(error);
    }
  }

}

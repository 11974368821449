import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";


@Injectable({
    providedIn: 'root'
})
export class DownloadService {

    constructor(private http: HttpClient) {}

    download(url: string): Observable<Blob> {
        return this.http.get(url, {
            responseType: 'blob'
        });
    }

    downloadPersonalAppForm() {
        this.download('/assets/finance/forms/Personal Loan Application Form.pdf')
            .subscribe(blob => {
                const a = document.createElement('a');
                const objectUrl = URL.createObjectURL(blob);
                a.href = objectUrl;
                a.download = 'Personal Loan Application Form.pdf';
                a.click();
                URL.revokeObjectURL(objectUrl);
            })
    }

    downloadBusinessAppForm() {
        this.download('/assets/finance/forms/Business Loan Application Form.pdf')
            .subscribe(blob => {
                const a = document.createElement('a');
                const objectUrl = URL.createObjectURL(blob);
                a.href = objectUrl;
                a.download = "Business Loan Application Form.pdf";
                a.click();
                URL.revokeObjectURL(objectUrl);
            })
    }
}
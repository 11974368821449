import { AfterViewChecked, Component, Input, OnInit } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { CorrespondanceType } from 'src/app/services/global.enum';
import { Email, FileCorrespondance, Sms } from 'src/app/services/global.interface';
import * as _ from 'lodash';

enum EmailQueueStatus {
  SAVED = 'Saved to DB', 
  SENTEX = 'Sent to Mandrill',
  SENT = 'Sent',
  FAILED = 'Failed',
  INCOMPLETE = 'Incomplete',
  PPCOMPLETE = 'Post Process Complete',
  PPFAILED = 'Post Process Failed'
}

@Component({
  selector: 'dt-correspondance',
  templateUrl: './correspondance.component.html',
  styleUrls: ['./correspondance.component.scss']
})
export class CorrespondanceComponent implements OnInit, AfterViewChecked {
  @Input('fileCorrespondance') value: FileCorrespondance = null;

  firstIndex: number = 0;
  smsFirstIndex: number = 0;
  rows: number = 10;
  smsRows: number = 10;
  page: number = 0;
  smsPage: number = 0;
  selectedEmails: Email;
  selectedSms: Sms;
  emailDropdownOptions: SelectItem[] = [];
  smsDropdownOptions: SelectItem[] = [];

  viewEmail: boolean = false;

  constructor() { }

  ngOnInit(): void { }

  async ngAfterViewChecked() {
    if (this.value && _.isEmpty(this.emailDropdownOptions)) {
      this.emailDropdownOptions = [];
      for (let i = 0; i < (this.value.emails.length/this.rows); i++) {
        console.log(i);
        this.emailDropdownOptions.push({
          label: `${(i+1)}`,
          value: i
        });   
      }
    }
    if (this.value && _.isEmpty(this.smsDropdownOptions)) {
      this.smsDropdownOptions = [];
      for (let i = 0; i < (this.value.smss.length/this.rows); i++) {
        console.log(i);
        this.smsDropdownOptions.push({
          label: (i+1).toString(),
          value: i
        });
      }
    }
  }

  public get correspondanceType(): typeof CorrespondanceType { return CorrespondanceType }

  isAnEmail(object: any) : object is Email {
    return 'id' in object && 'fromEmail' in object && 'fromName' in object;
  }

  handleStatus(statusText: string) : string {
    switch(statusText) {
      case EmailQueueStatus.FAILED:
      case EmailQueueStatus.INCOMPLETE: 
        return statusText
      default: 
        return EmailQueueStatus.SENT
    }
  }

  viewMessage(messageType: CorrespondanceType, email: Email, sms: Sms) {
    switch (messageType) {
      case CorrespondanceType.EMAIL:
        this.selectedEmails = email;
        this.viewEmail = true;
        break;
    }

  }

  emailPrevious() {
    if ((this.firstIndex - this.rows) < 0) return;
    this.firstIndex = this.firstIndex - this.rows;
    this.page--;
  }

  emailNext() {
    if ((this.firstIndex + this.rows) > this.value.emails.length) return;
    this.firstIndex = this.firstIndex + this.rows;
    this.page++;
  }

  emailIsFirst() : boolean {
    return this.value ? this.firstIndex === 0 : true
  }

  emailIsLast() : boolean {
    return this.value ? (this.firstIndex + this.rows) > this.value.emails.length : true
  }

  emailTableDropdownOptions(event: number) {
    this.firstIndex = event * this.rows
    this.page = event
  }

  emailGetPageCount() : string {
    if (this.value != undefined) {
      return `${this.firstIndex == 0 ? 1 : this.firstIndex}
    - ${this.value.emails.length < (this.rows * (this.page + 1) ) ? this.value.emails.length : this.rows * (this.page + 1)} 
    of ${Math.ceil(this.value.emails.length / this.rows)} page${Math.ceil(this.value.emails.length / this.rows) > 1 ? 's' : ''}`;
    }
  }  

  smsPrevious() {
    if ((this.smsFirstIndex - this.smsRows) < 0) return;
    this.smsFirstIndex = this.smsFirstIndex - this.smsRows;
    this.smsPage--;
  }

  smsNext() {
    if ((this.smsFirstIndex + this.smsRows) > this.value.smss.length) return;
    this.smsFirstIndex = this.smsFirstIndex + this.smsRows;
    this.smsPage++;
  }

  smsIsFirst() : boolean {
    return this.value.smss ? this.smsFirstIndex === 0 : true
  }

  smsIsLast() : boolean {
    return this.value.smss ? (this.smsFirstIndex + this.smsRows) > this.value.smss.length : true
  }

  smsTableDropdownOptions(event: number) {
    this.smsFirstIndex = event * this.smsRows
    this.smsPage = event
  }

  smsGetPageCount() : string {
    if (this.value.smss != undefined) {
      return `${this.smsFirstIndex == 0 ? 1 : this.smsFirstIndex}
    - ${this.value.smss.length < (this.smsRows * (this.smsPage + 1) ) ? this.value.smss.length : this.smsRows * (this.smsPage + 1)} 
    of ${Math.ceil(this.value.smss.length / this.smsRows)} page${Math.ceil(this.value.smss.length / this.smsRows) > 1 ? 's' : ''}`;
    }
  }  

}

import { Injectable } from "@angular/core";
import { MessageService } from "primeng/api";

@Injectable({
    providedIn: 'root'
})
export class ToastService {

    constructor(private messageService: MessageService) { }

    toastError(message: string, detailedMessage: string, genericId?: number) {
        this.messageService.add({
          severity: 'error',
          summary: message,
          detail: detailedMessage
        });
      }
    
      toastSuccess(message: string, detailedMessage: string, genericId?: number) {
        this.messageService.add({
          severity: 'success',
          summary: message,
          detail: detailedMessage
        });
      }
    
      toastInfo(message: string, detailedMessage: string, genericId?: number) {
        this.messageService.add({
          severity: 'info',
          summary: message,
          detail: detailedMessage
        });
      }
}


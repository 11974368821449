import { AfterViewChecked, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Message, SelectItem } from 'primeng/api';
import { AuthenticationService } from 'src/app/services/auth/authentication.service';
import { FileService } from 'src/app/services/file.service';
import { Document, DocumentCategory, ViewDocument } from 'src/app/services/global.interface';
import * as _ from 'lodash';

@Component({
  selector: 'dt-documents-table',
  templateUrl: './documents-table.component.html',
  styleUrls: ['./documents-table.component.scss']
})
export class DocumentsTableComponent implements OnInit, AfterViewChecked {

  @Input('tableValue') value: Document[];
  @Input('fileId') fileId: number;
  @Output() onUpload: EventEmitter<boolean> = new EventEmitter();

  selected: Document[];

  baseLoadingIcon: string = '<i class="pi pi-spin pi-spinner"></i>';

  files: any[] = [];

  firstIndex: number = 0;
  rows: number = 10;
  page: number = 0;
  dropdownOptions: SelectItem[] = [];
  viewDocument: boolean = false;
  downloadLoading: boolean = false;
  uploadLoading: boolean = false;
  viewUploadDocument: boolean = false;
  documentCategories: DocumentCategory[] = [];
  documentIcon: string = 'pi-cloud-upload';
  selectedDocument: ViewDocument = {
    documentId: 0,
    url: '',
    fileName: '',
    dataUrl: null
  };  
  uploadMessage: Message[] = [];
  uploadButtonStatus: boolean = true;

  constructor(private fileService: FileService, private auth: AuthenticationService) { }

  async ngOnInit() {
    this.documentCategories = await this.fileService.loadDocumentsPathAndCategoryId();
  }

  async ngAfterViewChecked() {
    if (this.value && _.isEmpty(this.dropdownOptions)) {
      this.dropdownOptions = [];
      for (let i = 0; i < (this.value.length/this.rows); i++) {
        console.log(i);
        this.dropdownOptions.push({
          label: (i+1).toString(),
          value: i
        });   
      }
    }
  }

  async viewFile(document: Document) {
    if (!document) return;
    this.viewDocument = true;
    this.selectedDocument = await this.fileService.getDocument(document);
    if (!this.selectedDocument) return; // Show Error
    console.log(this.selectedDocument)
  }

  async downloadFileIcon(documentToDownload: Document) {
    if (!document) return;
    let foundDocument = await this.fileService.getDocument(documentToDownload);
    if (!foundDocument) return; // Show Error
    let link = document.createElement('a');
    link.href = foundDocument.url;
    link.download = foundDocument.fileName;
    link.target = '_blank';
    link.click();
  }

  downloadFile(documentToDownload: ViewDocument) {
    this.downloadLoading = true;
    let link = document.createElement('a');
    link.href = documentToDownload.url;
    link.download = documentToDownload.fileName;
    link.target = '_blank';
    link.click();
    this.downloadLoading = false;
  }

  onFileUpload($event: FileList[]) {
    // Display pop up
    this.files = [];
    this.viewUploadDocument = true;
    this.files = $event;
    for (let i = 0; i < this.files.length; i++) {
      this.files[i].icon = 'pi pi-cloud-upload'
      this.files[i].documentStatus = 1;
    }
  }

  setUploadMessageWithTimer(errorType: string, errorMessage: string, dismissMask: boolean = false) {
    this.uploadButtonStatus = false;
    this.uploadMessage = [{
      severity: errorType, 
      summary: errorMessage
    }];
    setTimeout(() => {
      this.uploadMessage = [];
      if (dismissMask) {
        this.viewUploadDocument = false;
        this.files = [];
      }
      this.uploadButtonStatus = true;
    }, 3000)
  }

  // async uploadFiles() {
  //   this.uploadLoading = true;
  //   let error = false;
  //   let uploadError = false;
  //   for (let i = 0; i < this.files.length; i++) {
  //     console.log(this.files[i].category);
      
  //     if (!this.files[i].category) {
  //       error = true;
  //       this.files[i].documentStatus = 2;
  //       this.setUploadMessageWithTimer('error', 'Please make sure to select a document category for each document');
  //       break;
  //     }
  //     if (this.files[i].documentStatus == 3) continue

  //     this.files[i].icon = 'pi pi-spin pi-spinner'
  //     let response = await this.fileService.uploadDocuments(this.taskId, 'Upload Documents', this.auth.getUser().userId, this.files[i]);
  //     if (response.errorCode == 0) {
  //       this.files[i].icon = 'pi pi-check-circle';
  //       this.files[i].documentStatus = 3;
  //     } else {
  //       this.files[i].icon = 'pi pi-exclamation-circle'
  //       uploadError = true;
  //     }
  //   }
  //   this.uploadLoading = false;
    
  //   if (uploadError) this.setUploadMessageWithTimer('error', 'One or more of your documents failed to upload') 
  //   if (!error && !uploadError) {
  //     this.setUploadMessageWithTimer('success', 'Your documents have uploaded successfully...', true);
  //     this.onUpload.emit(true);
  //   }
    
  // }

  previous() {
    if ((this.firstIndex - this.rows) < 0) return;
    this.firstIndex = this.firstIndex - this.rows;
    this.page--;
  }

  next() {
    if ((this.firstIndex + this.rows) > this.value.length) return;
    this.firstIndex = this.firstIndex + this.rows;
    this.page++;
  }

  isFirst() : boolean {
    return this.value ? this.firstIndex === 0 : true
  }

  isLast() : boolean {
    return this.value ? (this.firstIndex + this.rows) > this.value.length : true
  }

  tableDropdownOptions(event: number) {
    this.firstIndex = event * this.rows
    this.page = event
  }

  getPageCount() : string {
    if (this.value != undefined) {
      return `${this.firstIndex == 0 ? 1 : this.firstIndex}
    - ${this.value.length < (this.rows * (this.page + 1) ) ? this.value.length : this.rows * (this.page + 1)} 
    of ${Math.ceil(this.value.length / this.rows)} page${Math.ceil(this.value.length / this.rows) > 1 ? '\'s' : ''}`;
    }
  }  
}

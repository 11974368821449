import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { DataService } from '../data.service';
import { LoaderService } from '../loader.service';
import { ToastService } from '../toast.service';

@Component({
  selector: 'app-g-employer-details',
  templateUrl: './g-employer-details.component.html',
  styleUrls: ['./g-employer-details.component.scss']
})
export class GEmployerDetailsComponent implements OnInit {

  @Output() onNextClick = new EventEmitter();
  @Output() onBackClick = new EventEmitter();

  isScheme: boolean = true;
  activeSchemes;
  selectedActiveScheme;

  constructor(private api: ApiService,
  public data: DataService,
  private toast: ToastService,
  private loaderService: LoaderService) { }

  ngOnInit(): void {
    window.scroll(0,0);
    this.api.getActiveSchemesList().subscribe(res => {
      if (res['errorCode'] == 0) {
        this.activeSchemes = res['result'];
      } else {
        // Handle error
      }
    })
  }

  back() {
    this.onBackClick.emit();
  }

  next() {
    this.loaderService.showLoader();
    // If scheme selected update schemeid to the selected id
    if (this.isScheme) {
      this.data.application.companyDetails.schemeId = this.selectedActiveScheme.id
    }
    // Else leave SchemeId equal to 0 and companyName and companyAddress is used
    else {
      this.data.application.companyDetails.schemeId = 0
    }
    let formattedJson = {
      applicationId: this.data.application.applicationId,
      SchemeId: this.data.application.companyDetails.schemeId,
      Scheme: this.data.application.companyDetails.companyName,
      address:this.data.application.companyDetails.companyAddress
    }
    this.api.updateApplicationWithScheme(formattedJson).subscribe((resp) => {
      if (resp['errorCode'] == 0) {
        this.loaderService.hideLoader();
        this.onNextClick.emit();
      }
      else {
        this.loaderService.hideLoader();
        this.toast.toastError('Unable to communicate', 'Something is wrong with connecting to the services, please try again!', 0);
      }
    })
  }

  formValid() {
    if (this.isScheme) {
      if (this.selectedActiveScheme) {
        return true
      }
      else {
        return false
      }
    }
    else {
      if (this.data.application.companyDetails.companyName && this.data.application.companyDetails.companyAddress) {
        return true
      }
      else {
        return false
      }
    }
  }

}

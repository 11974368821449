import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { AuthManager } from './services/auth/auth.manager';

// Website Pages
import { LoginComponent } from './components/login/login.component';
import { ContactComponent } from './components/contact-us/contact-us.component';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';

// WORKFLOW COMPONENTS
import { LandingPageClientComponent } from './components/portal/landing-page-client/landing-page-client.component';
import { LandingPageSuppliersComponent } from './components/portal/landing-page-suppliers/landing-page-suppliers.component'
import { LandingPageDriversComponent } from './components/portal/landing-page-drivers/landing-page-drivers.component'

import { ResetPasswordComponent } from './components/portal/reset-password/reset-password.component';

import { HomeComponent } from './components/home/home.component';
import { HelpCentreComponent } from './components/portal/help-centre/help-centre.component';
import { FinanceQualifyComponent } from './components/finance-qualify/finance-qualify.component';
import { FinancingFaqsComponent } from './components/financing-faqs/financing-faqs.component';
import { CompleteFinanceComponent } from './components/complete-finance/complete-finance.component';
import { EmptydashComponent } from './components/emptydash/emptydash.component';
import { DtFileViewerComponent } from './components/dt-file-viewer/dt-file-viewer.component';
import { LandingPageBorderagentComponent } from './components/portal/landing-page-borderagent/landing-page-borderagent.component';
import { OtpPassresetComponent } from './components/otp-passreset/otp-passreset.component';
import { Groups, PageLoads } from './services/auth/enum/authentication.enum';
import { DeepLinkHandlerComponent } from './components/deep-link-handler/deep-link-handler.component';

export const routes: Routes = [
    { 
        path: '', 
        component: HomeComponent, 
        data: { portal: false } 
    },
    { 
        path: 'terms-and-conditions', 
        component: TermsAndConditionsComponent 
    },
    { 
        path: 'privacy-policy',
        component: PrivacyPolicyComponent 
    },
    { 
        path: 'contact-us', 
        component: ContactComponent },    
    { 
        path: 'do-i-qualify', 
        component: FinanceQualifyComponent 
    },
    { 
        path: 'login', 
        component: LoginComponent, 
        pathMatch: 'full' 
    },
    { 
        path: 'login/portal/:title', 
        component: LoginComponent, 
        pathMatch: 'full' 
    },
    { 
        path: 'setup/:OTP', 
        component: ResetPasswordComponent, 
        pathMatch: 'full' 
    },
    { 
        path: 'client', 
        component: LandingPageClientComponent, 
        canActivate: [AuthManager], 
        data: { portal: true } 
    },
    { 
        path: 'supplier', 
        component: LandingPageSuppliersComponent, 
        canActivate: [AuthManager], 
        data: { portal: true } 
    },
    { 
        path: 'transporter', 
        component: LandingPageDriversComponent, 
        canActivate: [AuthManager], 
        data: { roles: [Groups.TRANSPORTER], portal: true } 
    },
    { 
        path: 'border', 
        component: LandingPageBorderagentComponent, 
        canActivate: [AuthManager], 
        data: { roles: [Groups.BORDER_AGENT], portal: true } 
    },
    { 
        path: 'dashboard', 
        component: EmptydashComponent, 
        canActivate: [AuthManager], 
        data: { portal: true } 
    },
    { 
        path: 'file-viewer/:type/:fileid', 
        component: DtFileViewerComponent, 
        canActivate: [AuthManager], 
        data: { portal: true, deepLinkId: PageLoads.FILE  }
    },
    { 
        path: 'login/sysadmin/:OTP', 
        component: LoginComponent, 
        pathMatch: 'full' 
    },
    {
        path: 'dpl/:OTP/:loadtype/:genericId',
        component: DeepLinkHandlerComponent, 
        pathMatch: 'full'
    },
    { 
        path: 'reset-password', 
        component: ResetPasswordComponent, 
        pathMatch: 'full', 
        data: { portal: true } 
    },
    { 
        path: 'password-reset', 
        component: OtpPassresetComponent 
    },
    { 
        path: 'help-centre', 
        component: HelpCentreComponent, 
        canActivate: [AuthManager] 
    },
    { 
        path: 'home', 
        component: HomeComponent 
    },
    { 
        path: 'finance', 
        component: CompleteFinanceComponent 
    }
];

export const AppRoutes = RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' });

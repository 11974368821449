import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { fromEvent } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { DataService } from '../data.service';
import { DownloadService } from '../download.service';
import { LoaderService } from '../loader.service';
import { ToastService } from '../toast.service';

@Component({
  selector: 'app-i-upload-documents',
  templateUrl: './i-upload-documents.component.html',
  styleUrls: ['./i-upload-documents.component.scss']
})
export class IUploadDocumentsComponent implements OnInit {

  @Output() onNextClick = new EventEmitter();
  @Output() onBackClick = new EventEmitter();

  uploadInProg: boolean = false;

  defaultChooseIcon = "pi pi-upload";
  defaultLoadingIcon = "pi pi-spin pi-spinner";
  defaultSuccessIcon = "pi pi-check";
  defaultFailIcon = "pi pi-times";

  idChooseStatus: number = 0;
  idChooseIcon: string = this.defaultChooseIcon;

  pinChooseStatus: number = 0;
  pinChooseIcon: string = this.defaultChooseIcon;

  poiChooseStatus: number = 0;
  poiChooseIcon: string = this.defaultChooseIcon;

  invChooseStatus: number = 0;
  invChooseIcon: string = this.defaultChooseIcon;

  bsChooseStatus: number = 0;
  bsChooseIcon: string = this.defaultChooseIcon;

  appChooseStatus: number = 0;
  appChooseIcon: string = this.defaultChooseIcon;

  constructor(
  private api: ApiService,
  public data: DataService,
  private download: DownloadService,
  private toast: ToastService,
  private loaderService: LoaderService) { }

  ngOnInit(): void {
    window.scroll(0,0);
    this.data.documentUploadStatus.forEach(doc => {
      if (doc.type == 'id') {
        this.idChooseStatus = doc.status;
        if (doc.status == 2) {
          this.idChooseIcon = this.defaultSuccessIcon;
        } else if (doc.status == 3) {
          this.idChooseIcon = this.defaultFailIcon;
        }
      } else if (doc.type == 'pin') {
        this.pinChooseStatus = doc.status;
        if (doc.status == 2) {
          this.pinChooseIcon = this.defaultSuccessIcon;
        } else if (doc.status == 3) {
          this.pinChooseIcon = this.defaultFailIcon;
        }
      } else if (doc.type == 'poi') {
        this.poiChooseStatus = doc.status;
        if (doc.status == 2) {
          this.poiChooseIcon = this.defaultSuccessIcon;
        } else if (doc.status == 3) {
          this.poiChooseIcon = this.defaultFailIcon;
        }
      } else if (doc.type == 'inv') {
        this.invChooseStatus = doc.status;
        if (doc.status == 2) {
          this.invChooseIcon = this.defaultSuccessIcon;
        } else if (doc.status == 3) {
          this.invChooseIcon = this.defaultFailIcon;
        }
      } else if (doc.type == 'bs') {
        this.bsChooseStatus = doc.status;
        if (doc.status == 2) {
          this.bsChooseIcon = this.defaultSuccessIcon;
        } else if (doc.status == 3) {
          this.bsChooseIcon = this.defaultFailIcon;
        }
      } else if (doc.type == 'app') {
        this.appChooseStatus = doc.status;
        if (doc.status == 2) {
          this.appChooseIcon = this.defaultSuccessIcon;
        } else if (doc.status == 3) {
          this.appChooseIcon = this.defaultFailIcon;
        }
      }
    });
  }

  click(uploader: number) {
    
  }

  back() {
    this.onBackClick.emit();
  }

  next() {
    this.loaderService.showLoader();
    this.api.updateApplicationDocumentsUpload(this.data.application.applicationId).subscribe((resp) => {
      if (resp['errorCode'] == 0) {
        this.loaderService.hideLoader();
        this.onNextClick.emit();
        
      }
      else {
        this.loaderService.hideLoader();
        this.toast.toastError('Failed to progress', 'There seems to be a connection issue between you and the server, please try again later!')
      }
    })
  }

  onBeforeFileUpload(event) {
    
    // console.error("onBeforeFileUpload" + event);
  }

  uploadHandlerForFiles(event, stanbicFileName, fileTypeId) {

    this.iconControl(fileTypeId, 1, this.defaultLoadingIcon);
    this.uploadInProg = true;
    
    var blob = event.files[0];
    // console.log(blob);
    var base64data;
    var reader = new FileReader();

    let local = this;
    let api: ApiService = this.api;
    let data: DataService = this.data;
    let toast: ToastService = this.toast;
    reader.readAsDataURL(blob); 
    reader.onloadend = async function() {
       base64data = reader.result;                
       console.log(base64data);
       var payload = {
        SystemId: 14,
        ApplicationId: data.application.applicationId, //Application Id
        // ApplicationId: 1,
        FileName: stanbicFileName,
        DocType: fileTypeId,
        FilePath: '',
        ImageBase64: base64data
      }
       api.uploadFinanceDocument(payload).subscribe(res => {
        if (res['errorCode'] == 0) {
          local.iconControl(fileTypeId, 2, local.defaultSuccessIcon);
          local.uploadInProg = false;
          toast.toastSuccess('Upload Document', 'Your document has been successfully uploaded', 0);
          return true;
        } else {
          local.iconControl(fileTypeId, 3, local.defaultFailIcon);
          local.uploadInProg = true;
          toast.toastError('Upload Document', 'Your document did not get uploaded, please try again!', 0)
          return false;
        }
       })

      
    }    
    // setTimeout(()=>{ 
    //   console.log('test')
      
    //   // If successful
      
    //   // If Api unsuccessful
      

    // }, 4000)
   }

   iconControl(fileTypeId: number, status: number, displayIcon) {
    // Status IDs: 0 Not Uploaded, 1 uploading, 2 success, 3 Fail
    switch (fileTypeId) {
      case 1: 
        this.idChooseStatus = status;
        this.data.documentUploadStatus[0].status = status;
        this.idChooseIcon = displayIcon;
        break;
      case 2: 
        this.pinChooseStatus = status;
        this.data.documentUploadStatus[1].status = status;
        this.pinChooseIcon = displayIcon;
        break;
      case 3:
        this.poiChooseStatus = status;
        this.data.documentUploadStatus[2].status = status;
        this.poiChooseIcon = displayIcon;
        break;
      case 4:
        this.invChooseStatus = status;
        this.data.documentUploadStatus[3].status = status;
        this.invChooseIcon = displayIcon;
        break;
      case 5: 
        this.bsChooseStatus = status;
        this.data.documentUploadStatus[4].status = status;
        this.bsChooseIcon = displayIcon;
        break;
      case 6:
        this.appChooseStatus = status;
        this.data.documentUploadStatus[5].status = status;
        this.appChooseIcon = displayIcon;
    }
   }

   loanApplicationFormDownload() {
     if (this.data.application.loanType == 1) { // Personal
      this.download.downloadPersonalAppForm();
     } else if (this.data.application.loanType == 2) { // Business
      this.download.downloadBusinessAppForm();
     } else {
      // console.log('test')
      // window.location.href = '/assets/finance/forms/Business Loan Application Form.pdf';
     }
   }

   fileUploadFinished(event, docId) {
    //  console.log(event)

    // console.error("fileUploadFinished" + event)
   }

   onUploadError(event) {
    console.error("onUploadError" + event)
   }

}

import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
@Pipe({ name: 'custPortalDate' })
export class DatePipe implements PipeTransform {
  transform(value: unknown, ...args: unknown[]): string {
    if (moment.utc(value).isBefore(moment.utc("1000-01-01"))) return "" 
    return moment(value).format('DD.MM.YYYY HH:MM').toString()
  }

}

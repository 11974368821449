import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

export let financeUrl = environment.financeUrl;
export let isDebugMode = environment.isDebugMode;
export let nodeUrl = environment.nodeUrl;
export let url = environment.url;


@Injectable()
export class ConfigService {
  
  nodeUrl = nodeUrl;
  url = url;
  financeUrl = financeUrl;
  
  constructor() { }
  
  getServerUrl() {
    return this.url;
  }

  getNodeUrl() {
    return this.nodeUrl;
  }

  getFinanceurl() {
    return this.financeUrl;
  }

  getDebugMode() {
    return isDebugMode;
  }
}

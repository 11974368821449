import { Injectable } from '@angular/core';
import xlsx from 'json-as-xlsx'

export interface ExcelSheet {
  sheet: string;
  columns: {
    label: string;
    value: string | ((row: any) => any);
  }[];
  content: any[];
}

@Injectable({
  providedIn: 'root'
})
export class ExcelService {

  xlsxData: ExcelSheet[];
  xlsxSettings = {
    fileName: "",
    extraLength: 3,
    writeOptions: {}
  };

  constructor() { }

  generateSingleSheetExcel(fileName: string, sheet: ExcelSheet) : Buffer | undefined  {
    this.xlsxSettings.fileName = fileName;

    var generated = xlsx([sheet], this.xlsxSettings);
    this.xlsxData = null;
    return generated;
  }

  generateMultiSheetExcel(fileName: string, sheets: ExcelSheet[]) : Buffer | undefined  {
    this.xlsxSettings.fileName = fileName;
    
    var generated = xlsx(sheets, this.xlsxSettings);
    this.xlsxData = null;
    return generated;
  }

}

import { DecimalPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';

interface Country {
  name: string,
  code: string,
  taxPercentage: number,
}

interface Currency {
  name: string, 
  prefix: string
}

interface Expenses {
  bondRepayments: number, 
  rentalPayments: number, 
  household: number, 
  utilities: number,
  phoneAndData: number,
  healthMedicalGym: number, 
  tranport: number, 
  education: number, 
  creditCardLoanRepayments: number, 
  savings: number, 
  entertainment: number, 
  other: number,
  total: number
}

@Component({
  selector: 'app-finance-qualify',
  templateUrl: './finance-qualify.component.html',
  styleUrls: ['./finance-qualify.component.css']
})
export class FinanceQualifyComponent implements OnInit {

  basicinput: any;

  netIncome: number;
  utilities: number;
  transport: number;
  homeLoanRent: number;
  foodHousehold: number;
  other: number;
  totalAllowed: string;

  messageStatus: boolean = false;
  error: boolean = false;
  message: any = '';

  countries: Country[] = [
    {
      name: "Select Country",
      code: "",
      taxPercentage: 40
    },{
      name: "South Africa",
      code: "ZA",
      taxPercentage: 35
    },{
      name: "Uganda",
      code: "UG",
      taxPercentage: 40
    },{
      name: "Kenya",
      code: "KE",
      taxPercentage: 45
    }
  ];
  selectedCountry: Country = {
    name: "Select Country",
    code: "",
    taxPercentage: 40
  };
  
  currencies: Currency[] = [{
    name: "Select Currency",
    prefix: ""
  },{
    name: "ZAR - South Africa", 
    prefix: "R"
  },{
    name: "KSH - Kenyan Shilling",
    prefix: "Ksh"
  },{
    name: "UGX - Ugandan shilling",
    prefix: "USh"
  }];
  selectedCurrency: Currency = {
    name: "Select Currency",
    prefix: ""
  }

  expenses: Expenses;
  

  constructor() { }

  ngOnInit(): void {
    
  }

  checkValidation() {
    if (!this.netIncome) {
      return false;
    }
    if (!this.utilities) {
      return false;
    }
    if (!this.transport) {
      return false;
    }
    if (!this.homeLoanRent) {
      return false;
    }
    if (!this.foodHousehold) {
      return false;
    }
    if (!this.other) {
      return false
    }
    return true;
  }

  runCalc() {
    if (this.checkValidation()) {
      const spaces = price => String(price)
        .replace(
          /(?!^)(?=(?:\d{3})+$)/g,
          ' '
        );
      let message;
      var totalExpenses: number = 0;
      var sum = 0;
      for ( var el in this.expenses) {
        if ( this.expenses.hasOwnProperty( el )) {
          sum += parseFloat( this.expenses[el] );
        }
      }
      console.error("Sum")
      console.log(sum)
      var remaining: number = 0;
      var maxMonthlyPayment: number = 0;
      var totalAllowed: number;

      totalExpenses = +this.homeLoanRent + +this.utilities + +this.foodHousehold + +this.transport + +this.other;
      console.log("Total expenses: ", totalExpenses);
      remaining = this.netIncome - totalExpenses;
      console.log("Total remaining: ", remaining);
      if (remaining > (this.netIncome * 0.4)) {
        maxMonthlyPayment = remaining * 0.75;
      } else {
        maxMonthlyPayment = this.netIncome * 0.4;
      }
      console.log("Max Monthly Repayment: ", maxMonthlyPayment);
      totalAllowed = maxMonthlyPayment * 60;
      console.log("Max Car Amount: ", this.totalAllowed);
      if (totalAllowed < 0) {
        totalAllowed = 0;
      } 
      this.totalAllowed = spaces(totalAllowed);
      message = "Max Monthly repayments amount: " + maxMonthlyPayment + " | Max Car Amount: " + this.totalAllowed;
      console.log("Message: ", message);
      this.messageStatus = false;
    } else {
      this.messageStatus = true;
      this.error = true;
      this.message = "Please make sure that all inputs have been filled in. Blank inputs are not allowed."
    }
  }

}

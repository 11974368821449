import { Component, EventEmitter, OnInit, Output} from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { DataService } from '../data.service';
import { LoaderService } from '../loader.service';
import { MLoginDropdownComponent } from '../m-login-dropdown/m-login-dropdown.component';
import { ToastService } from '../toast.service';

@Component({
  selector: 'app-l-password-page',
  templateUrl: './l-password-page.component.html',
  styleUrls: ['./l-password-page.component.scss']
})
export class LPasswordPageComponent implements OnInit {

  @Output() onNextClick = new EventEmitter()

  accountExists: boolean = false;

  pageHeading: string = "Enter in a password to create an account";
  password: string;
  confirmPassword: string;

  constructor(private api: ApiService, 
              public data: DataService, 
              private toast: ToastService, 
              private loginDropdown: MLoginDropdownComponent,
              private loaderService: LoaderService) { }

  ngOnInit(): void {
    window.scroll(0,0);
    if (this.data.accountExists) {
      this.accountExists = true;
    } else {
      this.accountExists = false;
    }
  }

  register() {
    this.loaderService.showLoader();
    if (this.password == this.confirmPassword) {
      if (this.password.length > 8) {
        this.api.financeRegisterUser(this.data.application.personDetails.emailAddress, this.password).subscribe(result => {
          console.log(result);
          this.loaderService.hideLoader();
          this.onNextClick.emit();
        });
      } else {
        this.loaderService.hideLoader();
        this.toast.toastError('Unable to register', 'Please make sure to make your password longer than 8 characters, and try again!', 0);
      }
    } else {
      this.loaderService.hideLoader();
      this.toast.toastError('Unable to register', 'Your passwords do not match, please check the passwords you have entered and try again!', 0);
    }
  }

  login() {
    this.loaderService.showLoader();
    this.api.financeLoginUser(this.data.application.personDetails.emailAddress, this.password).subscribe(result => {
      console.log(result);
      if (result["errorCode"] == 0) {
        this.toast.toastSuccess('Login Successful', 'You are now logged in, retrieving your applications!');
        window.sessionStorage.setItem('finance-token', result['result'].sessionToken);
        window.sessionStorage.setItem('finance-token-expiry', result['result'].expiryDate);

        if (result['result']['firstName']) {
          this.loginDropdown.loginText = result['result']['firstName'] + ' ' + result['result']['lastName'];
        } else {
          this.loginDropdown.loginText = result['result']['emailAddress'];
        }

        this.loaderService.hideLoader();

        // Get in progress applications
        this.loginDropdown.getInProgApps();

        this.onNextClick.emit();

      } else {
        this.loaderService.hideLoader();
        this.toast.toastError('Failed to login', result['errorMessage']);
      }
    })
  }

}

import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class LoaderService {

    loaderShow: boolean = false;
  
    constructor() { }
  
    getLoaderState() : boolean {
      return this.loaderShow
    }
  
    showLoader() {
      this.loaderShow = true;
    }
  
    hideLoader() {
      this.loaderShow = false;
    }
}
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { LogBase } from '../../../services/logger.service';
import { MessageService } from 'primeng/api';
import { TableColumns, TransporterCarrierDrivers, TransporterFile, LoadColumns, AssignedLoads, AcceptedLoads, AcceptDeclineLoadId, CollectionAssignedLoads} from '../../../services/global.interface';
import { ExcelService, ExcelSheet } from 'src/app/services/excel.service';
import { trigger,state,style,transition,animate } from '@angular/animations';
import * as _ from 'lodash';
import * as moment from 'moment';

enum LoadAcceptDecline {
  Accepted = 4,
  Declined = 5,
}


@Component({
  selector: 'app-landing-page-drivers.component',
  templateUrl: './landing-page-drivers.component.html',
  styleUrls: ['./landing-page-drivers.component.scss'],
  encapsulation: ViewEncapsulation.None,
   animations: [
      trigger('rowExpansionTrigger', [
          state('void', style({
              transform: 'translateX(-10%)',
              opacity: 0
          })),
          state('active', style({
              transform: 'translateX(0)',
              opacity: 1
          })),
          transition('* <=> *', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
      ])
  ]
})
export class LandingPageDriversComponent implements OnInit {

  incomingFiles: TransporterFile[];
  selectedIncomingFiles: TransporterFile[];
  incomingCargoPayload: any;
  loadEstimatedDeparture: any;
  collectionLoadETD: any;
  loadAssignDate: any;
  collectionAssignDate: any;

  acceptedFiles: TransporterFile[] = [];
  drivers: TransporterCarrierDrivers[] = [];

  loadsAssigned: AssignedLoads[];
  collectionLoadsAssigned: CollectionAssignedLoads[];
  collectionBulkLoadsAssigned: CollectionAssignedLoads[];
  acceptedLoads: AcceptedLoads[];
  acceptedCollectionLoads: CollectionAssignedLoads[];
  completedCollectionLoads: CollectionAssignedLoads[];
  completedLoads: AcceptedLoads[];


  columns: TableColumns[] = [
    { field: "dbnNumber", header: "DBN Number", width: null },
    { field: "foreignReference", header: "Reference", width: null },
    { field: "description", header: "Description", width: null },
    { field: "length", header: "Length", width: null },
    { field: "width", header: "Width", width: null },
    { field: "height", header: "Height", width: null },
    { field: "weight", header: "Weight", width: null },
    { field: "assignedRouteLegs", header: "Route", width: null },
  ];

  headers: LoadColumns[] = [
    { field: "loadName", header: "Load Name", width: null },
    { field: "estimatedDeparture", header: "Estimated Departure", width: null },
    { field: "destination", header: "Destination", width: null },
    { field: "cargoNumber", header: "Number of Cars", width: null },
    {field: "cargoValue", header: "Cargo Value", width: null},
    { field: "assignDate", header: "Date Assigned", width: null },
    { field: "driverName", header: "Drivers Name", width: null }
  ]

  collectionHeaders: LoadColumns[] = [
    { field: "name", header: "Load Name", width: null },
    { field: "estimatedDeparture", header: "Estimated Departure", width: null },
    { field: "pickupLocation", header: "Pickup Location", width: null },
    { field: "dropoffLocation", header: "Dropoff Location", width: null },
    { field: "numberOfCargoOnLoad", header: "Number of Cars", width: null },
    {field: "cargoValue", header: "Cargo Value", width: null},
    { field: "loadAssignDate", header: "Date Assigned", width: null },
    { field: "driverName", header: "Drivers Name", width: null }
  ]
  declineReason: string;

  selectedActiveTabIndex: number = 0;
  mainActiveIndex: number = 0;

  // Boolean Values
  loading: boolean = false;;
  driverCreate: boolean = false;
  showDialog: boolean = false;
  showAccept: boolean = false;
  showLoadAccept: boolean = false;
  showLoadDialog: boolean = false;
  showCollectionLoadAccept: boolean = false;
  showCollectionLoadDialog: boolean = false;

  waitingResults: boolean = false;
  submitRequest: boolean = false;
 

  // Form Variables
  firstName: string;
  lastName: string;
  emailAddress: string;
  password: string;
  confirmpassword: string;

  confirmDialogHeader: string;

  transporterAccept: number = 1;
  transporterDecline: number = 2;
  cargoLoadAssignmentId: number;
  selectedLoadRow: AcceptDeclineLoadId[];

  constructor(
    private api: ApiService,
    private log: LogBase,
    private messageService: MessageService,
    private excelService: ExcelService
  ) { }

  ngOnInit(): void {
    this.getAssignedLoads();
    this.getAcceptedLoad();
  }

  //Get Assigned Loads
  async getAssignedLoads() {
    try {
      let collection = await this.api.getCollectionLoadsAssignedToTransport();
      let bulkCollection = await this.api.getCollectionBulkLoadsAssignedToTransport()
      let delivery = await this.api.getDeliveryLoadsAssignedToTransport();
      if (delivery) {
        this.loadsAssigned = delivery;
      }
      if (collection) {
        this.collectionLoadsAssigned = collection;
      }
      if (bulkCollection) {
        this.collectionBulkLoadsAssigned = bulkCollection;
      }
    } catch (exception) {
      
    }
  }

  async acknowledgeBulkCollectionLoadassignment() {
    let bulkLoadId = []
    for (var load of this.selectedLoadRow) {
      console.log(load.dispatchLoadPlanId);
      bulkLoadId.push(load.dispatchLoadPlanId);
    }
    console.log(bulkLoadId);
    let retval = await this.api.acknowledgeBulkAssignment(bulkLoadId);
    if (retval) {
      this.toastMessage('success', 'Load dismissed', '');
    }
    await this.getAssignedLoads();
  }

  async AcceptDeclineLoad(status: number) {
    let LoadRowId = [];
    for (var item of this.selectedLoadRow) {
      LoadRowId.push(item.cargoLoadAssignmentId);
    }
    
    if (status == 4) {
      let retval = await this.api.TransporterAcceptDeclineLoad(LoadRowId, LoadAcceptDecline.Accepted);
      if (retval) {
        this.toastMessage('success', 'Load accepted successfully', '');
      }
      if (!retval) {
        this.toastMessage('error', 'Failed to accept load', '');
      }
    }
    else {
      let retval = await this.api.TransporterAcceptDeclineLoad(LoadRowId, LoadAcceptDecline.Declined);
       if (retval) {
        this.toastMessage('success', 'Load declined successfully', '');
      }
      if (!retval) {
        this.toastMessage('error', 'Failed to declined load', '');
      }
    }
    await this.getAssignedLoads()
    await this.getAcceptedLoad();
    this.showLoadAccept = false;
    this.showLoadDialog = false;
  }
 
  async AcceptDeclineCollectionLoad(status: number) {
    let LoadRowId = [];
    for (var item of this.selectedLoadRow) {
      console.log('Selected Load Row',this.selectedLoadRow);
      LoadRowId.push(item.cargoLoadAssignmentId);
      console.log('LoadRowId',LoadRowId);
    }
    if (status == 4) {
      let retval = await this.api.TransporterAcceptDeclineCollectionLoad(LoadRowId, LoadAcceptDecline.Accepted);
      if (retval) {
        this.toastMessage('success', 'Load accepted successfully', '');
      }
      if (!retval) {
        this.toastMessage('error', 'Failed to accept load', '');
      }
    }
    else {
      let retval = await this.api.TransporterAcceptDeclineCollectionLoad(LoadRowId, LoadAcceptDecline.Declined);
       if (retval) {
        this.toastMessage('success', 'Load declined successfully', '');
      }
      if (!retval) {
        this.toastMessage('error', 'Failed to declined load', '');
      }
    }
    await this.getAssignedLoads()
    await this.getAcceptedLoad();
    this.showLoadAccept = false;
    this.showLoadDialog = false;
    this.selectedLoadRow = null;
  }

  async getAcceptedLoad() {
    let acceptedCollection = await this.api.getCollectionAcceptedLoads();
    let acceptedDelivery = await this.api.getDeliveryAcceptedLoads()
    if (acceptedDelivery) {
      this.acceptedLoads = acceptedDelivery;
    }
    if (acceptedCollection) {
      this.acceptedCollectionLoads = acceptedCollection;
    }
    this.getLoadsAcceptedAndCompleted(); 
    this.getCollectionLoadsAcceptedAndCompleted();
  }

  async getLoadsAcceptedAndCompleted() {
    let retval = await this.api.getAcceptedLoadsCompleted();
    if (retval) {
      this.completedLoads = retval;
    }
  }

  async getCollectionLoadsAcceptedAndCompleted() {
    let retval = await this.api.getAcceptedCollectionLoadsCompleted();
    if (retval) {
      this.completedCollectionLoads = retval;
    }
  }

  confirm(): void {
    this.confirmDialogHeader = 'Accept Delivery'
    this.showAccept = true;
    this.showDialog = true;
  }

  confirmLoad(): void {
    this.confirmDialogHeader = 'Accept Load'
    this.showLoadAccept = true;
    this.showLoadDialog = true;
  }

  confirmCollectionLoad(): void {
    this.confirmDialogHeader = 'Accept Load'
    this.showCollectionLoadAccept = true;
    this.showCollectionLoadDialog = true;
  }

  decline(): void {
    this.confirmDialogHeader = 'Decline Delivery';
    this.showDialog = true;
    this.showAccept = false;
  }

  declineLoad(): void {
    this.confirmDialogHeader = 'Decline Load';
    this.showLoadDialog = true;
    this.showLoadAccept = false;
  }

  declineCollectionLoad(): void{
    this.confirmDialogHeader = 'Decline Collection Load';
    this.showCollectionLoadDialog = true;
    this.showCollectionLoadAccept = false;
  }

  sortTransporterTable(event: any): void {
    let data = event.data;
    var sortByField = event.field;
    var order = event.order;
    //asc
    if (order == 1) {
      if (sortByField == 'selectedFiles' && this.selectedIncomingFiles && this.selectedIncomingFiles.length != 0) {
        this.sortBySelectedFiles(order);
      }
      else {
        this.incomingFiles = _.orderBy(data, [sortByField], ['asc']);
      }
    }
    //desc
    else if (order == -1) {
      if (sortByField == 'selectedFiles' && this.selectedIncomingFiles && this.selectedIncomingFiles.length != 0) {
        this.sortBySelectedFiles(order);
      }
      else {
        this.incomingFiles = _.orderBy(data, [sortByField], ['desc']);
      }
    }
  }
  sortBySelectedFiles(order: number): void {
    for (var item of this.selectedIncomingFiles) {
      let selectedItem = this.incomingFiles.find(x => x.fileId == item.fileId)
      if (selectedItem) {
        let index = this.incomingFiles.indexOf(selectedItem);
        if (order == 1) {
          this.incomingFiles.splice(index, 1);
          this.incomingFiles.unshift(item)
        }
        else if (order == -1) {
          this.incomingFiles.splice(index, 1);
          this.incomingFiles.push(item)
        }
      }
    }
  }

  downloadExcelFile() {
    let xlsxData: ExcelSheet[] = [{
      sheet: "Incoming Cargo",
      columns: [
        { label: "DBN Number", value: "dbnNumber" },
        { label: "Foreign Reference", value: "foreignReference" },
        { label: "Cargo Description", value: "description" },
        { label: "Length", value: (row) => row.length + ' cm' },
        { label: "Width", value: (row) => row.width + ' cm' },
        { label: "Height", value: (row) => row.height + ' cm' },
        { label: "Weight", value: (row) => row.weight + ' kg' },
        { label: "Route", value: "assignedRouteLegs" }
      ],
      content: this.incomingFiles
    },{
      sheet: "Accepted Cargo",
      columns: [
        { label: "DBN Number", value: "dbnNumber" },
        { label: "Foreign Reference", value: "foreignReference" },
        { label: "Cargo Description", value: "description" },
        { label: "Length", value: (row) => row.length + ' cm' },
        { label: "Width", value: (row) => row.width + ' cm' },
        { label: "Height", value: (row) => row.height + ' cm' },
        { label: "Weight", value: (row) => row.weight + ' kg' },
        { label: "Route", value: "assignedRouteLegs" }
      ],
      content: this.acceptedFiles
    }];
    
    this.excelService.generateMultiSheetExcel("Transporter Files", xlsxData);
  }

  toastMessage(severity, summary, detail): void {
    this.messageService.add({
      severity: severity,
      summary: summary,
      detail: detail
    });
  }
}

import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import * as _ from 'lodash';
import { DomSanitizer } from '@angular/platform-browser';
import { MessageService } from 'primeng/api';
import { Cargo, CargoData, CargoShippingItem, Document, ViewDocument, DocumentCategory, UploadedDocuments, TimelineItem, BorderAgentFiles, BorderAgentDocuments, HttpResponse, LegalEntityInfoForFileId, FileCorrespondance, AssignedLoads } from './global.interface';
 
@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor(
    private api: ApiService,
    private sanitizer: DomSanitizer,
    private messageService: MessageService
  ) { }

  // #region Global ID's  

  portalUserId: number;
  selectedShippingFileId: number;
  selectedEmailFileId: number;
  selectedHistoryFileId: number;
  selectedTermsFileId: number;
  selectedDocumentFileId: number;
  selectedDocumentId: number;
  selectedCargoFileId: number;
  selectedTimelineFileId: number;
  selectedConsigneeFileId: number;
  selectedCorrespondanceFileId: number;
  referenceNumber: string;
  documentFilePageNavigator: boolean = false;

  // #endregion Global ID's

  // #region Global File Data

  allCargoFiles: CargoData[];
  shippingInfo: CargoShippingItem;
  cargoItems: Cargo[] = [];
  terms: string;
  fileDocuments: Document[];
  consignee: LegalEntityInfoForFileId;
  document: ViewDocument;
  documentCategories: DocumentCategory[];
  uploadedDocuments: UploadedDocuments[];
  correspondance: FileCorrespondance;
  timeline: TimelineItem[] = [];

  // #endregion Global File Data

  getReferenceNumber() : string {
    return this.referenceNumber;
  }

  getFileId() : number {
    return this.selectedShippingFileId;
  }

  async getAllCargoForUser(portalUserId: number) : Promise<CargoData[]> {
    if (!_.isEmpty(this.allCargoFiles) && this.portalUserId == portalUserId) return this.allCargoFiles;
    else {
      this.allCargoFiles = (await this.api.getAllCargoByUser()).result;
      return this.allCargoFiles;
    }
  }

  async getShippingInfo(fileId: number) : Promise<CargoShippingItem> {
    if (this.selectedShippingFileId == fileId && this.shippingInfo) return this.shippingInfo
    else {
      this.selectedShippingFileId = fileId;
      let shippingInfo = await this.api.getShippingInfo(fileId);
      this.shippingInfo = shippingInfo.result
      return this.shippingInfo;
    }
  }

  async getLegalEntityInfoForFileId(fileId: number) : Promise<LegalEntityInfoForFileId> {
    if (this.selectedConsigneeFileId == fileId && this.consignee) return this.consignee
    else {
      this.selectedConsigneeFileId = fileId;
      let consigneeInformation = await this.api.getLegalEntityInfoByFileId(fileId);
      this.consignee = consigneeInformation.result;
      return this.consignee
    }
  }

  async getCargoInfo(fileId: number) : Promise<Cargo[]> {
    if (this.selectedCargoFileId == fileId && !_.isEmpty(this.cargoItems)) return this.cargoItems
    else {
      this.selectedCargoFileId = fileId;
      let cargo = await this.api.getPortalCargoDataByFileId(fileId);
      this.referenceNumber = cargo.result[0].dbnNumber || this.selectedCargoFileId;
      this.cargoItems = cargo.result;
      return this.cargoItems;
    }
  }

  async getLatestTerms(fileId: number) : Promise<string> {
    if (this.selectedTermsFileId == fileId && this.terms) return this.terms
    else {
      this.selectedTermsFileId = fileId;
      let terms = await this.api.GetLatestTerms(fileId);
      this.terms = terms.result;
      return this.terms;
    }
  }

  async getFileDocuments(fileId: number, downloadOverride: boolean = false) : Promise<Document[]> {
    if (this.selectedDocumentFileId == fileId && !_.isEmpty(this.fileDocuments) && !downloadOverride) return this.fileDocuments;
    else {
      this.selectedDocumentFileId = fileId;
      let fileDocuments = await this.api.getAllFileDocuments(fileId);
      this.fileDocuments = fileDocuments.result;
      return this.fileDocuments;
    }
  }

  async getDocument(chosenDocument: Document) : Promise<ViewDocument> {
    if (this.selectedDocumentId == chosenDocument.id && this.document) return this.document;
    else {
      let document = await this.api.viewClientDocument(chosenDocument.id);
      let documentFile = document.result.substring(1, document.result.length - 1);

      if(chosenDocument.fileType == 'pdf') {
        return {
          documentId: chosenDocument.id,
          url: `data:application/pdf;base64,${documentFile}`,
          fileName: chosenDocument.fileName,
          dataUrl: this.sanitizer.bypassSecurityTrustResourceUrl(`data:application/pdf;base64,${documentFile}`)
        }
      } else if (chosenDocument.fileType == 'jpeg' || chosenDocument.fileType == 'jpg') {
        return {
          documentId: chosenDocument.id,
          url: `data:image/jpeg;base64,${documentFile}`,
          fileName: chosenDocument.fileName,
          dataUrl: this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/jpeg;base64,${documentFile}`)
        }
      } else if (chosenDocument.fileType == 'png' || chosenDocument.fileType == 'tiff') {
        return {
          documentId: chosenDocument.id,
          url: `data:image/${chosenDocument.fileType};base64,${documentFile}`,
          fileName: chosenDocument.fileName,
          dataUrl: this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/${chosenDocument.fileType};base64,${documentFile}`)
        }
      } else {
        this.messageService.add({
          severity: 'error',
          summary: 'Unknown File Type',
          detail: 'We are not sure the File Type of (' + chosenDocument.fileType + '), unable to display'
        });
        return {
          documentId: chosenDocument.id,
          url: `data:application/${chosenDocument.fileType};base64,${documentFile}`,
          fileName: chosenDocument.fileName,
          dataUrl: this.sanitizer.bypassSecurityTrustResourceUrl(`data:application/${chosenDocument.fileType};base64,${documentFile}`)
        }
      }
    }
  }

  async getDocumentByDocId(documentId: number, fileName: string) : Promise<ViewDocument | null> {
    if (documentId && fileName) {
      let document = await this.api.viewClientDocument(documentId);
      if (document.errorCode == 0) {
        let documentFile = document.result.substring(1, document.result.length - 1);
  
        let splitFileName = fileName.split('.');
        let fileType = splitFileName[splitFileName.length - 1];
    
        if(fileType == 'pdf') {
          return {
            documentId: documentId,
            url: `data:application/pdf;base64,${documentFile}`,
            fileName: fileName,
            dataUrl: this.sanitizer.bypassSecurityTrustResourceUrl(`data:application/pdf;base64,${documentFile}`)
          }
        } else if (fileType == 'jpeg' || fileType == 'jpg') {
          return {
            documentId: documentId,
            url: `data:image/jpeg;base64,${documentFile}`,
            fileName: fileName,
            dataUrl: this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/jpeg;base64,${documentFile}`)
          }
        } else if (fileType == 'png' || fileType == 'tiff') {
          return {
            documentId: documentId,
            url: `data:image/${fileType};base64,${documentFile}`,
            fileName: fileName,
            dataUrl: this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/${fileType};base64,${documentFile}`)
          }
        } else {
          this.messageService.add({
            severity: 'error',
            summary: 'Unknown File Type',
            detail: 'We are not sure the File Type of (' + fileType + '), unable to display'
          });
          return {
            documentId: documentId,
            url: `data:image/${fileType};base64,${documentFile}`,
            fileName: fileName,
            dataUrl: this.sanitizer.bypassSecurityTrustResourceUrl(`data:application/${fileType};base64,${documentFile}`)
          }
        }
      } else {
        return new Promise<ViewDocument>((resolve, reject) => {
          resolve(null)
        })  
      }      
    } else {
      return new Promise<ViewDocument>((resolve, reject) => {
        resolve(null)
      })
    }
  }

  async getCorrespondance(fileId: number) : Promise<FileCorrespondance> {
    if (this.selectedCorrespondanceFileId == fileId && !_.isEmpty(this.correspondance)) return this.correspondance;
    else {
      this.selectedCorrespondanceFileId = fileId
      this.correspondance = (await this.api.getFileCorrespondance(fileId)).result;
      return this.correspondance;
    }
  }

  async getPortalTimelineInfo(fileId: number) : Promise<TimelineItem[]> {
    if (this.selectedTimelineFileId == fileId && !_.isEmpty(this.timeline)) return this.timeline;
    else {
      this.selectedTimelineFileId = fileId;
      this.timeline = (await this.api.getPortalTimelineByFileId(fileId)).result;
      return this.timeline
    }
  }

  async loadDocumentsPathAndCategoryId() : Promise<DocumentCategory[]> {
    if (this.documentCategories) return this.documentCategories
    else {
      this.documentCategories = (await this.api.getPortalDocumentsPathAndDocCatergoryId()).result;
      return this.documentCategories;
    }
  }

  async GetDocumentsUploadedByFileId(fileId: number) : Promise<UploadedDocuments[]> {
    return (await this.api.getPortalUploadedDocumentsByFileId(fileId)).result;
  }  
  
  acceptTerms(fileId: number) : Promise<HttpResponse> {
    return new Promise<HttpResponse>(async (resolve, reject) => {
      try {
        let result = await this.api.acceptTerms(fileId)
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  }


  // ========================
  // BORDER PORTAL SECTION
  // ========================

  async getBorderAgentFiles() : Promise<BorderAgentFiles[]> {
    return new Promise<BorderAgentFiles[]>(async (resolve, reject) => {
      try {
        let result = await this.api.getBorderAgentFiles();
        if (result.errorCode == 0) {
          resolve(result.result);
        } else {
          reject(result.errorMessage);
        }
      } catch (error) {
        reject(error);
      }
    });
  }

  async getBorderAgentDocuments(fileId) : Promise<BorderAgentDocuments[]> {
    return new Promise<BorderAgentDocuments[]>(async (resolve, reject) => {
      try {
        let result = await this.api.getBorderAgentDocuments(fileId);
        if (result.errorCode == 0) {
          resolve(result.result);
        } else {
          reject(result.errorMessage);
        }
      } catch (error) {
        reject(error);
      }
    });
  }

  async addBorderAgentFileEvent(fileId: number, eventTypeId: number, text: string) : Promise<number> {
    return new Promise<number>(async (resolve, reject) => {
      try {
        let result = await this.api.addBorderAgentFileEvent({
          FileId: fileId, 
          EventTypeId: eventTypeId, 
          Text: text
        });
        if (result.errorCode == 0) {
          resolve(1)
        } else {
          reject(result.errorMessage);
        }
      } catch (error) {
        reject(error);
      }
    })
  }

  async removeBorderAgentFileEvent(fileId: number) : Promise<number> {
    return new Promise<number>(async (resolve, reject) => {
      try {
        let result = await this.api.removeBorderAentFileEvent(fileId);
        if (result.errorCode == 0) {
          resolve(1);
        } else { 
          reject(result.errorMessage);
        }
      } catch (error) {
        reject(error);
      }
    });
  }

  async getBorderAgentLoads() : Promise<AssignedLoads[]> {
    return new Promise<AssignedLoads[]>(async (resolve, reject) => {
      try {
        let result = await this.api.getLoadsForBorderAgents();
        if (result.errorCode == 0) {
          resolve(result.result);
        } else {
          reject(result.errorMessage);
        }
      } catch (error) {
        reject(error);
      }
    });
  } 

   async CloseSelectedFilesBorderAgent(fileId: number) : Promise<number> {
    return new Promise<number>(async (resolve, reject) => {
      try {
        let result = await this.api.closeIndividualFiles(fileId);
        if (result.errorCode == 0) {
          resolve(1);
        } else {
          reject(result.errorMessage);
        }
      } catch (error) {
        reject(error);
      }
    });
  }

   async OffloadLoadsBorderAgents(cargoLoadAssignmentId: number, statusId: number) : Promise<number> {
    return new Promise<number>(async (resolve, reject) => {
      try {
        let result = await this.api.offloadLoadsBorderAgents(cargoLoadAssignmentId, statusId);
        if (result.errorCode == 0) {
          resolve(1);
        } else {
          reject(result.errorMessage);
        }
      } catch (error) {
        reject(error);
      }
    });
  }


  // ========================
  // UPLOAD DOCUMENTS SECTION
  // ========================

  // uploadDocuments(taskId: string, taskName: string, userId: number, file) {
  //   return new Promise<HttpResponse>(async (resolve, reject) => {
  //     try {
  //       const reader = new FileReader();
  //       reader.readAsDataURL(file);
  //       reader.onload = async () => {
  //         let result = await this.api.uploadPortalFiles(this.getFileId(), file.category.path, +file.category.categoryId, taskId, taskName, userId, reader.result, file.name);
  //         if (result.errorCode == 0) resolve(result);
  //         else reject(result);
  //       }
  //     } catch (error) {
  //       reject(error);
  //     }
  //   });
  // }

  uploadBorderPortalDocument(userId: number, file, fileId: number) {
    return new Promise<HttpResponse>(async (resolve, reject) => {
      try {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = async () => {
          let result = await this.api.uploadPortalFiles(fileId, file.category.path, +file.category.categoryId, userId, reader.result, file.name);
          if (result.errorCode == 0) resolve(result);
          else reject(result);
        }
      } catch (error) {
        reject(error);
      }
    })
  }


}
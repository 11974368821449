import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LogBase } from '../../../services/logger.service';
import { MessageService } from "primeng/api";
import _ from 'lodash';
import { LoaderService } from 'src/app/services/loader.service';
import { FileService } from 'src/app/services/file.service';
import { AuthenticationService } from 'src/app/services/auth/authentication.service';
import { CargoData } from 'src/app/services/global.interface';

@Component({
  selector: 'app-landing-page-client',
  templateUrl: './landing-page-client.component.html',
  styleUrls: ['./landing-page-client.component.scss']
})

export class LandingPageClientComponent implements OnInit {

  myCargo: CargoData[];
  switchView: boolean = true;

  constructor(
    private fileService: FileService,
    private messageService: MessageService,
    private router: Router,
    private log: LogBase,
    private loaderService: LoaderService,
    private auth: AuthenticationService
  ) { }

  ngOnInit() {
    try {
      this.loadData();
      window.scrollTo(0, 0);

    } catch (error) {
      this.log.error(error);
    }
  }


  async loadData() {
    try {

      this.loaderService.activateFullSpinner();

      this.myCargo = await this.fileService.getAllCargoForUser(+this.auth.getUser().userId);

      if (_.isEmpty(this.myCargo)) {
        this.messageService.add({
          severity: 'warn', 
          summary: 'No Files have been found',
          detail: 'We don\'t seem to have any files registered for you, please contact us if this is an error!'
        });
      }

      this.loaderService.deactivateFullSpinner();
      
    } catch (error) {
      this.log.error(error);
      this.messageService.add({
        severity: 'error', 
        summary: 'Failed to get your Files',
        detail: 'Please try again, if this issue persists please contact us!'
      });
    }
  }  

  loadDetails(selectedCargo: CargoData) {

    this.router.navigate([`file-viewer/consignee/${selectedCargo.fileId}`]);
    
  }

  switchMainViewTemp() {
    this.switchView = !this.switchView;
  }

}
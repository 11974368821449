import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { AppRoutes } from './app.routes';
import { ReactiveFormsModule } from '@angular/forms';


// Imports Primeng
import { ListboxModule } from 'primeng/listbox';
import { TabViewModule } from 'primeng/tabview';
import { DropdownModule } from 'primeng/dropdown';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { TabMenuModule } from 'primeng/tabmenu';
import { FileUploadModule } from 'primeng/fileupload';
import { AccordionModule } from 'primeng/accordion';
import { ProgressBarModule } from 'primeng/progressbar';
import { EditorModule } from 'primeng/editor';
import { StepsModule } from 'primeng/steps';
import { PickListModule } from 'primeng/picklist';
import { DragDropModule } from 'primeng/dragdrop';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { PanelModule } from 'primeng/panel';
import { FieldsetModule } from 'primeng/fieldset';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { MenubarModule } from 'primeng/menubar';
import { SidebarModule } from 'primeng/sidebar';
import { CardModule } from 'primeng/card';
import { GMapModule } from 'primeng/gmap';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TableModule } from 'primeng/table';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { MenuModule } from 'primeng/menu';
import { CheckboxModule } from 'primeng/checkbox';
import { TimelineModule } from 'primeng/timeline';
import { GalleriaModule } from 'primeng/galleria';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { ContextMenuModule } from 'primeng/contextmenu';
import { ToastModule } from 'primeng/toast';
import { CalendarModule } from 'primeng/calendar';
import { SliderModule } from 'primeng/slider';
import { TooltipModule } from 'primeng/tooltip';
import { KeyFilterModule } from 'primeng/keyfilter';
import { InputMaskModule } from 'primeng/inputmask';
import { DataViewModule } from 'primeng/dataview';
import { CarouselModule } from 'primeng/carousel';
import { InputNumberModule } from 'primeng/inputnumber';
import { SelectButtonModule } from 'primeng/selectbutton';
import { PasswordModule } from 'primeng/password';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { SlideMenuModule } from 'primeng/slidemenu';
import { DividerModule } from 'primeng/divider';
import { ChipModule } from 'primeng/chip';
import { ChipsModule } from 'primeng/chips';



// Generic PrimeNg imports
import { MessageService } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';


import { AppComponent } from './app.component';
import { AppMenuComponent } from './app.menu.component';
import { TopbarComponent } from './includes/topbar/topbar.component';
import { AppFooterComponent } from './includes/footer/app.footer.component';


// Website Pages
import { LoginComponent } from './components/login/login.component';
import { ContactComponent } from './components/contact-us/contact-us.component';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';



// Services
import { ConfigService } from './services/config.service';
import { ApiService } from './services/api.service';
import { AuthManager } from './services/auth/auth.manager';
import { WebApiService } from './services/web.api.service';
import { LogInService, LoginUsernameService } from './services/rxjs.service';
import { LogBase, LogService } from './services/logger.service';
import { WindowRefService } from './services/alert.service';
import { CurrencyService } from './services/currency.service'

// Workflow Components
import { LandingPageClientComponent } from './components/portal/landing-page-client/landing-page-client.component';
import { LandingPageSuppliersComponent } from './components/portal/landing-page-suppliers/landing-page-suppliers.component'
import { LandingPageDriversComponent } from './components/portal/landing-page-drivers/landing-page-drivers.component'

import { ResetPasswordComponent } from './components/portal/reset-password/reset-password.component';
import { EmailResetLinkComponent } from './components/portal/email-reset-link/email-reset-link.component';
import { HomeComponent } from './components/home/home.component';
import { HelpCentreComponent } from './components/portal/help-centre/help-centre.component';



import { DropzoneModule, DROPZONE_CONFIG, DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { FinanceQualifyComponent } from './components/finance-qualify/finance-qualify.component';
import { DtFileViewerComponent } from './components/dt-file-viewer/dt-file-viewer.component';
import { CompleteFinanceComponent } from './components/complete-finance/complete-finance.component';
import { ACalculatorComponent } from './components/complete-finance/a-calculator/a-calculator.component';
import { BPersonalInfoComponent } from './components/complete-finance/b-personal-info/b-personal-info.component';
import { CClientComponent } from './components/complete-finance/c-client/c-client.component';
import { DOpenSbAccountComponent } from './components/complete-finance/d-open-sb-account/d-open-sb-account.component';
import { EAppTypeComponent } from './components/complete-finance/e-app-type/e-app-type.component';
import { FVehicleListComponent } from './components/complete-finance/f-vehicle-list/f-vehicle-list.component';
import { GEmployerDetailsComponent } from './components/complete-finance/g-employer-details/g-employer-details.component';
import { HLoanTermsComponent } from './components/complete-finance/h-loan-terms/h-loan-terms.component';
import { IUploadDocumentsComponent } from './components/complete-finance/i-upload-documents/i-upload-documents.component';
import { JTermsComponent } from './components/complete-finance/j-terms/j-terms.component';
import { KReviewApplicationComponent } from './components/complete-finance/k-review-application/k-review-application.component';
import { LPasswordPageComponent } from './components/complete-finance/l-password-page/l-password-page.component';
import { MLoginDropdownComponent } from './components/complete-finance/m-login-dropdown/m-login-dropdown.component';
import { FinancingFaqsComponent } from './components/financing-faqs/financing-faqs.component';
import { FullLoaderComponent } from './app.loader.component';
import { EmptydashComponent } from './components/emptydash/emptydash.component';
import { LandingPageBorderagentComponent } from './components/portal/landing-page-borderagent/landing-page-borderagent.component';
import { SideMenuComponent } from './includes/side-menu/side-menu.component';
import { OtpPasswordResetComponent } from './includes/otp-password-reset/otp-password-reset.component';
import { OtpPassresetComponent } from './components/otp-passreset/otp-passreset.component';
import { InputComponent } from './includes/input/input.component';
import { DtTextComponent } from './components/dt-file-viewer/dt-text/dt-text.component';
import { BracketTextComponent } from './includes/bracket-text/bracket-text.component';
import { DatePipe } from './pipes/date.pipe';
import { DocumentsTableComponent } from './components/dt-file-viewer/documents-table/documents-table.component';
import { BPDocumentsTableComponent } from './components/portal/landing-page-borderagent/documents-table/documents-table.component'
import { DndDirective } from './components/dt-file-viewer/documents-table/dnd.directive';
import { DocCategory } from './pipes/doc-category.pipe';
import { CorrespondanceComponent } from './components/dt-file-viewer/correspondance/correspondance.component';
import { DeepLinkHandlerComponent } from './components/deep-link-handler/deep-link-handler.component';

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        AppRoutes,
        BrowserAnimationsModule,
        ListboxModule,
        TabViewModule,
        InputTextModule,
        InputNumberModule,
        ButtonModule,
        DialogModule,
        ReactiveFormsModule,
        DropdownModule,
        ConfirmDialogModule,
        TabMenuModule,
        FileUploadModule,
        AccordionModule,
        ProgressBarModule,
        EditorModule,
        StepsModule,
        PickListModule,
        DragDropModule,
        AutoCompleteModule,
        ProgressSpinnerModule,
        PanelModule,
        FieldsetModule,
        MessagesModule,
        MessageModule,
        MenubarModule,
        SidebarModule,
        CardModule,
        GMapModule,
        SplitButtonModule,
        TableModule,
        RadioButtonModule,
        ScrollPanelModule,
        MenuModule,
        CheckboxModule,
        GalleriaModule,
        TimelineModule,
        HttpClientModule,
        MultiSelectModule,
        ContextMenuModule,
        CalendarModule,
        SliderModule,
        ToastModule,
        DropzoneModule,
        TooltipModule,
        KeyFilterModule,
        InputMaskModule,
        DataViewModule,
        CarouselModule,
        SelectButtonModule,
        PasswordModule,
        SlideMenuModule,
        OverlayPanelModule,
        DividerModule,
        ChipModule,
        ChipsModule
    ],
    declarations: [
        AppComponent,
        AppMenuComponent,
        TopbarComponent,
        AppFooterComponent,
        LoginComponent,
        ContactComponent,
        TermsAndConditionsComponent,
        PrivacyPolicyComponent,
        LandingPageSuppliersComponent,
        LandingPageDriversComponent,
        ResetPasswordComponent,
        EmailResetLinkComponent,
        LandingPageClientComponent,
        HomeComponent,
        HelpCentreComponent,
        FinanceQualifyComponent,
        DtFileViewerComponent,
        CompleteFinanceComponent,
        ACalculatorComponent,
        BPersonalInfoComponent,
        CClientComponent,
        DOpenSbAccountComponent,
        EAppTypeComponent,
        FVehicleListComponent,
        GEmployerDetailsComponent,
        HLoanTermsComponent,
        IUploadDocumentsComponent,
        JTermsComponent,
        KReviewApplicationComponent,
        LPasswordPageComponent,
        MLoginDropdownComponent,
        FinancingFaqsComponent,
        FullLoaderComponent,
        EmptydashComponent,
        LandingPageBorderagentComponent,
        SideMenuComponent,
        OtpPasswordResetComponent,
        OtpPassresetComponent,
        InputComponent,
        DtTextComponent,
        BracketTextComponent,
        DatePipe,
        DocumentsTableComponent,
        BPDocumentsTableComponent,
        DndDirective, 
        DocCategory, 
        CorrespondanceComponent,
        DeepLinkHandlerComponent
    ],
    providers: [
        { provide: LocationStrategy, useClass: PathLocationStrategy },
        { provide: LogBase, useClass: LogService },
        ConfigService,
        WindowRefService,
        WebApiService,
        LogInService,
        LoginUsernameService,
        ApiService,
        AuthManager,
        MessageService,
        CurrencyService,
        ConfirmationService,
        LandingPageClientComponent,
        MLoginDropdownComponent],
    bootstrap: [AppComponent]
})
export class AppModule { }

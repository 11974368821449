import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { DataService } from '../data.service';
import { LoaderService } from '../loader.service';
import { ToastService } from '../toast.service';

@Component({
  selector: 'app-c-client',
  templateUrl: './c-client.component.html',
  styleUrls: ['./c-client.component.scss']
})
export class CClientComponent implements OnInit {
  selectedExisting
  selectedNew

  stanbicOptions = [
    {
      icon: 'bi bi-check-lg',
      text: 'EXISTING STANBIC CLIENT (you currently have a Stanbic account)',
      val: 1
    },{
      icon: 'bi bi-x-lg',
      text: 'NEW TO STANBIC CLIENT (you do not have an account with Stanbic)',
      val: 0
    }
  ];
  selectedStanbicOption;

  @Output() onNextClick = new EventEmitter();
  @Output() onBackClick = new EventEmitter();

  constructor(public data: DataService,
  private api: ApiService,
  private toast: ToastService,
  private loaderService: LoaderService) { }

  ngOnInit(): void {
    window.scroll(0,0);
    var loanType = this.data.application.stanbicClient
    switch (loanType) {
      case 0: 
        this.selectedExisting = false; 
        this.selectedNew = true; 
        break;
      case 1: 
        this.selectedExisting = true; 
        this.selectedNew = false; 
        break;
      default: 
        this.selectedExisting = false; 
        this.selectedNew = false
    }
  }

  selectedItem(loanType) {
    this.data.application.stanbicClient = loanType;
    console.log(loanType)
    switch (loanType) {
      case 0: 
        this.selectedExisting = false; 
        this.selectedNew = true; 
        break;
      case 1: 
        this.selectedExisting = true; 
        this.selectedNew = false; 
        break;
      default: 
        this.selectedExisting = false; 
        this.selectedNew = false
    }
  }
  
  next() {
    this.loaderService.showLoader();
    let formattedJson:any = {
      applicationId: this.data.application.applicationId,
      stanbicClient: this.data.application.stanbicClient
    }
    this.api.updateApplicationStanbicClient(formattedJson).subscribe((resp) => {
       if (resp['errorCode'] == 0) {
        this.loaderService.hideLoader()
        this.onNextClick.emit()
      }
      else {
        this.toast.toastError('Unable to communicate', 'Something is wrong with connecting to the services, please try again!', 0);
        this.loaderService.hideLoader()
        
      }
    })
    
  }

  back() {
    this.onBackClick.emit()
  }
}

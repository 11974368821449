import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';

// THIS Service has the secure method on it....
import { WebApiService } from './web.api.service';
import { LogBase } from '../services/logger.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpResponse, FileEvent, TransporterFile, TransporterCarrierDrivers, AcceptedLoads } from './global.interface';
import { AuthenticationService } from './auth/authentication.service';

@Injectable()
export class ApiService {
  apiUrl: string;


  constructor(
    private config: ConfigService,
    private http: HttpClient,
    private webapi: WebApiService,
    private log: LogBase,
    private router: Router,
    private auth: AuthenticationService
  ) { }

  // API Log
  async logError(error: string, caller: string) {
    try {
      let url = this.config.getServerUrl() + 'api/portal/log/error?caller=' + caller;

      let retval = await this.webapi.post(url, error);
      return retval;
    } catch (error) {
      this.log.error(error);
    }
  }

  acceptTerms(fileid: any): Promise<HttpResponse> {
    try {
      const url = this.config.getServerUrl() + 'api/portal/agreetoterms?fileId=' + fileid;
      return this.webapi.post(url, null);
    } catch (error) {
      this.log.error(error);
      return new Promise((resolve, reject) => {
        reject(error);
      })
    }
  }

  sendWebsiteMessage(toEmail, fromEmail, body, subject, fileId, contactId) {
    try {
      var payload = {
        body: body,
        subject: subject,
        fromEmail: fromEmail,
        toEmail: toEmail,
        fileId: fileId,
        legalEntityId: contactId
      }
      var variables = JSON.stringify(payload);
      const url = this.config.getServerUrl() + 'api/Emails/sendWebsiteMessage'
      return this.webapi.doPost(url, variables);
    } catch (error) {
      this.log.error(error)
    }
  }

  async GetLatestTerms(fileId: number): Promise<HttpResponse> {
    try {
      var url = this.config.getServerUrl() + 'api/portal/getLatestTerms?FileId=' + fileId;
      let retval = await this.webapi.post(url, null);
      return retval;
    } catch (error) {
      this.log.error(error);
    }
  }

  async getAllCargoByUser(): Promise<HttpResponse> {
    try {
      var url = this.config.getServerUrl() + "api/portal/getAllCargoForUserId";
      let retval = await this.webapi.post(url, null);
      return retval;
    } catch (error) {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
  }

  async viewClientDocument(documentId: number): Promise<HttpResponse> {
    try {
      const url = this.config.getServerUrl() + 'api/Documents/ViewDocument?documentId=' + documentId;
      return await this.webapi.post(url, null);
    } catch (error) {
      this.log.error(error);
    }
  }

  async getAllFileDocuments(id): Promise<HttpResponse> {
    try {
      var url = this.config.getServerUrl() + 'api/Files/GetAllFileDocuments?FileId=' + id;
      let retval = await this.webapi.post(url, null);
      return retval;
    } catch (error) {
      this.log.error(error)
    }
  }

  async getPortalCargoDataByFileId(fileId: any): Promise<HttpResponse> {
    try {
      const url = this.config.getServerUrl() + 'api/portal/getFileCargo?fileId=' + fileId;
      let retval = await this.webapi.post(url, null);
      return retval;
    } catch (error) {
      this.log.error(error);
    }
  }

  async getPortalUploadedDocumentsByFileId(fileId: any): Promise<HttpResponse> {
    try {
      let apiUrl = this.config.getServerUrl() + 'api/portal/getPortalUploadedDocumentsByFileId?fileId=' + fileId;
      let result = await this.webapi.post(apiUrl, null);
      return result;
    } catch (error) {
      this.log.error(error);
      return new Promise<HttpResponse>((resolve, reject) => {
        reject(error);
      })
    }
  }

  async getPortalTimelineByFileId(fileId: number): Promise<HttpResponse> {
    try {
      let apiUrl = this.config.getServerUrl() + `api/portal/gettimeline?fileId=${fileId}`;
      let result = await this.webapi.get(apiUrl);
      console.log(result)
      return result;
    } catch (error) {
      this.log.error(error);
      return new Promise<HttpResponse>((resolve, reject) => {
        reject(error)
      });
    }
  }

  async getPortalDocumentsPathAndDocCatergoryId(): Promise<HttpResponse> {
    try {
      let apiUrl = this.config.getServerUrl() + 'api/portal/getPortalDocumentsPathAndDocCatergoryId';
      let result = await this.webapi.post(apiUrl, null);
      return result;
    } catch (error) {
      this.log.error(error);
      return new Promise<HttpResponse>((resolve, reject) => {
        reject(error);
      })

    }
  }

  async getShippingInfo(id): Promise<HttpResponse> {
    try {
      var url = this.config.getServerUrl() + 'api/Files/GetFileShippingInfoSupplierPortal?FileId=' + id;
      let retval = await this.webapi.post(url, null);
      return retval
    } catch (error) {
      this.log.error(error)
    }
  }

  async getLegalEntityInfoByFileId(fileId): Promise<HttpResponse> {
    try {
      var url = this.config.getServerUrl() + 'api/portal/consignee?fileId=' + fileId
      return await this.webapi.get(url);
    } catch (error) {
      this.log.error(error);
    }
    return null
  }

  async getSupplierVessels(): Promise<HttpResponse> {
    try {
      const apiUrl = this.config.getServerUrl() + 'api/portal/getSupplierShips';
      let retval = await this.webapi.post(apiUrl, null);
      return retval;

    } catch (error) {
      this.log.error(error);
    }
  }

  async getSupplierVoyages(vesselId: number): Promise<HttpResponse> {
    try {
      const apiUrl = this.config.getServerUrl() + 'api/portal/getSupplierVoyages?vesselId=' + vesselId;
      const retval = await this.webapi.post(apiUrl, null);
      return retval;
    } catch (error) {
      this.log.error(error);
    }
  }

  async getFileCorrespondance(fileId: number) : Promise<HttpResponse> {
    try {
      const apiUrl = this.config.getServerUrl() + `api/portal/correspondance?fileId=${fileId}`;
      const retval = await this.webapi.get(apiUrl);
      return retval;
    } catch (error) {
      this.log.error(error);
    }
  }

  // AVECS FINANCE CALLS

  financeRegisterUser(emailAddress: string, password: string) {
    try {
      const apiUrl = this.config.getFinanceurl() + 'auth/api/registeruser';
      var payload = {
        EmailAddress: emailAddress,
        Password: password
      }
      return this.webapi.doPost(apiUrl, payload);
    } catch (error) {
      this.log.error(error);
    }
  }

  financeLoginUser(emailAddress: string, password: string) {
    try {
      const apiUrl = this.config.getFinanceurl() + 'auth/api/loginuser';
      var payload = {
        EmailAddress: emailAddress,
        Password: password
      }
      return this.webapi.doPost(apiUrl, payload);
    } catch (error) {
      this.log.error(error);
    }
  }

  financeRegisterCleanUser(emailAddress: string, password: string) {
    try {
      const apiUrl = this.config.getFinanceurl() + 'auth/api/registerCleanUser';
      var payload = {
        EmailAddress: emailAddress,
        Password: password
      }
      return this.webapi.doPost(apiUrl, payload);
    } catch (error) {
      this.log.error(error);
    }
  }

  financeSessionCheck() {
    try {
      const apiUrl = this.config.getFinanceurl() + 'auth/api/checkSession'
      return this.webapi.doFinanceGet(apiUrl);
    } catch (error) {
      this.log.error(error);
    }
  }

  getInProgressApplication(applicationId: number) {
    try {
      const apiUrl = this.config.getFinanceurl() + 'finance/api/getIncompleteApplication?applicationId=' + applicationId;
      return this.webapi.doFinanceGet(apiUrl);
    } catch (error) {
      this.log.error(error);
    }
  }

  getBothDropdown(currencyId: number, countryId: number) {
    try {
      const apiUrl = this.config.getFinanceurl() + 'finance/api/getCountryandCurrency?currencyId=' + currencyId + '&countryId=' + countryId;
      return this.webapi.doFinanceGet(apiUrl);
    } catch (error) {
      this.log.error(error);
    }
  }

  uploadFinanceDocument(payload) {
    try {
      const apiUrl = this.config.getFinanceurl() + 'file/api/files/uploadDocument';
      return this.webapi.doStandardHeadersPost(apiUrl, payload);
    } catch (error) {
      this.log.error(error)
    }
  }

  getInProgressApplications() {
    try {
      const apiUrl = this.config.getFinanceurl() + 'finance/api/getInProgApp';
      return this.webapi.doFinanceGet(apiUrl);
    } catch (error) {
      this.log.error(error);
    }
  }

  updateApplicationVehicleWishlist(payload) {
    try {
      const apiUrl = this.config.getFinanceurl() + 'finance/api/updateApplicationVehicleWishlist';
      return this.webapi.doStandardHeadersPost(apiUrl, payload);
    } catch (error) {
      this.log.error(error);
    }
  }

  checkLoginExists(emailAddress) {
    try {
      const apiUrl = this.config.getFinanceurl() + 'finance/api/checkLogin?emailAddress=' + emailAddress;
      return this.webapi.doStandardHeadersPost(apiUrl, null);
    } catch (error) {
      this.log.error(error);
    }
  }

  getFees(payload) {
    try {
      const apiUrl = this.config.getFinanceurl() + 'CostingMatrix/api/getTotalFees';
      return this.webapi.doStandardHeadersPost(apiUrl, payload);
    } catch (error) {
      this.log.error(error);
    }
  }

  updateApplicationWithCalculatorValues(payload) {
    try {
      const apiUrl = this.config.getFinanceurl() + 'finance/api/updateApplicationWithCalculatorValues';
      return this.webapi.doStandardHeadersPost(apiUrl, payload);
    } catch (error) {
      this.log.error(error);
    }
  }

  updateApplicationWithLoanType(payload) {
    try {
      const apiUrl = this.config.getFinanceurl() + 'finance/api/updateApplicationWithLoanType';
      return this.webapi.doStandardHeadersPost(apiUrl, payload);
    } catch (error) {
      this.log.error(error);
    }
  }

  updateApplicationStanbicClient(payload) {
    try {
      const apiUrl = this.config.getFinanceurl() + 'finance/api/updateApplicationStanbicClient';
      return this.webapi.doStandardHeadersPost(apiUrl, payload);
    } catch (error) {
      this.log.error(error);
    }
  }
  updateApplicationAcceptStanbicTerms(payload) {
    try {
      const apiUrl = this.config.getFinanceurl() + 'finance/api/updateApplicationAcceptStanbicTerms';
      return this.webapi.doStandardHeadersPost(apiUrl, payload);
    } catch (error) {
      this.log.error(error);
    }
  }

  updateApplicationWithScheme(payload) {
    try {
      const apiUrl = this.config.getFinanceurl() + 'finance/api/updateApplicationWithScheme';
      return this.webapi.doStandardHeadersPost(apiUrl, payload);
    } catch (error) {
      this.log.error(error);
    }
  }

  updateApplicationPersonDetails(payload) {
    try {
      const apiUrl = this.config.getFinanceurl() + 'finance/api/updateApplicationPersonDetails';
      return this.webapi.doStandardHeadersPost(apiUrl, payload);
    } catch (error) {
      this.log.error(error);
    }
  }

  updateApplicationDocumentsUpload(payload) {
    try {
      const apiUrl = this.config.getFinanceurl() + 'finance/api/updateApplicationDocumentsUpload';
      return this.webapi.doStandardHeadersPost(apiUrl, payload);
    } catch (error) {
      this.log.error(error);
    }
  }

  updateApplicationWithTerms(payload) {
    try {
      const apiUrl = this.config.getFinanceurl() + 'finance/api/updateApplicationWithTerms';
      return this.webapi.doStandardHeadersPost(apiUrl, payload);
    } catch (error) {
      this.log.error(error);
    }
  }

  startNewApplication() {
    try {
      const apiUrl = this.config.getFinanceurl() + 'finance/api/startNewApplication';
      return this.webapi.doGet(apiUrl);
    } catch (error) {
      this.log.error(error);
    }
  }

  getDropdowns() {
    try {
      const url = this.config.getFinanceurl() + 'finance/api/GetDropdowns';
      return this.webapi.doGet(url);
    } catch (error) {
      this.log.error(error);
    }
  }

  getActiveSchemesList() {
    try {
      const url = this.config.getFinanceurl() + 'finance/api/getSchemes';
      return this.webapi.doGet(url);
    } catch (error) {
      this.log.error(error);
    }
  }

  async getBorderAgentFiles(): Promise<HttpResponse> {
    try {
      const url = this.config.getServerUrl() + `api/portal/borderagentfiles`;
      let retval = await this.webapi.get(url);
      return retval;
    } catch (error) {
      this.log.error(error);
    }
  }

  async getBorderAgentDocuments(fileId): Promise<HttpResponse> {
    try {
      const url = this.config.getServerUrl() + `api/portal/borderagentdutiesdocs?fileId=${fileId}`;
      let retval = await this.webapi.get(url);
      return retval;
    } catch (error) {
      this.log.error(error);
    }
  }

  async addBorderAgentFileEvent(fileEvent: FileEvent): Promise<HttpResponse> {
    try {
      const url = this.config.getServerUrl() + `api/portal/border/fileevent`;
      let retval = await this.webapi.post(url, JSON.stringify(fileEvent));
      return retval;
    } catch (error) {
      this.log.error(error);
    }
  }

  async removeBorderAentFileEvent(fileId: number): Promise<HttpResponse> {
    try {
      const url = this.config.getServerUrl() + `api/portal/border/fileevent?fileId=${fileId}`;
      return await this.webapi.delete(url);
    } catch (error) {
      this.log.error(error);
    }
  }

   async getLoadsForBorderAgents() : Promise<HttpResponse> {
    try {
      const url = `${this.config.getServerUrl()}api/portal/getLoadsForBorderAgents`;
      return await this.webapi.get(url);
    } catch (error) {
      this.log.error(error);
    }
  }

   async closeIndividualFiles(fileId: number) : Promise<HttpResponse> {
    try {
      const url = `${this.config.getServerUrl()}api/portal/closeLoadIndividualFiles?dbnNumber=${fileId}`;
      return await this.webapi.post(url, null);
    } catch (error) { 
      this.log.error(error);
    }
  }

   async offloadLoadsBorderAgents(cargoLoadAssignmentId: number, statusId: number) : Promise<HttpResponse> {
    try {
      const url = `${this.config.getServerUrl()}api/portal/UpdateCargoLoadAssignmentStatus?cargoLoadAssignmentId=${cargoLoadAssignmentId}&statusId=${statusId}` ;
      return await this.webapi.post(url, null);
    } catch (error) {
      this.log.error(error);
    }
  }

  async getBFJTrackingSheetForDeliveryReport() : Promise<HttpResponse> {
    try {
      const url = `${this.config.getServerUrl()}api/portal/report/rptBFJTrackingForDelivery`;
      return await this.webapi.post(url, null);
    } catch (error) {
      this.log.error(error);
    }
  }

  async getBFJTrackingSheetForStorageReport() : Promise<HttpResponse> {
    try {
      const url = `${this.config.getServerUrl()}api/portal/report/rptBFJTrackingForStorage`;
      return await this.webapi.post(url, null);
    } catch (error) {
      this.log.error(error);
    }
  }

  async getBFJPortalTransporterAssigned(): Promise<HttpResponse> {
    try {
      const url = `${this.config.getServerUrl()}api/portal/report/rptTransporterAssignedCargoBFJ`;
      return await this.webapi.post(url, null);
    } catch (error) {
      this.log.error(error);
    }
  }

  async filterDataForDelivery(foreignRef?: string[], vessel?: string, voyage?: string): Promise<HttpResponse> {
    try {
      const payload = {
        foreignRef: foreignRef,
        vessel: vessel,
        voyage: voyage
      }
      const url = `${this.config.getServerUrl()}api/portal/filterDataForDelivery`;
      return await this.webapi.post(url, payload);
    } catch (error) {
      this.log.error(error);
    }
  }

  async filterDataForStorage(foreignRef?: string[], vessel?: string, voyage?: string) {
    try {
      const payload = {
        foreignRef: foreignRef,
        vessel: vessel,
        voyage: voyage
      };
      const url = `${this.config.getServerUrl()}api/portal/filterDataForStorage`;
      return await this.webapi.post(url, payload);
    } catch (error) {
      this.log.error(error);
    }
  }

  async uploadPortalFiles(FileId: number, DocPath: string, DocCategoryId: number, UserId: number, FileB64, FileName: string) : Promise<HttpResponse> {
    try {
      const url = this.config.getServerUrl() + 'api/portal/uploaddoc';
      var payload = {
        FileId,
        FileRowItemId: 0,
        DocPath,
        DocCategoryId,
        UserId,
        File: FileB64,
        FileName
      }
      console.log(payload);
      
      return await this.webapi.post(url, payload);
    } catch (error) {
      this.log.error(error);
    }
  }
  
  async getCollectionLoadsAssignedToTransport() {
    try {
      const url = this.config.getServerUrl() + 'api/portal/getAssignedCollectionLoads';
      let retval = await this.webapi.get(url)
      return retval.result;
    } catch (error) {
      this.log.error(error);
    }
  }

  async getCollectionBulkLoadsAssignedToTransport() {
    try {
      const url = this.config.getServerUrl() + 'api/portal/getAssignedBulkCollectionLoads';
      let retval = await this.webapi.get(url)
      return retval.result;
    } catch (error) {
      this.log.error(error);
    }
  }

  async getDeliveryLoadsAssignedToTransport() {
    try {
      const url = this.config.getServerUrl() + 'api/portal/getAssignedLoads';
      let retval = await this.webapi.get(url)
      return retval.result;
    } catch (error) {
      this.log.error(error);
    }
  }

  async TransporterAcceptDeclineLoad(CargoLoadAssignedIds: number[], StatusId: number) {
    try {
      let payload = {
        "CargoLoadAssignmentIds": CargoLoadAssignedIds,
        "StatusId": StatusId,
      }
      const url = this.config.getServerUrl() + 'api/portal/UpdateCargoLoadAssignmentStatusFromPortal';
      let retval = await this.webapi.post(url, payload)
      return retval.result;
    } catch (error) {
      this.log.error(error);
    }
  }

  async TransporterAcceptDeclineCollectionLoad(CargoLoadAssignedIds: number[], StatusId: number) {
    try {
      let payload = {
        "CargoLoadAssignmentIds": CargoLoadAssignedIds,
        "StatusId": StatusId,
      }
      const url = this.config.getServerUrl() + 'api/portal/UpdateCollectionCargoLoadAssignmentStatusFromPortal';
      let retval = await this.webapi.post(url, payload)
      return retval.result;
    } catch (error) {
      this.log.error(error);
    }
  }

  async getCollectionAcceptedLoads() {
    try {
      const url = this.config.getServerUrl() + 'api/portal/getAcceptedCollectionLoads';
      let retval = await this.webapi.get(url)
      return retval.result;
    } catch (error) {
      this.log.error(error);
    }
  }
  async getDeliveryAcceptedLoads() {
    try {
      const url = this.config.getServerUrl() + 'api/portal/getAcceptedLoads';
      let retval = await this.webapi.get(url)
      return retval.result;
    } catch (error) {
      this.log.error(error);
    }
  }
  async getAcceptedLoadsCompleted() {
    try {
      const url = this.config.getServerUrl() + 'api/portal/getCompletedLoads';
      let retval = await this.webapi.get(url)
      return retval.result;
    } catch (error) {
      this.log.error(error);
    }
  }

  async getAcceptedCollectionLoadsCompleted() {
    try {
      const url = this.config.getServerUrl() + 'api/portal/getCompletedCollectionLoads';
      let retval = await this.webapi.get(url)
      return retval.result;
    } catch (error) {
      this.log.error(error);
    }
  }
  async acknowledgeBulkAssignment(collectionLoadPlanId: number[]) {
    try {
      const url = this.config.getServerUrl() + 'api/portal/acknowledgeBulkAssignment?collectionLoadPlanId=' + collectionLoadPlanId;
      let retval = await this.webapi.post(url, null)
      return retval.result;
    } catch (error) {
      this.log.error(error);
    }
  }
}
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

interface HomeTile {
  pageToLoad: string;
  title: string;
  titleText: string;
  titleImage: string;
  hoverIcon: string;
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  homeTiles: HomeTile[] = [{
    pageToLoad: 'https://avecsgroup.com/', 
    title: 'AVECS GROUP',
    titleText: 'We are professionals in the Clearing, Fowarding, Logistics & IT Business',
    titleImage: '/assets/newbuild/avecsgroup.jpg',
    hoverIcon: '../../../assets/home-page-icons/avecsgroup.png'
  },{
    pageToLoad: 'login', 
    title: 'TRACK MY VEHICLE',
    titleText: 'Track your cargo!',
    titleImage: '/assets/newbuild/trackMyVehicle.jpg',
    hoverIcon: '../../../assets/home-page-icons/trackvehicle.png'
  },{
    pageToLoad: 'login', 
    title: 'SUPPLIER TRACKING',
    titleText: 'Check your supplier files!',
    titleImage: '/assets/newbuild/supploerTracking.jpg',
    hoverIcon: '../../../assets/home-page-icons/suppliertracking.png'
  },{
    pageToLoad: 'login', 
    title: 'DEALER TRACKING',
    titleText: 'Track the cargo for your dealership!',
    titleImage: '/assets/newbuild/dealerTracking.jpg',
    hoverIcon: '../../../assets/home-page-icons/dealertracking.png'
  },{
    pageToLoad: 'login', 
    title: 'UPLOAD DOCUMENTS',
    titleText: 'Upload your missing documents!',
    titleImage: '/assets/newbuild/uploadDocuments.jpg',
    hoverIcon: '../../../assets/home-page-icons/uploaddocuments.png'
},{
  pageToLoad: 'http://docutrack.avecs.co.za/', 
  title: 'DOCUTRACK',
  titleText: '',
  titleImage: '/assets/newbuild/docutrack.jpg',
  hoverIcon: '../../../assets/home-page-icons/saexports.png'
}]

  constructor(private router: Router) { }

  ngOnInit() {
  }

  loadPage(pageOrUrl: any) {
    if (pageOrUrl.startsWith('http')) {
      window.open(pageOrUrl, "_blank");
    } else {
      this.router.navigate([pageOrUrl])
    }
  }

}

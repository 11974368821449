export enum Groups {
    ACCOUNTS = 1,
    TRANSPORTER = 3,
    DRIVER = 4,
    CARGO = 7,
    CONSIGNEE = 8,
    PORTAL_ADMIN = 9,
    SYSTEM_ADMIN = 10,
    CLAIMS = 11,
    AGENCY = 17,
    VESSEL_OWNERS = 18,
    SUPPLIER = 19,
    DISPATCH = 20,
    FILE_OWNER = 21,
    BORDER_AGENT = 22
}

export enum PageLoads {
    FILE = 1,
    SUPPLIER = 2,
    TRANSPORTER = 3,
    BORDER = 4
}
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { DataService } from '../data.service';
import { CountryData, CurrencyData, FuelType } from '../finance.interfaces';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as _ from 'lodash';
import * as moment from 'moment';
import { ToastService } from '../toast.service';
import { LoaderService } from '../loader.service';

@Component({
  selector: 'app-f-vehicle-list',
  templateUrl: './f-vehicle-list.component.html',
  styleUrls: ['./f-vehicle-list.component.scss']
})
export class FVehicleListComponent implements OnInit {

  cellNumberRegex = "^[0-9+]*$"
  numberOnlyRegex = "^[0-9]*$"

  _displayTerms: boolean = false;
  _acceptTerms: boolean = false;

  fuelTypes: FuelType[] = [{
    name: 'Petrol',
    value: 1
  }, {
    name: 'Diesel',
    value: 2
  }];
  
  formError: boolean = false;

  @Output() onNextClick = new EventEmitter();

  constructor(private api: ApiService,
    public data: DataService,
    private toast: ToastService,
    private loaderService: LoaderService) {
    // this.data.getDropdowns()
  }

  ngOnInit(): void {
    window.scroll(0,0);
  }

  displayTerms(toDisplay: boolean) {
    this._displayTerms = toDisplay;
  }

  selectCountry(event) {
    this.data.application.formDetails.selectedCountry = event.value;
    console.log(this.data.application.formDetails.selectedCountry);
    let currency = _.find(this.data.currencies, (e) => {
      return e.id == this.data.application.formDetails.selectedCountry.primaryCurrency
    })
    if (currency) {
      this.data.application.formDetails.selectedCurrency = currency
    }
  }

  selectCurrency(event) {
    this.data.application.formDetails.selectedCurrency = event.value;
    console.log(this.data.application.formDetails.selectedCurrency)
  }

  selectFuelType(event) {
    this.data.application.vehicleDetails.fuelType = event.value;
  }

  termsAccept() {
    this._acceptTerms = !this._acceptTerms;
  }

  getYearRange() {
    return moment().subtract(8, 'years').year() + ':' + moment().year();
  }

  getCurrencyCode() {
    if (this.data.application.formDetails.selectedCurrency) {
      return this.data.application.formDetails.selectedCurrency.data.currencyCode
    }
    else {
      return 'ZAR'
    }
  }

  getLocale() {
    if (this.data.application.formDetails.selectedCurrency) {
      return this.data.application.formDetails.selectedCurrency.locale
    }
    else {
      return 'en-ZA'
    }
  }

  calChange(event) {
    console.log(event)
    let date = moment(event);
    if (date.isValid) {
      this.data.application.vehicleDetails.manufactureYear = date.toDate();
      // console.log(moment(this.data.application.vehicleDetails.manufactureYear).diff(moment()));
    } else {
      console.log('the fuck')
      // Handle if not year selected
    }
  }

  formValid() {
    if (this.validateCarAgeDiff(this.data.application.vehicleDetails.manufactureYear)) {
      if (this.data.application.personDetails.fullName &&
          this.data.application.personDetails.emailAddress &&
          this.data.application.personDetails.cellNumber &&
          this.data.application.formDetails.selectedCountry &&
          this.data.application.formDetails.selectedCurrency &&
          this.data.application.vehicleDetails.vehicleMake && 
          this.data.application.vehicleDetails.vehicleModel &&
          this.data.application.vehicleDetails.fuelType &&
          this.data.application.vehicleDetails.engineSize &&
          this.data.application.vehicleDetails.vehicleValue) {
            if (this._acceptTerms) {
              if (this.validateEmail(this.data.application.personDetails.emailAddress)) {
                if (this.data.application.vehicleDetails.vehicleValue > 0) {
                  if (this.data.application.vehicleDetails.engineSize > 0) {
                    return true;
                  } else {
                    this.formError = true;
                    this.toast.toastError('Invalid Engine Size', 'Please make sure to enter the correct engine size, the amount cannot be negative!', 0)
                    return false;
                  }
                } else {
                  this.formError = true;
                  this.toast.toastError('Invalid Vehicle Value', 'Please make sure to enter an actual vehicle price, the amount cannot be negative!', 0);
                  return false;
                }
                
              } else {
                this.formError = true;
                this.toast.toastError('Invalid Email Address', 'Please make sure to enter a valid and working email address', 0);
                return false;
              }
            } else {
              this.formError = true;
              this.toast.toastError('Accept Terms!', 'You need to accept terms to continue with your application', 0);
              return false;
            }
            
          }
    }
    this.formError = true;
    this.toast.toastError('Missing Fields!', 'There are some missing fields, please make sure to check you have entered all fields correctly', 0);
    return false;
  }

  validateCarAgeDiff(carManufactureDate) {
    if (carManufactureDate != null) {
      return -moment(carManufactureDate).diff(moment(), 'months') < (8 * 12);
    } else {
      return false;
    }
  }

  validateEmail(email) {
    const regularExpression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regularExpression.test(String(email).toLowerCase());
   }

  next() {
    this.loaderService.showLoader()
    console.log('test')
    // console.log(this.data.application.vehicleDetails)
    if (this.formValid()) {
      let formattedJson = {
        applicationId: this.data.application.applicationId,
        fullName: this.data.application.personDetails.fullName,
        emailAddress: this.data.application.personDetails.emailAddress,
        cellNumber: this.data.application.personDetails.cellNumber,
        vehicleMake: this.data.application.vehicleDetails.vehicleMake,
        vehicleModel: this.data.application.vehicleDetails.vehicleModel,
        manufactureYear: this.data.application.vehicleDetails.manufactureYear,
        other: this.data.application.vehicleDetails.other,
        fuelType: this.data.application.vehicleDetails.fuelType,
        engineSize: this.data.application.vehicleDetails.engineSize,
        vehicleValue: this.data.application.vehicleDetails.vehicleValue,
        countryId: this.data.application.formDetails.selectedCountry.id,
        currencyId: this.data.application.formDetails.selectedCurrency.id
      }
      this.api.updateApplicationVehicleWishlist(formattedJson).subscribe((resp) => {
        if (resp['errorCode'] == 0) {
          let formattedJson2 = {
            currentRetailSellingPrice: this.data.application.vehicleDetails.vehicleValue,
            year: this.data.application.vehicleDetails.manufactureYear,
            engineCapacity: this.data.application.vehicleDetails.engineSize,
            fuelType: this.data.application.vehicleDetails.fuelType
          }

          this.api.checkLoginExists(this.data.application.personDetails.emailAddress).subscribe((result) => {
            console.log(result)
            if (result['errorCode'] == 0) {
              this.data.accountExists = true;
            }
          });
    
          this.api.getFees(formattedJson2).subscribe((resp) => {
            if (resp['errorCode'] == 0) {
              console.log(resp)
              this.loaderService.hideLoader()
              this.onNextClick.emit();
            }
            else {
              this.toast.toastError('Unable to communicate', 'Something is wrong with connecting to the services, please try again!', 0);
              this.loaderService.hideLoader()
            }
          })
        }
        else {
          this.toast.toastError('Unable to communicate', 'Something is wrong with connecting to the services, please try again!', 0);
          this.loaderService.hideLoader()
        }
      })
    } else {
      this.toast.toastError('Form Missing Values', 'Please make sure all required form fields are filled in')
      this.loaderService.hideLoader()
    }
    
  }

}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastService } from '../toast.service';
import { ApiService } from '../../../services/api.service';
import { ConfirmationService } from 'primeng/api';
import { DataService } from '../data.service';
import { LoaderService } from '../loader.service';

export interface EventEmitApplication {
  applicationId: number;
  stepNumber: number;
}

@Component({
  selector: 'app-m-login-dropdown',
  templateUrl: './m-login-dropdown.component.html',
  styleUrls: ['./m-login-dropdown.component.scss']
})
export class MLoginDropdownComponent implements OnInit {

  @Output() onApplicationContinue = new EventEmitter<EventEmitApplication>();

  @Input('styling') darkBackground: boolean = false;

  menuItems;

  collapseMenu: boolean = true;
  loginModal: boolean = false;
  registerModal: boolean = false;

  emailAddress: string;
  password: string;
  confirmPassword: string;

  loginText: string = 'Login / Register';

  constructor(private api: ApiService, 
    private toast: ToastService, 
    private confirmationService: ConfirmationService,
    private data: DataService,
    private loaderService: LoaderService) { }

  ngOnInit() {
    window.scroll(0,0);
    this.resetMenu();
    this.loginCheck();
  }

  resetMenu() {
    this.menuItems = [{
      label: 'Login',
      icon: 'pi pi-sign-in',
      detail: null,
      command: () => {
        this.loginModal = true;
        this.collapseMenu = true;
      }
    },{
      label: 'Register',
      icon: 'pi pi-user-plus',
      detail: null,
      command: () => {
        this.registerModal = true;
        this.collapseMenu = true;
      }
    }]
  }

  loginCheck() {
    this.api.financeSessionCheck().subscribe(result => {
      if (result['errorCode'] == 0) {
        this.getInProgApps();
        if (result['result']['firstName']) {
          this.loginText = result['result']['firstName'] + ' ' + result['result']['lastName'];
        } else {
          this.loginText = result['result']['emailAddress'];
        }
        this.data.loggedIn = true;
      } else if (result['errorCode'] == 7) {
        this.resetMenu();
      } else {
        this.toast.toastError('Unknown Error', result['errorMessage'])
      }
    })
  }

  login() {
    this.loaderService.showLoader();
    this.api.financeLoginUser(this.emailAddress, this.password).subscribe(result => {
      if (result["errorCode"] == 0) {
        this.toast.toastSuccess('Login Successful', 'You are now logged in, retrieving your applications!');
        window.sessionStorage.setItem('finance-token', result['result'].sessionToken);
        window.sessionStorage.setItem('finance-token-expiry', result['result'].expiryDate);

        this.data.application.personDetails.emailAddress = this.emailAddress;

        if (result['result']['firstName']) {
          this.loginText = result['result']['firstName'] + ' ' + result['result']['lastName'];
        } else {
          this.loginText = result['result']['emailAddress'];
        }
         

        this.loginModal = false;

        // Get in progress applications
        this.getInProgApps();
        this.loaderService.hideLoader();

      } else {
        this.loaderService.hideLoader();
        this.toast.toastError('Failed to login', result['errorMessage']);
      }
    })
  }

  register() {
    this.loaderService.showLoader();
    if (this.password == this.confirmPassword) {
      if (this.emailAddress && this.password) {
        this.api.financeRegisterCleanUser(this.emailAddress, this.password).subscribe(result => {
          console.log(result);
          if (result["errorCode"] == 0) {
            this.toast.toastSuccess('Register Successful', 'You have successfully registered!');
            window.sessionStorage.setItem('finance-token', result['sessionToken']);
            window.sessionStorage.setItem('finance-token-expiry', result['result']);
            this.loginText = this.emailAddress;
            this.registerModal = false;
            this.data.application.personDetails.emailAddress = this.emailAddress;

            this.getInProgApps();
            this.loaderService.hideLoader();
          } else {
            this.loaderService.hideLoader();
            this.toast.toastError('Failed to register user', result['errorMessage'])
          }

          
        })
      } else {
        this.loaderService.hideLoader();
        this.toast.toastError('Failed to register User', 'Please make sure that all fields are entered!')
      }
    } else {
      this.loaderService.hideLoader();
      this.toast.toastError('Failed to Register User', 'Please make sure that your entered passwords match')
    }
  }

  getInProgApps() {
    this.api.getInProgressApplications().subscribe(result => {
      console.log(result);

      if (result["errorCode"] == 0) {
        this.menuItems = [];
        result['result'].forEach(element => {
          this.menuItems.push({
            label: 'Incomplete Application',
            icon: 'pi pi-exclamation-circle',
            detail: `Step: ${element.stepId}/9`,
            command: () => this.continueApplication(element.id, element.stepId)
          });
        });
        this.menuItems.push({
          label: 'Logout', 
          icon: 'pi pi-times',
          detail: null,
          command: () => this.logout()
        })

      } else {
        this.toast.toastError('Failed to get Applications', result['errorMessage'])
      }

    })
  }

  logout() {
    this.collapseMenu = true;
    this.confirmationService.confirm({
      message: 'Are you sure that you want to logout?',
      accept: () => {
        this.resetMenu();
        window.sessionStorage.removeItem('finance-token');
        window.sessionStorage.removeItem('finance-token-expiry')
        this.loginText = 'Login / Register';
      }
    });
  }

  continueApplication(applicationId: number, stepId: number) {
    // console.log(applicationId)
    this.onApplicationContinue.emit({
      applicationId: applicationId, 
      stepNumber: stepId
    });
  }

}
